import { createRouter, createWebHistory } from 'vue-router'
import { userdataStore } from '@/stores/userData'
// Main routes
import MainView from '../views/MainView.vue'
import ErrorView from '../views/ErrorView.vue'
import MobileViewUnsupported from '../views/MobileViewUnsupported.vue'
import Information from '../views/Information.vue'
// Subroutes -> tabs in app
import Dashboard from '../pages/Dashboard.vue'
import DashboardClient from '../pages/DashboardClient.vue'
import Blank from '../pages/Blank.vue'
import Form from '../pages/Form.vue'
import Search from '../pages/Search.vue'
import Warehouse from '../pages/Warehouse.vue'
import Settings from '../pages/Settings.vue'
import Policy from '../pages/policy.vue'
import Problem from '../pages/problem.vue'
import SearchUser from '../pages/SearchUser.vue'

import AuthView from '@/views/AuthView.vue'
import Register from '@/pages/Register.vue'
import Login from '@/pages/Login.vue'
import RequestResetPassword from '@/pages/RequestResetPassword.vue'
import ResetPassword from '@/pages/ResetPassword.vue'
import EmailConfirm from '@/pages/EmailConfirm.vue'

import Ankieta from '@/pages/Auron/ankieta.vue'
import WniosekAdmin from '@/pages/Auron/WniosekAdmin.vue'
import Wniosek from '@/pages/Auron/Wniosek2.vue'




// let Ankieta, WniosekAdmin, Wniosek;
// Ankieta = Wniosek = WniosekAdmin = Settings;

// try {
// Ankieta = () => import('../pages/Auron/ankieta.vue').catch(() => Settings)
// Wniosek = () => import('../pages/Auron/Wniosek.vue').catch(() => Settings)
// Wniosek2 = () => import('../pages/Auron/Wniosek2.vue').catch(() => Settings)
// } catch (e) {
// console.log('Ankieta import error: ' + e)
// }
// import.meta.env.BASE_URL

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            redirect: '/dashboardAdmin',
            name: 'main',
            component: MainView,
            meta: { requiresAuth: true },
            children: [
                {
                    icon: 'pi-desktop',
                    name: 'Dashboard Admin',
                    dashboardTitle: "Dash. Admin",
                    path: '/dashboardAdmin',
                    meta: { show: true },
                    component: Dashboard
                },
                {
                    icon: 'pi-search',
                    name: 'Wyszukaj wniosek',
                    dashboardTitle: "Wnioski",
                    path: '/search',
                    meta: { show: true },
                    component: Search
                },
                {
                    icon: 'pi-search',
                    name: 'Wyszukaj użytkownika',
                    dashboardTitle: "Użytkownicy",
                    path: '/searchUser',
                    meta: { show: true },
                    component: SearchUser
                },
                {
                    icon: 'bi-file-earmark',
                    name: 'Wniosek',
                    path: '/wniosekAdmin/:id?',
                    component: WniosekAdmin
                },
                {
                    icon: 'pi-home',
                    name: 'Dashboard Klient',
                    dashboardTitle: "Dash. Klient",
                    menuTitle: "Dashboard",
                    path: '/dashboard',
                    meta: { show: true },
                    component: DashboardClient
                },
                {
                    icon: 'pi-check-square',
                    name: 'Ankieta',
                    path: '/ankieta',
                    meta: { show: true },
                    component: Ankieta
                },
                {
                    icon: 'bi-file-earmark',
                    name: 'Wniosek Klient',
                    dashboardTitle: "Wnio. Klient",
                    menuTitle: "Wniosek o upadłość konsumencką",
                    path: '/wniosek/:id?',
                    component: Wniosek,
                },
                {
                    icon: 'pi-file',
                    name: 'Regulamin',
                    dashboardTitle: "Regulamin",
                    menuTitle: "Regulamin i polityka prywatności",
                    path: '/policy',
                    meta: { show: true },
                    component: Policy
                },
                {
                    icon: 'pi-comments',
                    name: 'Problem',
                    dashboardTitle: "Zgłoś problem",
                    menuTitle: "Coś nie działa? Zgłoś problem!!!",
                    path: '/problem',
                    meta: { show: true },
                    component: Problem
                }
                // {
                //     icon: 'bi-app',
                //     name: 'Blank',
                //     path: '/blank',
                //     component: Blank
                // },
                // {
                //     icon: 'bi-file-earmark',
                //     name: 'Form',
                //     path: '/form',
                //     component: Form
                // },
                // {
                //     icon: 'bi-archive',
                //     name: 'Warehouse',
                //     path: '/warehouse',
                //     component: Warehouse
                // },
                // {
                //     icon: 'bi-gear',
                //     name: 'Settings',
                //     path: '/settings',
                //     component: Settings
                // },

            ]
        },
        {
            path: '/mobileUnsupported',
            name: 'mobile',
            component: MobileViewUnsupported
        },
        {
            path: '/data-policy-and-user-agreement',
            name: 'information',
            component: Information
        },
        {
            path: '/:catchAll(.*)',
            name: 'error',
            component: ErrorView
        },
        {
            path: '/authview',
            redirect: '/login',
            name: 'Authentication',
            component: AuthView,
            children:
                [
                    {
                        name: 'Register',
                        path: '/register',
                        component: Register,
                    },
                    {
                        name: 'RequestResetPassword',
                        path: '/request-reset-password',
                        component: RequestResetPassword,
                    },
                    {
                        name: 'Login',
                        path: '/login',
                        component: Login,
                    },
                    {
                        name: 'ResetPassword',
                        path: '/reset-password',
                        component: ResetPassword,
                    },
                    {
                        name: 'EmailConfirm',
                        path: '/email-confirm',
                        component: EmailConfirm,
                    },
                ]
        }
    ]
})


let previousPath = '';

router.beforeEach(async (to, from) => {
    previousPath = to.fullPath;

    if (to.path == "/login" && !document.cookie.includes('appToken')) {
        return;
    }
    
    try {
        let response = await fetch('/api/refreshToken',{},true);

        if(!response.ok) throw { error: 'Failed to refresh token' };
    } catch (e) {
        document.cookie = "appToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
    }

    if (to.meta.requiresAuth && !document.cookie.includes('appToken')) {
        return {
            path: '/login',
            query: { redirect: to.fullPath },
        }
    }

    const userdataS = userdataStore();
    if (userdataS.firstLogin && to.path == "/dashboardAdmin" && from.path != "/ankieta") {
        return { path: '/ankieta' };
    }

    try {
        let objectsResponse = await fetch('/api/getListObjects', {
            body: JSON.stringify({
                "listUUID": "0f38658b-dce6-4534-979e-31b7b3a82e68",
                "propertyMap": {
                    "name": ".*"
                }
            })
        },true);

        if (!objectsResponse.ok) {
            throw { error: 'Failed to fetch objects' };
        }

        const objects = (await objectsResponse.json()).result.objects || [];
        const table = ["Dashboard", "EmailConfirm", "Login", "Register", "ResetPassword", "RequestResetPassword", "mobile", "error","information","Policy"];

        objects.forEach(object => {
            table.push(object.name);
        });

        //remove as soon as possible - disgusting
        if (table.includes('Dashboard Admin')) {
            userdataS.admin = true;
        }
        //

        if (!table.includes(to.name) && to.name == 'Dashboard Admin') {
            return { path: '/dashboard' };
        }
        if (!table.includes(to.name)) {
            return { path: '/error' };
        }
    } catch (e) {
        console.log("🚀 ~ router.beforeEach ~ e:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", e)
    }

    if (to.params.id === ':id') {
        if (to.path.includes(':id')) {
            const newPath = to.path.replace(/\/[^/]+$/, '');
            return { path: newPath || '/' };
        }
    }
})


router.getPreviousPath = () => {
    return previousPath;
};

export default router
