<template>
    <Toast />
    <div class="flex w-full h-full">
        <div v-if="loginPanel" class="flex flex-col flex-1 min-h-screen h-full max-h-screen overflow-auto">
            <div class="flex-grow flex flex-col items-center justify-center">
                <router-view class="flex flex-col justify-center items-center h-full" />
            </div>
            <div class="flex justify-center w-full p-3 text-md text-surface-300">
                &copy;{{ new Date().getFullYear() }} &bull; Invictus. All rights reserved.
            </div>
        </div>
        <!-- 'h-[93vh]': isMobile()  -->
        <section v-if="landingPage" class="flex-[2]" :class="{ 'h-[100vh]': !isMobile() }"
            style="background-image: url('landingPageBack.webp');background-repeat: no-repeat;background-position: center;background-size: cover;">
            <div class="flex flex-col justify-between items-start w-full h-full font-sans overflow-auto"
                style="background-color:rgba(0, 0, 0, 0.6);">
                <!-- <div class="max-w-full w-full flex flex-row justify-between items-center font-bold text-white" style="background-color:rgba(0, 0, 0, 0.5);">
                    <div class="w-full lg:w-[80%] px-5 pl-5 lg:pl-10 py-5 flex flex-col gap-2 items-start">
                        <div class="text-4xl">Upadłość Konsumencka - Kancelaria AR</div>
                        <div class="pl-1 text-left text-4xl lg:text-2xl">Skuteczne oddłużanie</div>
                    </div>
                    <button class="block bg-primary-600 hover:bg-primary-700 rounded-2xl lg:hidden text-2xl lg:text-lg text-nowrap mr-5 p-5 to-primary-100" @click="showLoginPanel()">Zaloguj się</button>
                </div> -->
                <div class="max-w-full w-full flex flex-col justify-between items-start font-bold text-white"
                    style="background-color:rgba(0, 0, 0, 0.5);">
                    <div class="w-full lg:w-[80%] pl-5 pr-3 lg:pl-10 py-3 flex flex-row gap-2 items-start">
                        <div class="text-4xl">Upadłość Konsumencka - Kancelaria AR</div>
                        <button
                            class="block bg-primary-600 hover:bg-primary-700 rounded-2xl lg:hidden text-2xl lg:text-lg text-nowrap mt-2 mr-2 p-5 to-primary-100"
                            @click="showLoginPanel()">Zaloguj się</button>
                    </div>
                    <div class="pl-5 lg:pl-10 mb-3 text-left text-5xl lg:text-2xl">Skuteczne oddłużanie</div>
                </div>

                <div class="w-full px-5 pl-5 lg:pl-10 py-3 flex flex-col gap-2 items-center text-white">

                    <!-- <div class="text-4xl lg:text-4xl font-bold">Sprawdź czy możesz uwolnić się od długów, windykatorów i komornika poprzez upadłość</div> -->
                    <div class="hidden lg:flex flex-col items-center gap-2 font-bold">
                        <div class="text-2xl lg:text-5xl text-center">Sprawdź czy możesz uwolnić się od długów,</div>
                        <div class="text-2xl lg:text-5xl text-center">windykatorów i komornika poprzez upadłość</div>
                    </div>
                    <div class="flex lg:hidden flex-col items-center gap-2 font-bold">
                        <div class="text-3xl lg:text-5xl text-center">Sprawdź czy możesz uwolnić się od długów,
                            windykatorów i&nbsp;komornika poprzez upadłość</div>
                    </div>
                    <button
                        class="my-2 lg:my-5 w-11/12 lg:w-fit bg-primary-600 hover:bg-primary-700 rounded-2xl lg:rounded-lg text-2xl lg:text-4xl px-5 py-3 text-center lg:font-bold"
                        @click="showRegister()">
                        Zakładam darmowe konto i wypełniam bezpłatną ankietę
                    </button>

                    <div class="flex flex-col lg:flex-row items-center gap-2">
                        <div class="text-3xl lg:text-4xl">Kompleksowa obsługa</div>
                        <div class="text-3xl lg:text-4xl">już od 2400zł</div>
                    </div>

                    <div class="text-3xl lg:text-4xl">... z nami odzyskasz spokój.</div>
                    <button
                        class="my-2 lg:mt-5 w-11/12 lg:w-fit bg-primary-600 hover:bg-primary-700 rounded-2xl lg:rounded-lg text-2xl lg:text-3xl px-5 py-3 text-center lg:font-bold"
                        @click="visibleConsultationDialog = true">Umów się na bezpłatną konsultację</button>
                    <div class="lg:mt-3 text-3xl lg:text-4xl">Zadzwoń:</div>
                    <div class="flex gap-4 text-5xl lg:text-5xl font-bold">
                        <span class="mt-1 pi pi-phone"></span>
                        <a :href="isMobile() ? 'tel:534123123' : ''">534 123 123</a>
                    </div>

                    <div class="flex flex-col text-center gap-4 text-3xl lg:text-4xl lg:mt-5 font-bold">
                        <span>...lub od razu wybierz najtańszą opcję na przygotowanie wniosku i pomoc po ogłoszeniu
                            upadłości</span>
                    </div>
                    <button
                        class="my-2 lg:my-5 lg:mb-4 w-11/12 lg:w-fit bg-primary-600 hover:bg-primary-700 rounded-2xl lg:rounded-lg text-2xl lg:text-4xl px-5 py-3 text-center font-bold"
                        @click="showRegister()">
                        SPRAWDZAM
                    </button>

                </div>
                <div class="w-full px-5 pl-5 lg:pl-10 py-3 flex flex-col gap-2 items-center text-gray-400"
                    style="background-color:rgba(0, 0, 0, 0.3);">
                    <RouterLink to="data-policy-and-user-agreement">Regulamin i polityka prywatności</RouterLink>
                </div>

            </div>
        </section>
        <Dialog v-model:visible="visibleConsultationDialog" modal header="Umów się na bezpłatną konsultację"
            class="w-[45rem]">
            <Consultation @close-window="visibleConsultationDialog = false"></Consultation>
        </Dialog>
    </div>

</template>

<script setup>
import { ref, provide, onMounted, inject } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast';
import Toast from 'primevue/toast';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Consultation from '@/components/DialogsContent/consultation.vue';

const toast = useToast();
const visibleConsultationDialog = ref(false);
const loginPanel = ref(true);
const landingPage = ref(true);
const router = useRouter();
const isMobile = inject('isMobile');

const showToast = (severity, summary, detail) => {
    let msg = { severity: severity, summary: summary, detail: detail, life: 3000 };
    toast.add(msg);
};

const showLoginPanel = () => {
    loginPanel.value = true;
    landingPage.value = isMobile() ? false : true;
}

const showRegister = () => {
    loginPanel.value = true;
    landingPage.value = isMobile() ? false : true;
    router.push('/register');
}

const isLandingPageShown = () => {
    return landingPage.value;
}

onMounted(() => {
    if (router.currentRoute.value.query.redirect != '/dashboardAdmin' && router.currentRoute.value.fullPath != '/login') {
        loginPanel.value = true;
        landingPage.value = false;
    } else if (window.innerWidth < 1024) {
        landingPage.value = true;
        loginPanel.value = false;
    }
})

router.afterEach((to) => {
    if (to.fullPath != '/login' && to.query.redirect != '/dashboardAdmin') {
        loginPanel.value = true;
        landingPage.value = false;
    } else {
        landingPage.value = true;
        loginPanel.value = isMobile() ? false : true;
    }
})

provide('showToast', showToast);
provide('isLandingPageShown', isLandingPageShown);

</script>