<template>
    <div class="overflow-auto max-h-[60vh] border p-5 rounded-[5px]">
        <div class="relative">
            <table>
                <tr>
                    <th colspan="2">
                        <span class="flex-center">2. Dłużnik</span>
                    </th>
                </tr>
                <tr>
                    <th style="width: 30%;">2.1. Imię:</th>
                    <td>{{ name }}</td>
                </tr>
                <tr>
                    <th>2.2. Nazwisko:</th>
                    <td>{{ surname }}</td>
                </tr>
                <tr>
                    <th>2.3. PESEL*:</th>
                    <td>{{ pesel }}</td>
                </tr>
                <tr>
                    <th>2.4. NIP**:</th>
                    <td>Nie posługuje się</td>
                </tr>

            </table>

            <table>
                <tr>
                    <th colspan="2">
                        <span class="flex-center">Miejsce zamieszkania i adres dłużnika</span>
                    </th>
                </tr>
                <tr>
                    <th style="width: 30%;">2.5. Kraj:</th>
                    <td>Polska</td>
                </tr>
                <tr>
                    <th>2.6. Miejscowość:</th>
                    <td>{{ city }}</td>
                </tr>
                <tr>
                    <th>2.7. Ulica:</th>
                    <td>{{ street }}</td>
                </tr>
                <tr>
                    <th>2.8. Numer budynku:</th>
                    <td>{{ building }}</td>
                </tr>
                <tr>
                    <th>2.9. Numer lokalu:</th>
                    <td :class="{ 'cross': apartment == '' }">{{ apartment }}</td>
                </tr>
                <tr>
                    <th>2.10. Kod pocztowy:</th>
                    <td>{{ postalcode }}</td>
                </tr>
                <tr>
                    <th>2.11. Czy adres do doręczeń jest inny niż adres miejsca zamieszkania dłużnika?</th>
                    <td>
                        <div class="flex-col">
                            <div class="flex-row" style="width: 60%; justify-content: start">
                                <div class="square" :class="{ 'double-cross': cross1 }"></div>
                                <span style="margin-left: 0.5rem">TAK (należy wypełnić rubryki od 2.12. do 2.17.)</span>
                            </div>
                            <div class="flex-row" style="margin-top: 0.25rem;width: 60%; justify-content: start">
                                <div class="square" :class="{ 'double-cross': cross2 }"></div>
                                <span style="margin-left: 0.5rem">NIE (należy przekreślić rubryki od 2.12. do
                                    2.17.)</span>
                            </div>
                        </div>


                    </td>
                </tr>
            </table>

            <table>
                <tr>
                    <th colspan="2">
                        <span class="flex-center">Adres do doręczeń dłużnika</span>
                    </th>
                </tr>
                <tr>
                    <th style="width: 30%;">2.12. Kraj:</th>
                    <td :class="{ 'cross': cross3 }">{{ delivery_country }}</td>
                </tr>
                <tr>
                    <th>2.13. Miejscowość:</th>
                    <td :class="{ 'cross': cross3 }">{{ delivery_city }}</td>
                </tr>
                <tr>
                    <th>2.14. Ulica:</th>
                    <td :class="{ 'cross': cross3 }">{{ delivery_street }}</td>
                </tr>
                <tr>
                    <th>2.15. Numer budynku:</th>
                    <td :class="{ 'cross': cross3 }">{{ delivery_building }}</td>
                </tr>
                <tr>
                    <th>2.16. Numer lokalu:</th>
                    <td :class="{ 'cross': cross3 || cross4 }">{{ delivery_apartment }}</td>
                </tr>
                <tr>
                    <th>2.17. Kod pocztowy:</th>
                    <td :class="{ 'cross': cross3 }">{{ delivery_postalcode }}</td>
                </tr>
            </table>

            <table>
                <tr>
                    <th colspan="2">
                        <span class="flex-center">Adres poczty elektronicznej dłużnika</span>
                    </th>
                </tr>
                <tr>
                    <th style="width: 30%;">2.18. Czy dłużnik posiada adres poczty elektronicznej?</th>
                    <td>
                        <div class="flex-col">
                            <div class="flex-row" style="width: 60%; justify-content: start">
                                <div class="square double-cross"></div>
                                <span style="margin-left: 0.5rem">TAK (należy wypełnić rubrykę 2.19)</span>
                            </div>
                            <div class="flex-row" style="margin-top: 0.25rem;width: 60%; justify-content: start">
                                <div class="square"></div>
                                <span style="margin-left: 0.5rem">NIE (należy przekreślić rubrykę 2.19)</span>
                            </div>
                        </div>


                    </td>
                </tr>
                <tr>
                    <th>2.19. Adres poczty elektronicznej:</th>
                    <td>{{ email }}</td>
                </tr>
            </table>

            <table>
                <tr>
                    <th colspan="2">
                        <span class="flex-center">Przedstawiciel ustawowy dłużnika</span>
                    </th>
                </tr>
                <tr>
                    <th style="width: 30%;">2.20. Czy dłużnik działa przez przedstawiciela ustawowego?</th>
                    <td>
                        <div class="flex-col">
                            <div class="flex-row" style="width: 60%; justify-content: start">
                                <div class="square"></div>
                                <span style="margin-left: 0.5rem">TAK (należy wypełnić rubryki od 2.21. do 2.35.)</span>
                            </div>
                            <div class="flex-row" style="margin-top: 0.25rem;width: 60%; justify-content: start">
                                <div class="square double-cross"></div>
                                <span style="margin-left: 0.5rem">NIE (należy przekreślić rubryki od 2.21. do
                                    2.35.)</span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>2.21. Imię przedstawiciela ustawowego:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.22. Nazwisko przedstawiciela ustawowego:</th>
                    <td class="cross"></td>
                </tr>
            </table>

            <table>
                <tr>
                    <th colspan="2">
                        <span class="flex-center">Miejsce zamieszkania i adres przedstawiciela ustawowego
                            dłużnika</span>
                    </th>
                </tr>
                <tr>
                    <th style="width: 30%;">2.23. Kraj:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.24. Miejscowość:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.25. Ulica:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.26. Numer budynku:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.27. Numer lokalu:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.28. Kod pocztowy:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.29. Czy adres do doręczeń jest inny niż adres zamieszkania przedstawiciela ustawowego?</th>
                    <td>
                        <div class="flex-col">
                            <div class="flex-row" style="width: 60%; justify-content: start">
                                <div class="square"></div>
                                <span style="margin-left: 0.5rem">TAK (należy wypełnić rubryki od 2.30. do 2.35.)</span>
                            </div>
                            <div class="flex-row" style="margin-top: 0.25rem;width: 60%; justify-content: start">
                                <div class="square double-cross"></div>
                                <span style="margin-left: 0.5rem">NIE (należy przekreślić rubryki od 2.30. do
                                    2.35.)</span>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>

            <table>
                <tr>
                    <th colspan="2">
                        <span class="flex-center">Adres do doręczeń przedstawiciela ustawowego dłużnika</span>
                    </th>
                </tr>
                <tr>
                    <th style="width: 30%;">2.30. Kraj:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.31. Miejscowość:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.32. Ulica:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.33. Numer budynku:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.34. Numer lokalu:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.35. Kod pocztowy:</th>
                    <td class="cross"></td>
                </tr>
            </table>

            <table>
                <tr>
                    <th colspan="2">
                        <span class="flex-center">Pełnomocnik dłużnika</span>
                    </th>
                </tr>
                <tr>
                    <th>2.36. Czy dłużnik ma pełnomocnika?</th>
                    <td>
                        <div class="flex-col">
                            <div class="flex-row" style="width: 60%; justify-content: start">
                                <div class="square"></div>
                                <span style="margin-left: 0.5rem">TAK (należy wypełnić rubryki od 2.37. do 2.51.)</span>
                            </div>
                            <div class="flex-row" style="margin-top: 0.25rem;width: 60%; justify-content: start">
                                <div class="square double-cross"></div>
                                <span style="margin-left: 0.5rem">NIE (należy przekreślić rubryki od 2.37. do
                                    2.51.)</span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th style="width: 30%;">2.37. Imię pełnomocnika:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.38. Nazwisko pełnomocnika:</th>
                    <td class="cross"></td>
                </tr>
            </table>

            <table>
                <tr>
                    <th colspan="2">
                        <span class="flex-center">Miejsce zamieszkania lub siedziba i adres pełnomocnika dłużnika</span>
                    </th>
                </tr>
                <tr>
                    <th style="width: 30%;">2.39. Kraj:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.40. Miejscowość:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.41. Ulica:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.42. Numer budynku:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.43. Numer lokalu:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.44. Kod pocztowy:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.45. Czy adres do doręczeń jest inny niż adres miejsca zamieszkania lub siedziby pełnomocnika
                        dłużnika?</th>
                    <td>
                        <div class="flex-col">
                            <div class="flex-row" style="width: 60%; justify-content: start">
                                <div class="square"></div>
                                <span style="margin-left: 0.5rem">TAK (należy wypełnić rubryki od 2.46. do 2.51.)</span>
                            </div>
                            <div class="flex-row" style="margin-top: 0.25rem;width: 60%; justify-content: start">
                                <div class="square double-cross"></div>
                                <span style="margin-left: 0.5rem">NIE (należy przekreślić rubryki od 2.46. do
                                    2.51.)</span>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>

            <table>
                <tr>
                    <th colspan="2">
                        <span class="flex-center">Adres do doręczeń pełnomocnika dłużnika</span>
                    </th>
                </tr>
                <tr>
                    <th style="width: 30%;">2.46. Kraj:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.47. Miejscowość:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.48. Ulica:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.49. Numer budynku:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.50. Numer lokalu:</th>
                    <td class="cross"></td>
                </tr>
                <tr>
                    <th>2.51. Kod pocztowy:</th>
                    <td class="cross"></td>
                </tr>
            </table>

            <table>
                <tr>
                    <th colspan="2">
                        <span class="flex-center">3. Żądanie wniosku:</span>
                    </th>
                </tr>
                <tr>
                    <th style="width: 50%;">3.1. Dłużnik wnosi o ogłoszenie swojej upadłości*</th>
                    <td>
                        <div class="flex-col">
                            <div class="flex-row" style="width: 15%; justify-content: start">
                                <div class="square double-cross"></div>
                                <span style="margin-left: 0.5rem">TAK</span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>3.2. Czy dłużnik wyraża zgodę na udział w postępowaniu <br>o zawarcie układu na zgromadzeniu
                        wierzycieli?**</th>
                    <td>
                        <div class="flex-col">
                            <div class="flex-row" style="width: 15%; justify-content: start">
                                <div class="square"></div>
                                <span style="margin-left: 0.5rem">TAK</span>
                            </div>
                            <div class="flex-row" style="margin-top: 0.25rem;width: 15%; justify-content: start">
                                <div class="square double-cross"></div>
                                <span style="margin-left: 0.5rem">NIE</span>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>

            <table>
                <thead>
                    <tr>
                        <th colspan="6">
                            <span class="flex-center">4. Aktualny i zupełny wykaz majątku z szacunkową wyceną</span>
                        </th>
                    </tr>
                    <tr>
                        <th colspan="6">
                            <span class="flex-center">4.1. Składniki majątku</span>
                        </th>
                    </tr>
                    <tr>
                        <th style="width: 5%;">Lp.</th>
                        <th style="width: 20%;">Opis składnika majątku</th>
                        <th style="width: 20%;">Informacja czy na danym składniku majątku ustanowiono zabezpieczenie
                        </th>
                        <th style="width: 15%;">Data ustanowienia zabezpieczenia</th>
                        <th style="width: 15%;">Szacunkowa wycena składnika majątku</th>
                        <th style="width: 25%;">Miejsce, w którym znajduje się składnik majątku dłużnika</th>
                    </tr>
                </thead>
                <tbody v-html="assets"></tbody>
            </table>
            <table>
                <tr>
                    <td style="width: 5%;" rowspan="2">{{ assets_count }}.</td>
                    <th style="width: 20%;" rowspan="2">Środki pieniężne w gotówce</th>
                    <th style="width: 20%;">Waluta</th>
                    <th style="width: 25%;">Kwota</th>
                    <th style="width: 30%;">Miejsce, w którym znajdują się środki pieniężne</th>
                </tr>
                <tr>
                    <td>Nie posiadam</td>
                    <td class="cross"></td>
                    <td class="cross"></td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { format } from 'date-fns';

const props = defineProps({
    objectUUID: String
});

const name = ref();
const surname = ref();
const pesel = ref();
const email = ref();
const city = ref();
const street = ref();
const building = ref();
const apartment = ref();
const postalcode = ref();
const cross1 = ref();
const cross2 = ref();
const cross3 = ref();
const cross4 = ref();
const delivery_country = ref();
const delivery_city = ref();
const delivery_street = ref();
const delivery_building = ref();
const delivery_apartment = ref();
const delivery_postalcode = ref();
const assets_count = ref();
const assets = ref();

onMounted(() => {
    jsonToHtmlMain();
})

async function jsonToHtmlMain() {
    let jsonData;
    let response = await fetch("/api/getObject", {
        body: JSON.stringify({
            objectUUID: props.objectUUID,
            functionalityUUID: "c98b6831-bc1a-4d84-b78a-fbb1188ca440"
        })
    });

    if (response.ok) {
        jsonData = await response.json();
        // process jsonData
    } else {
        console.error('Fetch error:', response.statusText);
    }

    jsonData = jsonData.result.root.properties;
    let data = JSON.parse(jsonData.data);
    let subObjectsVal = JSON.parse(jsonData.objects);
    // console.log("🚀 ~ jsonToHtmlMain ~ subObjectsVal:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", subObjectsVal)
    let formVals = data.formVal;


    let subObject = Array(10).fill("");

    for (let i = 0; i < subObjectsVal[0].length; i++) {
        const element = subObjectsVal[0][i];
        subObject[0] += `<tr><td>${i + 1}.</td>
        <td>${trimAndCapitalize(element.asset_description)}</td>
        <td>${element.asset_secured}</td>
        <td class="${element.asset_secured == "Nie" ? "cross" : ""}">${element.asset_secured == "Nie" ? "" : format(element.asset_securing_date, 'dd.MM.yyyy')}</td>
        <td>${getPrice(element.asset_price, element.currency)}</td>
        <td>
            <span>${trimAndCapitalize(element.asset_street, "ul")} ${element.asset_house_number.trim()}${element.asset_flat_number !== undefined && element.asset_flat_number.trim() != "" ? " m. " + element.asset_flat_number.trim() : ""}</span><br>
            <span>${element.asset_postal_code.trim()} ${trimAndCapitalize(element.asset_city)}, ${trimAndCapitalize(element.asset_country)}</span>
        </td></tr>`;
    }


    name.value = trimAndCapitalize(jsonData.name);
    surname.value = trimAndCapitalize(jsonData.surname);
    pesel.value = jsonData.pesel;
    email.value = formVals[4]?.trim().toLowerCase();
    city.value = trimAndCapitalize(formVals[6]);
    street.value = trimAndCapitalize(formVals[7]);
    building.value = formVals[8]?.trim();
    apartment.value = formVals[9] && formVals[9]?.trim() != "" ? formVals[9]?.trim() : '';
    postalcode.value = formVals[10];
    cross1.value = formVals[11] == "Nie" ? true : false;
    cross2.value = formVals[11] == "Tak" ? true : false;
    cross3.value = formVals[11] == "Tak" ? true : false;
    cross4.value = formVals[16] !== null ? false : true;
    delivery_country.value = formVals[11] == "Nie" ? trimAndCapitalize(formVals[12]) : "";
    delivery_city.value = formVals[11] == "Nie" ? trimAndCapitalize(formVals[13]) : "";
    delivery_street.value = formVals[11] == "Nie" ? trimAndCapitalize(formVals[14]) : "";
    delivery_building.value = formVals[11] == "Nie" ? formVals[15]?.trim() : "";
    delivery_apartment.value = formVals[11] == "Nie" ? formVals[16]?.trim() + (formVals[17]?.trim() != "" && formVals[17] ? " skrt. poczt." + formVals[17]?.trim() : "") : "";
    delivery_postalcode.value = formVals[11] == "Nie" ? formVals[18]?.trim() : "";
    assets_count.value = subObjectsVal[0].length == 0 ? 2 : subObjectsVal[0].length + 1;
    assets.value = subObject[0] == "" ? '<tr><td>1.</td><td>Nie posiadam</td><td class="cross"></td><td class="cross"></td><td class="cross"></td><td class="cross"></td></tr>' : subObject[0];
}

function trimAndCapitalize(str, type = "") {
    str = (str === null || str === undefined) ? "" : str;
    str = str.trim().replace(/ +/g, " ");
    let name = str.replace(/^(aleja|ul|al)\.*/gi, "").trim();
    let oldstr = str;
    str = "";
    if (type == "ul") {
        if (oldstr.match(/^(aleja|al)\.*/gi)) str = "al. ";
        else str = "ul. ";
    }
    str += name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    return str;
}

function getPrice(num, cur) {
    num = (num + "").replace(/\B(?=(\d{3})+(?!\d))/g, " ")

    if (cur == "PLN") cur = "zł";
    else if (cur == "USD") return "$ " + num;
    else if (cur == "EUR") cur = "€";
    else if (cur == "GBP") return "£ " + num;
    // else if (cur == "JPY") return "¥ " + num;
    else if (cur == "SEK" || cur == "NOK") cur = "kr";
    else if (cur == "CZK") cur = "Kč";
    else if (cur == "HUF") cur = "Ft";

    return num + " " + cur;
}
</script>

<style scoped>
* {
    font-size: 0.85rem;
}

ol>li {
    font-size: 0.8rem;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: -1px;
}

th {
    border: 1px solid black;
    padding: 0.25rem;
    padding-left: 0.5rem;
    word-break: break-word;
    text-wrap: wrap;
}

:deep(td) {
    border: 1px solid black;
    padding: 0.25rem;
    padding-left: 0.5rem;
    word-break: break-word;
    text-wrap: wrap;
}

span {
    word-break: break-word;
    text-wrap: wrap;
}

:deep(th) {
    background-color: #bfbfbf;
    vertical-align: top;
    text-align: left;
}

thead th {
    text-align: center;
}

:deep(td) {
    text-align: left;
}

tr {
    page-break-inside: avoid;
}

tbody tr:first-child {
    page-break-before: avoid;
}

.w-full {
    width: 100%;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flex-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

li {
    text-align: justify;
    font-weight: normal;
    font-size: 1rem;
}

.square {
    content: ' ';
    width: 15px;
    /* Adjust size as needed */
    height: 15px;
    border: 1px solid black;
    /* Black border */
}

:deep(.cross) {
    background-image: linear-gradient(to bottom right, transparent calc(50% - 2px), black, transparent calc(50% + 2px))
        /*,linear-gradient(to bottom left,  transparent calc(50% - 1px), black, transparent calc(50% + 1px))*/
    ;
}

.double-cross {
    background-image: linear-gradient(to bottom right, transparent calc(50% - 2px), black, transparent calc(50% + 2px)), linear-gradient(to bottom left, transparent calc(50% - 2px), black, transparent calc(50% + 2px));
}
</style>