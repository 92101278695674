import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const userdataStore = defineStore('basicData', () => {
  const username = ref('');
  const name = ref('');
  const surname = ref('');
  const email = ref('');
  const defaultOrg = ref('');
  const firstLogin = ref(false);
  const admin = ref(false); //disgusting - get the fuck out of here with this shit as soon as possible

  function clear() {
    username.value = '';
    name.value = '';
    surname.value = '';
    email.value = '';
    defaultOrg.value = '';
    firstLogin.value = false;
    admin.value = false;
  }

  return { username, name, surname, email, defaultOrg, firstLogin, admin, clear }
})
