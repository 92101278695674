<template>
    <main class="mt-0 pl-3 pr-2 pb-3 flex flex-col justify-start items-center gap-3">
        <div class="h-max lg:w-11/12 xl:w-10/12 w-full min-w-64 flex 2xl:flex-row flex-col gap-3">
            <Card v-if="!loading" class="w-full flex-[3] h-fit"
                :pt="{ content: { style: 'padding-bottom: 0.75rem;padding-top: 0' } }">
                <template #header>
                    <ProgressBar v-if="generating" mode="indeterminate" style="height: 6px"></ProgressBar>
                    <div v-if="userdata.admin" class="flex justify-center p-5 gap-5 border-blue-500 border-2">
                        Debug inputs:
                        <Button label="zapisz" severity="secondary" outlined @click="save" />
                        <span>ID: {{ active }}</span>
                        <InputNumber @update:modelValue="changeId()" ref="idInput" v-model="newId"
                            placeholder="id zaczyna się od 0" />
                    </div>
                    <div class="px-5 pt-5 flex justify-between items-center">
                        <div class="w-32">
                            <Button v-if="previousInputCond()" ref="btnprev" label="Cofnij" severity="secondary"
                                :disabled="generating" outlined @click="previousInput" />
                        </div>
                        <h1 class="text-xl font-bold flex justify-center items-center">{{ getTitle() }}</h1>
                        <div class="w-32 flex items-end justify-end">
                            <Button v-if="nextInputCond()" ref="btnnext" label="Dalej"
                                :disabled="disableNextBtn || generating" severity="secondary" outlined
                                @click="nextInput" />
                        </div>
                    </div>
                </template>

                <template #content>
                    <div class="w-full flex justify-center " v-for="(item, index) in formData">
                        <div class="w-10/12" v-if="item.component === 'SubObject'">
                            <div v-if="item.id === active" class="w-full flex flex-col justify-center items-center">
                                <h2>{{ item.labels[item.optionid] }}</h2>
                                <div class="flex justify-center gap-5 pt-5">
                                    <Button label="Tak" outlined @click="acceptSubObject" />
                                    <Button label="Nie" severity="secondary" outlined :disabled="disableNextBtn"
                                        @click="rejectSubObject" />
                                </div>
                            </div>
                            <div v-for="(subitem, subindex) in item.content">
                                <FormField v-if="subitem.id === active && subitem.component !== 'Instruction'"
                                    :ref="references[subitem.uuid]" v-bind="subitem"
                                    v-model="activeObject[subitem.name]" @update:modelValue="custom(subitem.name)" />
                                <div v-else-if="subitem.id === active" class="w-full">
                                    <div class="font-bold text-xl mb-3">Instrukcja</div>

                                    <div v-if="instructionData[active].content">
                                        <div v-for="(item, index) in getArray(instructionData[active].content)"
                                            :key="index">
                                            <div v-if="item.type == 'image'"
                                                class="w-full flex justify-center items-center">
                                                <Image :src="item.image" alt="Image" class="w-2/12 mb-3" preview />
                                            </div>
                                            <p v-if="item.type == 'text'" class="m-0 text-justify"
                                                v-html="getInstructionText(item)" />
                                            <p v-if="item.type == 'list-ul'" class="m-0 text-justify">
                                            <ul class="list-disc list-outside pl-3">
                                                <li v-for="(listItem, listItemIndex) in item.content"
                                                    :key="listItemIndex">
                                                    {{ listItem }}
                                                </li>
                                            </ul>
                                            </p>
                                            <div v-if="item.type == 'btn'" class="w-full flex justify-center py-3">
                                                <div class="flex flex-col">
                                                    <Button :label="item.btnTitle || 'Pobierz formularz'"
                                                        :disabled="generating" @click="downloadFile(item)" />
                                                    <span v-if="generating"
                                                        class="mt-3 text-[0.75rem] text-surface-400">{{ btnActiveText
                                                        }}</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="w-full flex justify-center items-center">
                                            <Image v-if="instructionData[active]?.image"
                                                :src="instructionData[active].image" alt="Image" class="w-2/12 mb-3"
                                                preview />
                                        </div>

                                        <p v-if="instructionData[active].function" class="m-0 text-justify"
                                            v-html="instructionData[active].function()" />
                                        <p v-else class="m-0 text-justify"
                                            v-html="getInstructionText(instructionData[active])" />

                                        <div v-if="instructionData[active].documentType"
                                            class="w-full flex justify-center pt-5">
                                            <div class="flex flex-col">
                                                <Button :label="instructionData[active].btnTitle || 'Pobierz formularz'"
                                                    :disabled="generating"
                                                    @click="downloadFile(instructionData[active])" />
                                                <span v-if="generating" class="mt-3 text-[0.75rem] text-surface-400">{{
                                                    btnActiveText }}</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div v-else-if="item.component === 'Instruction' && item.id === active" class="w-11/12">
                            <div class="font-bold text-xl mb-3">Instrukcja</div>
                            <div v-if="instructionData[active].content">
                                <div v-for="(item, index) in getArray(instructionData[active].content)" :key="index">
                                    <div v-if="item.type == 'image'" class="w-full flex justify-center items-center">
                                        <Image :src="item.image" alt="Image" class="w-2/12 mb-3" preview />
                                    </div>
                                    <p v-if="item.type == 'text'" class="m-0 text-justify"
                                        v-html="getInstructionText(item)" />
                                    <p v-if="item.type == 'list-ul'" class="m-0 text-justify">
                                    <ul class="list-disc list-outside pl-3">
                                        <li v-for="(listItem, listItemIndex) in item.content" :key="listItemIndex">
                                            {{ listItem }}
                                        </li>
                                    </ul>
                                    </p>
                                    <formView v-if="item.type == 'formView'" :objectUUID="uuid" />
                                    <div v-if="item.type == 'btn'" class="w-full flex justify-center py-3">
                                        <div class="flex flex-col">
                                            <Button :label="item.btnTitle || 'Pobierz formularz'" :disabled="generating"
                                                @click="downloadFile(item)" />
                                            <span v-if="generating" class="mt-3 text-[0.75rem] text-surface-400">{{
                                                btnActiveText }}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="w-full flex justify-center items-center">
                                    <Image v-if="instructionData[active]?.image" :src="instructionData[active].image"
                                        alt="Image" class="w-2/12 mb-3" preview />
                                </div>

                                <p v-if="instructionData[active].function" class="m-0 text-justify"
                                    v-html="instructionData[active].function()" />
                                <p v-else class="m-0 text-justify"
                                    v-html="getInstructionText(instructionData[active])" />

                                <div v-if="instructionData[active].documentType"
                                    class="w-full flex justify-center pt-5">
                                    <div class="flex flex-col">
                                        <Button :label="instructionData[active].btnTitle || 'Pobierz formularz'"
                                            :disabled="generating" @click="downloadFile(instructionData[active])" />
                                        <span v-if="generating && !progressBarActive"
                                            class="mt-3 text-[0.75rem] text-surface-400">{{ btnActiveText }}</span>
                                    </div>
                                </div>
                                <div class="w-full flex justify-center flex-col">
                                    <ProgressBar v-if="progressBarActive" :value="progressBarValue"
                                        class="mt-6 w-full h-[24px]"></ProgressBar>
                                    <span v-if="progressBarActive"
                                        class="mt-3 text-[0.75rem] text-center text-surface-400">{{ btnActiveText
                                        }}</span>
                                </div>

                            </div>
                        </div>
                        <div v-else-if="item.component === 'MultiLoop' && item.id === active">
                            <span>{{ item.labelStart + item.label(item.optionid) + item.labelEnd }}</span>
                        </div>
                        <div class="w-10/12" v-else-if="item.component === 'Checklist' && item.id === active">
                            <div class="flex flex-row justify-between">
                                <div>
                                    Dokument
                                </div>
                                <div class="text-right">
                                    Czy otrzymałeś/łaś odpowiedź na wysłany dokument?
                                </div>
                            </div>
                            <DataView :value="applications">
                                <template #list="slotProps">
                                    <div class="grid grid-nogutter">
                                        <div v-for="(item, index) in slotProps.items" :key="index" class="col-12">
                                            <div class="flex flex-col lg:flex-row lg:items-center p-4 gap-3"
                                                v-if="formValues[item.activation] == 'Tak'"
                                                :class="{ 'border-t border-surface-200 dark:border-surface-700': index !== 0 }">
                                                <div
                                                    class="flex flex-col md:flex-row justify-between md:items-center flex-1 gap-3">
                                                    <div
                                                        class="flex flex-row w-full md:flex-col justify-between items-start gap-2">

                                                        <div
                                                            class="text-lg font-medium text-surface-700 dark:text-surface-0/80 mt-2">
                                                            {{ item.name }}
                                                        </div>

                                                    </div>
                                                    <div class="flex flex-col items-end gap-5 w-full">
                                                        <div class="flex flex-row-reverse md:flex-row gap-2">
                                                            <FormField v-model="applications[index].val"
                                                                :ref="references[applications[index].uuid]"
                                                                v-bind="applications[index]"
                                                                @update:modelValue="checkChecklist()" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </DataView>
                        </div>
                        <div v-else class="w-10/12">
                            <FormField v-if="item.id === active" :ref="references[item.uuid]" v-bind="item"
                                v-model="formValues[item.id]" />
                        </div>
                    </div>
                </template>
            </Card>
            <div v-if="sideInstructionActive && !loading"
                class="h-max min-w-64 sticky top-0 2xl:max-w-96 2xl:w-[25rem] w-full flex-[1]">
                <div v-for="(item, instrIndex) in sideInstructionData">
                    <Card v-if="sideActive.includes(instrIndex)" class="mb-3">
                        <template #title>{{ item.label }}</template>
                        <template #content>
                            <p class="m-0" v-html="item.text" />
                        </template>
                    </Card>
                </div>
            </div>
            <div v-if="loading" class="w-full">
                <div
                    class="rounded shadow p-6 bg-surface-0 dark:bg-surface-900 flex justify-center items-center flex-col mb-4">
                    <div class="flex mb-4 w-full">
                        <div class="flex flex-row w-full justify-between">
                            <Skeleton width="5rem" height="80px" class="mb-2"></Skeleton>
                            <Skeleton width="20rem" height="80px" class="mb-2"></Skeleton>
                            <Skeleton width="5rem" height="80px" class="mb-2"></Skeleton>
                        </div>
                    </div>
                    <Skeleton width="80%" height="40px" class="mb-3"></Skeleton>
                    <Skeleton width="80%" height="80px"></Skeleton>
                </div>
                <div
                    class="rounded shadow p-6 bg-surface-0 dark:bg-surface-900 flex justify-center items-start flex-col">
                    <Skeleton width="20%" height="40px" class="mb-3"></Skeleton>
                    <Skeleton width="100%" height="80px"></Skeleton>
                </div>
            </div>
        </div>

        <Card v-if="instructionActive && !loading && !instructionData[active].mode"
            class="h-max min-w-64 lg:w-11/12 xl:w-10/12 w-full" :pt="{ content: { style: 'padding-bottom: 0' } }">
            <template #title>
                Instrukcja
            </template>
            <template #content>
                <div v-if="instructionData[active].content">
                    <div v-for="(item, index) in getArray(instructionData[active].content)" :key="index">
                        <div v-if="item.type == 'image'" class="w-full flex justify-center items-center">
                            <Image :src="item.image" alt="Image" class="w-2/12 mb-3" preview />
                        </div>
                        <p v-if="item.type == 'text'" class="m-0 text-justify" v-html="getInstructionText(item)" />
                        <p v-if="item.type == 'list-ul'" class="m-0 text-justify">
                        <ul class="list-disc list-outside pl-3">
                            <li v-for="(listItem, listItemIndex) in item.content" :key="listItemIndex">
                                {{ listItem }}
                            </li>
                        </ul>
                        </p>
                        <div v-if="item.type == 'btn'" class="w-full flex justify-center py-3">
                            <div class="flex flex-col">
                                <Button :label="item.btnTitle || 'Pobierz formularz'" :disabled="generating"
                                    @click="downloadFile(item)" />
                                <span v-if="generating" class="mt-3 text-[0.75rem] text-surface-400">{{ btnActiveText
                                }}</span>
                            </div>

                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="w-full flex justify-center items-center">
                        <Image v-if="instructionData[active]?.image" :src="instructionData[active].image" alt="Image"
                            class="w-2/12 mb-3" preview />
                    </div>

                    <p v-if="instructionData[active]?.function" class="m-0 text-justify"
                        v-html="instructionData[active].function()" />
                    <p v-else class="m-0 text-justify" v-html="getInstructionText(instructionData[active])" />

                    <div v-if="instructionData[active].documentType" class="w-full flex justify-center pt-5">
                        <div class="flex flex-col">
                            <Button :label="instructionData[active].btnTitle || 'Pobierz formularz'"
                                :disabled="generating" @click="downloadFile(instructionData[active])" />
                            <span v-if="generating" class="mt-3 text-[0.75rem] text-surface-400">{{ btnActiveText
                            }}</span>
                        </div>

                    </div>
                </div>
            </template>
        </Card>
    </main>
</template>

<script setup>
import { ref, inject, nextTick, onMounted } from 'vue'
import { format, max } from 'date-fns';
import { pl } from 'date-fns/locale';
import { userdataStore } from '@/stores/userData'
import { useRoute } from 'vue-router'
import { langObject } from '@/languages/pl.js';

import Button from 'primevue/button'
import Card from 'primevue/card';
import FormField from '@/components/forms/FormField.vue'
import formView from '@/components/content/formView.vue';
import DataView from 'primevue/dataview';
import Image from 'primevue/image';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import ProgressBar from 'primevue/progressbar';
import Skeleton from 'primevue/skeleton';
import router from '@/router';

const route = useRoute()
const userdata = userdataStore();
const showToast = inject('showToast');

const loading = ref(true);
const active = ref(0);
const maxActive = ref(0);
let previousDiff = true;
const instructionActive = ref(true);
const sideInstructionActive = ref(false);
const sideActive = ref([0]);

const btnnext = ref();
const btnprev = ref();
const lastJumpId = ref(0);
const jumped = ref(false);
const jumpedToEnd = ref(false);
const lastSectionEndId = ref(0);
const leaveOnEndId = ref(false);
const disableNextBtn = ref(false);
const generating = ref(false);
const btnActiveText = ref('Generowanie...');
const progressBarActive = ref(false);
const progressBarValue = ref(0);

const references = Array.from({ length: 400 }, (_, i) => ({}[i + ""] = ref()));
const savingMatrix = [21, 35, 36, 51, 58, 71, 82, 84, 100, 114, 115, 117, 131, 132, 133, 149, 163, 176, 197, 209, 227, 247, 251, 256, 261, 269, 270, 271, 272, 273, 274, 279, 280];
let maxVal = 288;
let KRDid = 51;

let formStatus = "";

const uuid = ref();
const formValues = ref(Array(300).fill(null));

formValues.value[KRDid] = 'Tak';
formValues.value[KRDid + 1] = 'Tak';
formValues.value[KRDid + 2] = 'Tak';

const activeObject = ref({});
const subObjects = ref(Array(12).fill(null));
const subObjectValues = ref(subObjects.value.reduce((acc, item, itemIndex) => {
    acc[itemIndex] = [];
    return acc;
}, {}));

const countries = ref(['Polska']);
const cities = ref([]);
const streets = ref([]);
const houseNumbers = ref([]);
const flatNumbers = ref([]);
const postalCodes = ref([]);

const dynamicQuery = ref();
const currency = ref();
const inspectors = ref(subObjectValues.value[3]);
const windycation_companies = ref(subObjectValues.value[2]);
const windycation_companies_data = [
    {
        windycation_company_name_select: 'Best',
        windycation_company_name: 'Best S.A.',
        windycation_company_city: 'Gdynia',
        windycation_company_street: 'Łużycka',
        windycation_company_house_number: '8A',
        windycation_company_postal_code: '81-537'
    },
    {
        windycation_company_name_select: 'Kruk/Prokura',
        windycation_company_name: 'Kruk S.A.',
        windycation_company_city: 'Wrocław',
        windycation_company_street: 'Wołowska',
        windycation_company_house_number: '8',
        windycation_company_postal_code: '51-116'
    },
    {
        windycation_company_name_select: 'Ultimo',
        windycation_company_name: 'Ultimo S.A.',
        windycation_company_city: 'Wrocław',
        windycation_company_street: 'Szczytnicka',
        windycation_company_house_number: '11',
        windycation_company_postal_code: '50-382'
    },
    {
        windycation_company_name_select: 'Koksztys',
        windycation_company_name: 'Koksztys Kancelaria Prawa Gospodarczego Sp. k.',
        windycation_company_city: 'Wrocław',
        windycation_company_street: 'Objazdowa',
        windycation_company_house_number: '50',
        windycation_company_postal_code: '54-513'
    },
    {
        windycation_company_name_select: 'Eos',
        windycation_company_name: 'Eos Poland sp. z o.o.',
        windycation_company_city: 'Warszawa',
        windycation_company_street: 'Siedmiogrodzka',
        windycation_company_house_number: '9',
        windycation_company_postal_code: '01-204'
    },
    {
        windycation_company_name_select: 'Hoist',
        windycation_company_name: 'Hoist Polska sp. z o.o.',
        windycation_company_city: 'Wrocław',
        windycation_company_street: 'Powstańców Śląskich',
        windycation_company_house_number: '9',
        windycation_company_postal_code: '53-332'
    },
    {
        windycation_company_name_select: 'Pra Group',
        windycation_company_name: 'PRA Group Polska sp. z o.o.',
        windycation_company_city: 'Warszawa',
        windycation_company_street: 'Prosta',
        windycation_company_house_number: '68',
        windycation_company_postal_code: '00-838'
    },
    {
        windycation_company_name_select: 'Raport/Axfina',
        windycation_company_name: 'Axfina Polska S.A.',
        windycation_company_city: 'Warszawa',
        windycation_company_street: 'Żwirki I Wigury',
        windycation_company_house_number: '16A',
        windycation_company_postal_code: '02-092'
    },
    {
        windycation_company_name_select: 'Kredyt Inkaso',
        windycation_company_name: 'Kredyt Inkaso S.A.',
        windycation_company_city: 'Warszawa',
        windycation_company_street: 'Postępu',
        windycation_company_house_number: '21B',
        windycation_company_postal_code: '02-676'
    },
    {
        windycation_company_name_select: 'Intrum Justitia',
        windycation_company_name: 'Intrum sp. z o.o.',
        windycation_company_city: 'Warszawa',
        windycation_company_street: 'Taśmowa',
        windycation_company_house_number: '7',
        windycation_company_postal_code: '02-677'
    },
    {
        windycation_company_name_select: 'Asekuracja',
        windycation_company_name: 'Asekuracja sp. z o.o.',
        windycation_company_city: 'Sopot',
        windycation_company_street: 'Władysława IV',
        windycation_company_house_number: '22',
        windycation_company_postal_code: '81-743'
    },
    {
        windycation_company_name_select: 'Aasa Polska',
        windycation_company_name: 'Aasa Polska S.A.',
        windycation_company_city: 'Warszawa',
        windycation_company_street: 'Hrubieszowska',
        windycation_company_house_number: '2',
        windycation_company_postal_code: '01-209'
    },
    {
        windycation_company_name_select: 'Debito NSFIZ',
        windycation_company_name: 'Debito NSFIZ',
        windycation_company_city: 'Warszawa',
        windycation_company_street: 'Sienna',
        windycation_company_house_number: '75',
        windycation_company_postal_code: '00-833'
    },
    {
        windycation_company_name_select: 'Presco Investments',
        windycation_company_name: 'Presco Investments S.A.',
        windycation_company_city: 'Wrocław',
        windycation_company_street: 'Wołowska',
        windycation_company_house_number: '8',
        windycation_company_postal_code: '51-116'
    },
];

//temp
const newId = ref(0);
const changeId = () => {
    setTimeout(() => {
        if (newId.value >= 0 && newId.value <= formData.value[formData.value.length - 1].id) active.value = newId.value;

    }, 300)
}
const idInput = ref();
//END temp
onMounted(() => {
    //temp
    idInput.value?.$el.querySelector('span input').classList.add('w-32');
    // END temp

    uuid.value = route.params.id;

    fetch("/api/getObject", {
        body: JSON.stringify({
            objectUUID: uuid.value,
            functionalityUUID: "c98b6831-bc1a-4d84-b78a-fbb1188ca440"
        })
    }).then(response => response.json()).then(data => {
        if (data.success == 1) {
            let formValData = data.result.root.properties.data != "" ? JSON.parse(data.result.root.properties.data) : {
                active: 0,
                previousDiff: true,
                instructionActive: false,
                sideInstructionActive: false,
                sideActive: [0],
                lastJumpId: 0,
                jumped: false,
                jumpedToEnd: false,
                lastSectionEndId: 0,
                disableNextBtn: false,
                countries: ['Polska'],
                cities: [],
                streets: [],
                houseNumbers: [],
                flatNumbers: [],
                postalCodes: [],
                formVal: Array(300).fill(null),
                optionMatrix: []
            };

            if (formValData.formVal[KRDid] === null) {
                formValData.formVal[KRDid] = 'Tak';
                formValData.formVal[KRDid + 1] = 'Tak';
                formValData.formVal[KRDid + 2] = 'Tak';
            }
            formStatus = data.result.root.properties.status != "" ? data.result.root.properties.status : "trial";

            active.value = formValData.active;
            previousDiff = formValData.previousDiff;
            instructionActive.value = formValData.instructionActive;
            sideInstructionActive.value = formValData.sideInstructionActive;
            sideActive.value = formValData.sideActive;
            lastJumpId.value = formValData.lastJumpId;
            jumped.value = formValData.jumped;
            jumpedToEnd.value = formValData.jumpedToEnd;
            lastSectionEndId.value = formValData.lastSectionEndId;
            disableNextBtn.value = formValData.disableNextBtn;
            countries.value = formValData.countries;
            cities.value = formValData.cities;
            streets.value = formValData.streets;
            houseNumbers.value = formValData.houseNumbers;
            flatNumbers.value = formValData.flatNumbers;
            postalCodes.value = formValData.postalCodes;
            formValues.value = formValData.formVal;
            console.log("🚀 ~ onMounted ~ formValData:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", formValData)

            subObjectValues.value = data.result.root.properties.objects != "" ? JSON.parse(data.result.root.properties.objects) : (subObjects.value.reduce((acc, item, itemIndex) => {
                acc[itemIndex] = [];
                return acc;
            }, {}));

            inspectors.value = subObjectValues.value[3];
            windycation_companies.value = subObjectValues.value[2];

            let i = 0;
            formData.value.filter(obj => obj.component === 'SubObject' || obj.component === 'MultiLoop').forEach(obj => {
                obj.optionid = formValData?.optionMatrix[i] !== undefined ? formValData.optionMatrix[i++] : 0;

            })

            loading.value = false;
        } else {
            console.log("🚀 ~ data ~ data:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", data)
        }
    })
});

function updateMaxActive() {
    maxActive.value = Math.max(active.value, maxActive.value)
}

const saveSubObject = (id, reset = true) => {
    // console.log("🚀 ~ saveSubObject ~ id:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", id)
    let mainObject = formData.value.find(obj => obj.id === id || obj.id <= id && obj.endid >= id);
    // console.log("🚀 ~ saveSubObject ~ mainObject:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", mainObject)
    // console.log("🚀 ~ saveSubObject ~ mainObject.optionid:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", mainObject.optionid)
    // if((mainObject.subObjectIndex == 6 || mainObject.subObjectIndex == 7) && mainObject.optionid < mainObject.options.length-1) {
    //     activeObject.value[mainObject.name] = mainObject.options[mainObject.optionid-1];
    // }
    // else 
    activeObject.value[mainObject.name] = mainObject.options[mainObject.optionid];
    delete activeObject.value["ignore"];

    let tempObj = { ...activeObject.value };
    let currentObjects = mainObject.content.filter(obj => obj.saveType === 'address');
    // console.log("🚀 ~ saveSubObject ~ currentObjects:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", currentObjects)
    let arr = [];
    for (let i = 0; i < currentObjects.length; i++) {
        if (currentObjects[i].name == 'country') arr = countries.value;
        else if (currentObjects[i].name == 'city') arr = cities.value;
        else if (currentObjects[i].name == 'street') arr = streets.value;
        else if (currentObjects[i].name == 'house_number') arr = houseNumbers.value;
        else if (currentObjects[i].name == 'flat_number') arr = flatNumbers.value;
        else if (currentObjects[i].name == 'postal_code') arr = postalCodes.value;

        if (activeObject.value[currentObjects[i].name] !== undefined && !arr.includes(activeObject.value[currentObjects[i].name])) arr.push(activeObject.value[currentObjects[i].name]);

        activeObject.value[currentObjects[i].saveName] = activeObject.value[currentObjects[i].name];
        delete activeObject.value[currentObjects[i].name];
    }

    let countryObject = mainObject.content.find(obj => obj.name === 'country');
    if (countryObject === undefined && currentObjects.length > 0) {
        activeObject.value['country'] = "Polska";
    }

    let currencyObject = mainObject.content.find(obj => obj.name === 'currency');
    let amountObject = mainObject.content.find(obj => obj.name === 'amount');
    if (currencyObject === undefined && amountObject !== undefined) {
        activeObject.value['currency'] = "PLN";
    }

    if (activeObject.value['creditor_type'] == 'windycation_company' && (activeObject.value['creditor_name'] == '' || activeObject.value['creditor_name'] == undefined)) {
        let multiloop = formData.value.find(obj => obj.id === mainObject.endid + 3);
        let tempoptionid = multiloop.optionid - 1;
        // console.log("🚀 ~ saveSubObject ~ multiloop:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", multiloop)

        activeObject.value['creditor_name'] = multiloop.loopArray[tempoptionid].windycation_company_name;
        activeObject.value['creditor_country'] = 'Polska';
        activeObject.value['creditor_city'] = multiloop.loopArray[tempoptionid].windycation_company_city;
        activeObject.value['creditor_street'] = multiloop.loopArray[tempoptionid].windycation_company_street;
        activeObject.value['creditor_house_number'] = multiloop.loopArray[tempoptionid].windycation_company_house_number;
        activeObject.value['creditor_flat_number'] = multiloop.loopArray[tempoptionid].windycation_company_flat_number;
        activeObject.value['creditor_postal_code'] = multiloop.loopArray[tempoptionid].windycation_company_postal_code;
    }
    else if (activeObject.value['creditor_type'] == 'US-ZASW' || activeObject.value['creditor_type'] == 'ZUS') {
        activeObject.value['creditor_country'] = 'Polska';
        activeObject.value['currency'] = 'PLN';
    }

    if (activeObject.value['document_name'] !== undefined && activeObject.value['document_name'] != "") {
        let currentObjects2 = mainObject.content.find(obj => obj.name === 'document_name');

        activeObject.value['fact'] = currentObjects2.fact;
    }

    if (activeObject.value['windycation_company_name_select'] !== undefined && activeObject.value['windycation_company_name_select'] != "Inna Firma") {
        let company = windycation_companies_data.find(obj => obj.windycation_company_name_select === activeObject.value['windycation_company_name_select']);

        activeObject.value = { ...company };
    }

    if (mainObject.subObjectIndex == 6) {
        activeObject.value.starting_date = new Date();
    }
    // if(mainObject.subObjectIndex == 4){
    let found = subObjectValues.value[mainObject.subObjectIndex].find(obj => JSON.stringify(obj) === JSON.stringify(activeObject.value));
    if (!found) {
        subObjectValues.value[mainObject.subObjectIndex].push({ ...activeObject.value });
    }
    /*}else{
        subObjectValues.value[mainObject.subObjectIndex].push(activeObject.value);
    }*/


    if (reset) activeObject.value = {};
    else activeObject.value = tempObj;
}

function convertToZL(val, currency) {
    if (!currency) currency = 1;
    return Math.round((val * currency + Number.EPSILON) * 100) / 100;
}

const save = async () => {
    try {
        let court_status = "never_matched";
        let courtObject = null;
        let desc = "";

        if (active.value == maxVal) {
            let currenciesMultiplayer = fetch('/api/getCurrencies');

            let courts = fetch('/api/getCourt', {
                body: JSON.stringify({
                    postalCode: formValues.value[10],
                })
            });

            let chat0 = fetch('/api/chatgpt-query', {
                body: JSON.stringify({
                    message: `Przeanalizuj opis napisany przez klienta: "${formValues.value[269]}"
                    Czy wynika z niego że posiada jakieś choroby lub dolegliwości?
                    Odpowiedz tylko "Tak" lub "Nie"`,
                })
            });
            let chat3 = fetch('/api/chatgpt-query', {
                body: JSON.stringify({
                    message: `Przeanalizuj opis napisany przez klienta: "${formValues.value[265]}"
                    Czy wynika z niego że zaciągł środki na rozwój biznesu/firmy/działalności?
                    Odpowiedz tylko "Tak" lub "Nie"`,
                })
            });


            let repairSituation = formValues.value[262] == "Tak";
            let ownCredit = formValues.value[264] == "Tak";
            let additionalCredit = (formValues.value[266] ? formValues.value[266] : "").trim().length > 0;


            let workingTitles = ["Wynagrodzenie - Umowa o pracę", "Wynagrodzenie - Umowa zlecenie", "Wynagrodzenie - Umowa o dzieło"];
            let workingIncomeObjects = subObjectValues.value[6].filter(obj => workingTitles.includes(obj.income_source));
            let workingIncome = workingIncomeObjects.length > 0;

            let manyCurrentWorkingIncomes = false;
            let manyPastWorkingIncomes = false;
            let foreignCurrentIncome = false;
            let foreignPastIncome = false;

            let countCurrentWorkIncomes = 0;
            let countPastWorkIncomes = 0;

            for (let i = 0; i < workingIncomeObjects.length; i++) {
                const element = workingIncomeObjects[i];

                let sum = 0;
                //no need for multiplayer of currency as only existance of income is needed
                sum += element.income_amount1;
                sum += element.income_amount2;
                sum += element.income_amount3;
                sum += element.income_amount4;
                sum += element.income_amount5;
                sum += element.income_amount6;

                if (element.income_amount1 > 0) {
                    countCurrentWorkIncomes++;
                    manyCurrentWorkingIncomes = workingIncomeObjects.length > 1 && countCurrentWorkIncomes > 1;
                }
                if (sum - element.income_amount1 > 0 && element.income_amount1 == 0) {
                    countPastWorkIncomes++;
                    manyPastWorkingIncomes = workingIncomeObjects.length > 1 && countPastWorkIncomes > 1;
                }
                if (element.currency1 != "PLN" && element.income_amount1 > 0) {
                    foreignCurrentIncome = true;
                }
                if (
                    (element.currency2 != "PLN" && element.income_amount2 > 0 ||
                        element.currency3 != "PLN" && element.income_amount3 > 0 ||
                        element.currency4 != "PLN" && element.income_amount4 > 0 ||
                        element.currency5 != "PLN" && element.income_amount5 > 0 ||
                        element.currency6 != "PLN" && element.income_amount6 > 0) &&
                    element.income_amount1 == 0
                ) {
                    foreignPastIncome = true;
                }
            }

            let incomeTitles = subObjectValues.value[6].map(obj => obj.income_source);
            let income = 0;
            let income_deduction = false;

            currenciesMultiplayer = await currenciesMultiplayer;
            if (currenciesMultiplayer.ok) {
                const data = await currenciesMultiplayer.json();
                currenciesMultiplayer = data.result;
            }

            for (let i = 0; i < subObjectValues.value[6].length; i++) {
                const element = subObjectValues.value[6][i];

                let sum = 0;
                sum += convertToZL(element.income_amount1, currenciesMultiplayer[element.currency1]);
                sum += convertToZL(element.income_amount2, currenciesMultiplayer[element.currency2]);
                sum += convertToZL(element.income_amount3, currenciesMultiplayer[element.currency3]);
                sum += convertToZL(element.income_amount4, currenciesMultiplayer[element.currency4]);
                sum += convertToZL(element.income_amount5, currenciesMultiplayer[element.currency5]);
                sum += convertToZL(element.income_amount6, currenciesMultiplayer[element.currency6]);

                income += Math.round(((sum / 6) + Number.EPSILON) * 100) / 100;

                if (element.income_deduction1 == "Otrzymywana kwota po egzekucji komorniczej" ||
                    element.income_deduction2 == "Otrzymywana kwota po egzekucji komorniczej" ||
                    element.income_deduction3 == "Otrzymywana kwota po egzekucji komorniczej" ||
                    element.income_deduction4 == "Otrzymywana kwota po egzekucji komorniczej" ||
                    element.income_deduction5 == "Otrzymywana kwota po egzekucji komorniczej" ||
                    element.income_deduction6 == "Otrzymywana kwota po egzekucji komorniczej")
                    income_deduction = true;
            }


            let expanses = 0;
            let aliments = 0;
            let alimentsPresent = false;

            for (let i = 0; i < subObjectValues.value[7].length; i++) {
                const element = subObjectValues.value[7][i];

                expanses += convertToZL(element.amount, currenciesMultiplayer[element.currency]); //spending_type,spending_name   

                if (element.spending_type == "Pozostałe wydatki") {
                    if (element.spending_name.match(/aliment/gi)) {
                        alimentsPresent = true;
                        aliments += convertToZL(element.amount, currenciesMultiplayer[element.currency]);
                    }
                }
            }

            let familyHelp = formValues.value[263] == "Tak";

            let sick = false;
            chat0 = await chat0;
            if (chat0.ok) {
                const data = await chat0.json();
                if (data.result.match(/tak/gi)) sick = true;
            }

            let bussinessPresent = false;
            chat3 = await chat3;
            if (chat3.ok) {
                const data = await chat3.json();
                if (data.result.match(/tak/gi)) bussinessPresent = true;
            }

            let assetsPresent = subObjectValues.value[0].length > 0;
            let receivablesPresent = subObjectValues.value[1].length > 0;

            let disputedReceivablesPresent = subObjectValues.value[5].length > 0;

            let legalActionsPresent = subObjectValues.value[8].length > 0;
            let legalActionsMovablesPresent = subObjectValues.value[9].length > 0;

            let male = formValues.value[3][10] % 2 == 1;

            let descParts = [
                `Podstawą wystąpienia z niniejszym wnioskiem o ogłoszenie wobec mnie upadłości jest fakt, iż nie prowadzę działalności gospodarczej i od co najmniej 3 m-cy stał${male ? "em" : "am"} się osobą niewypłacalną. Nie jestem bowiem w stanie dokonywać spłat zobowiązań wobec swoich wierzycieli i nie mam także takiej możliwości do ich spłaty w przyszłości, z powodu dużego łącznego salda zadłużenia. Wcześniej zawsze starał${male ? "em" : "am"} się na bieżąco regulować swoje zobowiązania, prawidłowo, ale i tak okazało się, że pomimo częściowej spłaty długów, pozostała kwota zamiast maleć, zwiększa się.`,

                (manyCurrentWorkingIncomes ? `Chciał${male ? "em" : "am"} poprawić swoją sytuację finansową poprzez posiadanie dodatkowego źródła dochodu w postaci dodatkowej pracy, abym mógł${male ? "" : "a"} spłacić długi i zacząć żyć spokojnie.` :
                    foreignCurrentIncome ? `Chciał${male ? "em" : "am"} poprawić swoją sytuację finansową i dlatego pracuję tymczasowo za granicą, abym mógł${male ? "" : "a"} spłacić długi.` :
                        manyPastWorkingIncomes ? `Chciał${male ? "em" : "am"} poprawić swoją sytuację finansową i dlatego podjął${male ? "em" : "am"} dodatkową pracę, żeby spłacać długi. ${sick ? `Jednak z przyczyn losowych, jak i zdrowotnych, niezawinionych przeze mnie, nie mogł${male ? "em" : "am"} tego kontynuować.` : ""}` :
                            foreignPastIncome ? `Chciał${male ? "em" : "am"} poprawić swoją sytuację finansową i dlatego pracował${male ? "em" : "am"} tymczasowo za granicą, żeby spłacać długi. ${sick ? `Jednak z przyczyn losowych, jak i zdrowotnych, niezawinionych przeze mnie, nie mogł${male ? "em" : "am"} tego kontynuować.` : ""}` :
                                `Chciał${male ? "em" : "am"} poprawić swoją sytuację finansową poprzez znalezienie dodatkowego źródła dochodu, abym mógł${male ? "" : "a"} spłacić długi i zacząć żyć spokojnie. ${sick ? "Jednak z przyczyn losowych, jak i zdrowotnych, niezawinionych przeze mnie, to mi się nie udało." : ""}`),

                `Obecnie mój dochód wynosi około ${income} zł netto z tytułu ...${income_deduction ? ", po potrąceniach przez Komornika Sądowego" : ""}.`,

                ` Natomiast średniomiesięczne koszty ponoszone na utrzymanie wynoszą około ${expanses} zł.`,

                `Ponadto mam zasądzone alimenty w wysokości ${aliments} zł, miesięcznie. `,

                `Zatem przy moich obecnych dochodach, ${familyHelp ? "bez pomocy rodziny, znajomych " : ""}nie mam możliwości zaspokojenia wszystkich koniecznych potrzeb życiowych, a co za tym idzie również spłaty zadłużenia.`,

                (ownCredit ? `Środki finansowe z zaciągniętych zobowiązań przeznaczył${male ? "em" : "am"} na ... ${additionalCredit ? `, a ponadto ...` : ``}` : `Moje zadłużenie pojawiło się w wyniku ...`),
                ``,
                ``,
                `Problemy związane z bardzo złą sytuacją finansową i stres z tym związany, doprowadziły do znacznego pogorszenia się stanu mojego zdrowia. `,//Podlegam stałej opiece lekarskiej ... w tym z powodu depresji w ...

                `Sytuacja moja przysparza mi również wiele problemów ${workingIncome ? "zarówno " : ""}w życiu rodzinnym${workingIncome ? ", jak i zawodowym" : ""}. ... Dłużej nie jestem w stanie tak żyć i zdał${male ? "em" : "am"} sobie sprawę, że bez oddłużenia poprzez postępowanie upadłościowe, nie jestem w stanie wyjść na prostą, nawet do końca życia. Przecież nie zaciągał${male ? "em" : "am"} zobowiązań na życie ponad stan, ani też żeby celowo narazić wierzycieli na szkodę, tylko aby ${bussinessPresent ? "rozwijać swój biznes, i " : ""}utrzymać podstawowy standard życia. 
                Dlatego dosz${male ? "edłem" : "łam"} do przekonania, iż złożenie niniejszego wniosku o ogłoszenie upadłości jest jedynym wyjściem z mojej bardzo trudnej, a wręcz tragicznej sytuacji.`,

                `Oświadczam, iż nie posiadam majątku.`,
                `Oświadczam, iż nie posiadam środków pieniężnych w gotówce.`,
                `Oświadczam, iż nie posiadam żadnych wierzytelności względem banków i spółdzielczych kas oszczędnościowo-pożyczkowych.`,
                `Oświadczam, iż nie posiadam żadnych wierzytelności względem innych podmiotów.`,
                `Oświadczam, iż nie posiadam żadnych wierzytelności spornych.`,
                `Oświadczam, iż nie dokonałem żadnych czynności prawnych w ostatnich dwunastu miesiącach przed dniem złożenia wniosku, których przedmiotem byłoby zbycie nieruchomości, akcji i udziałów w spółkach.`,
                `Oświadczam, iż nie dokonałem żadnych czynności prawnych, których przedmiotem były ruchomości, wierzytelności czy inne prawa, których wartość przekracza 10 000 zł.`,

                `Wnoszę zatem o ogłoszenie wobec mnie upadłości, jako osoby fizycznej nieprowadzącej działalności gospodarczej. <br>
                Zastrzegam, że istnieje możliwość ciążących na mnie innych zobowiązań, nie wykazanych we wniosku, ale z uwagi na brak wszystkich dokumentów, upływ czasu oraz niepamięć, mogł${male ? "em" : "am"} coś nieświadomie pominąć. Jeżeli otrzymam w tej kwestii jakąkolwiek informację czy korespondencję, to niezwłocznie uzupełnię ją w toku postępowania upadłościowego.<br><br>
                Jako osoba fizyczna nieprowadząca działalności gospodarczej wnoszę, o umożliwienie mi składania pism z pominięciem systemu KRZ, w formie papierowej, wysłanej pocztą i jednocześnie wnoszę o przesyłanie wszelkiej korespondencji do mnie również za pośrednictwem Poczty Polskiej.`
            ];

            let chat1 = fetch('/api/chatgpt-query', {
                body: JSON.stringify({
                    message: `Lista dochodów podana przez klienta: ${JSON.stringify(incomeTitles)}
                             Wykorzystaj podaną listę żeby uzupełnić to zdanie: "${descParts[2]}".
                             Użyj oficjalnych i ładnych sformułowań i zwróć jedynie tekst tego zdania.`,
                })
            });
            let chat2 = fetch('/api/chatgpt-query', {
                body: JSON.stringify({
                    message: `${ownCredit && additionalCredit ? `Opisy dostarczone przez klienta: "${formValues.value[265]}" oraz "${formValues.value[266]}"` : ``}
                             ${ownCredit && !additionalCredit ? `Opis dostarczony przez klienta: "${formValues.value[265]}"` : ""}
                             ${!ownCredit && additionalCredit ? `Opis dostarczony przez klienta: "${formValues.value[266]}"` : ""}
                             ${ownCredit && additionalCredit ? `Wykorzystaj opisy podane przez klienta żeby uzupełnić to zdanie: "${descParts[6]}". Przy pierwszym ... wykorzystaj pierwszy opis a przy drugim ... wykorzystaj drugi opis.`
                            : `Wykorzystaj opis podany przez klienta żeby uzupełnić to zdanie: "${descParts[6]}"`}
                             Użyj oficjalnych i ładnych sformułowań i zwróć jedynie tekst tego zdania.`,
                })
            });
            let chat4 = fetch('/api/chatgpt-query', {
                body: JSON.stringify({
                    message: `Opisy dostarczone przez klienta: "${formValues.value[267]}", "${formValues.value[268]}", "${formValues.value[275]}", 
                             Wykorzystaj podane opisy do opisania najważniejszych problemów klienta, jeśli pojawia się informacja o prowadzeniu firmy(działalności gospodarczej) to napisz że klient ją zamknął, uzupełnij to zdanie: "${descParts[10]}".
                             Użyj oficjalnych i ładnych sformułowań, zachowaj spójność wypowiedzi i zwróć jedynie tekst tego zdania.`,
                })
            });
            let chat5 = fetch('/api/chatgpt-query', {
                body: JSON.stringify({
                    message: `Opis dostarczony przez klienta: "${formValues.value[269]}"
                             Wykorzystaj opis podany przez klienta żeby rozwinąć opis jego sytuacji zdrowotnej, zacznij od tego zdania: "${descParts[9]}".
                             Pisz w pierwszej osobie tak jakbyś był klientem oraz użyj oficjalnych i ładnych sformułowań i zwróć jedynie tekst tego zdania.`,
                })
            });

            courts = await courts;

            if (courts.ok) {
                const data = await courts.json();
                if (data.result.length == 1) {
                    court_status = "matched";
                    courtObject = data.result[0];
                } else {
                    court_status = "not_matched";
                }
            }

            desc = descParts[0] + "<br>";
            if (repairSituation) desc += descParts[1] + "<br>\n\n";
            chat1 = await chat1;
            if (chat1.ok) {
                const data = await chat1.json();
                desc += data.result.replace(/"/gi, '') + "\n\n";
            }
            desc += descParts[3] + "<br>";
            if (alimentsPresent) desc += descParts[4] + "<br>";
            desc += descParts[5] + "<br>\n\n";
            chat2 = await chat2;
            if (chat2.ok) {
                const data = await chat2.json();
                desc += data.result.replace(/"/gi, '') + "<br>\n";
            }
            // chat3 = await chat3;
            // if (chat3.ok) {
            //     const data = await chat3.json();
            //     desc += data.result.replace(/"/gi, '')+"<br>";
            // }
            // chat4 = await chat4;
            // if (chat4.ok) {
            //     const data = await chat4.json();
            //     desc += data.result.replace(/"/gi, '')+"<br>";
            // }
            chat5 = await chat5;
            if (chat5.ok) {
                const data = await chat5.json();
                desc += data.result.replace(/"/gi, '') + "<br>\n\n";
            }
            // desc += descParts[10]+"<br>";
            chat4 = await chat4;
            if (chat4.ok) {
                const data = await chat4.json();
                desc += data.result.replace(/"/gi, '') + "<br>\n\n";
            }
            if (!assetsPresent) desc += descParts[11] + "<br>";
            desc += descParts[12] + "<br>";
            desc += descParts[13] + "<br>";
            if (!receivablesPresent) desc += descParts[14] + "<br>";
            if (!disputedReceivablesPresent) desc += descParts[15] + "<br>";
            if (!legalActionsPresent) desc += descParts[16] + "<br>";
            if (!legalActionsMovablesPresent) desc += descParts[17] + "<br>";
            desc += descParts[18] + "<br>";
        }

        let optMatrixes = formData.value.filter(obj => obj.component === 'SubObject' || obj.component === 'MultiLoop')
            .map(obj => obj.optionid);
        // console.log("🚀 ~ save ~ optMatrixes:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", optMatrixes)
        const response = await fetch('/api/objectModify', {
            body: JSON.stringify({
                object: {
                    root: {
                        labels: [
                            "SimpleForm"
                        ],
                        properties: {
                            name: formValues.value[1],
                            surname: formValues.value[2],
                            phone: formValues.value[5] || "",
                            pesel: formValues.value[3],
                            email: formValues.value[4],
                            data: JSON.stringify({
                                active: getSaveActive(),
                                previousDiff: previousDiff,
                                instructionActive: instructionActive.value,
                                sideInstructionActive: sideInstructionActive.value,
                                sideActive: sideActive.value,
                                lastJumpId: lastJumpId.value,
                                jumped: jumped.value,
                                jumpedToEnd: jumpedToEnd.value,
                                lastSectionEndId: lastSectionEndId.value,
                                disableNextBtn: disableNextBtn.value,
                                countries: countries.value,
                                cities: cities.value,
                                streets: streets.value,
                                houseNumbers: houseNumbers.value,
                                flatNumbers: flatNumbers.value,
                                postalCodes: postalCodes.value,
                                court: courtObject,
                                formVal: formValues.value,
                                optionMatrix: optMatrixes
                            }),
                            objects: JSON.stringify(subObjectValues.value),
                            description: desc,
                            status: active.value == maxVal ? 'verification_needed' : (formStatus == "trial" ? "trial" : 'requires_completion'),
                            statusCourt: court_status,
                            maxActive: active.value == maxVal ? maxVal : maxActive.value
                        },
                        uuid: uuid.value,
                        list_prop: {
                            dataType: "Map",
                            value: [

                            ]
                        },
                        orgUUID: "0a754dfd-5c83-4356-baff-1b687aaf3fd1",
                        mod_parent: 0,
                        mod_order: 0,
                        mod_org: 0,
                        mod: 2
                    },
                    formUUID: "169fe196-b2de-4d7d-8ebe-d1217251717a",
                    objects: {
                        dataType: "Map",
                        value: []
                    },
                    structure: {
                        dataType: "Map",
                        value: []
                    },
                    formEditDate: 1810959199504
                }
            })
        });

        if (response.ok) {
            const data = await response.json();
            console.log("🚀 ~ save ~ data:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", data)
            if (data.success == 1) {
                return 1;
                // id = data.result.objects[0].uuid;
                // if(!generating.value){
                //     disabled.value = false;
                //     let chosen = data.result.objects.length - 1
                //     progress.value = Math.round(data.result.objects[chosen].maxActive/289*100);
                //     status.value = data.result.objects[chosen].status;
                // }
            }
            else {
                showToast('error', 'Błąd', data.result);
            }
        }

    } catch (error) {
        console.error(error);
    }

    // console.log("🚀 ~ formValues:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", formValues)
    // console.log("🚀 ~ save ~ references[i+].value:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", references)
    // console.log("🚀 ~ subObjectValues ~ subObjectValues:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", subObjectValues)
    // for (let i = 0; i < 24; i++) {
    //     // console.log("🚀 ~ save ~ references[i+].value:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", references[i + ""].value)
    //     if (references[i + ""].value !== undefined && references[i + ""].value !== null && references[i + ""].value.length > 0) {
    //         // console.log("🚀 ~ save ~ references[i+].value:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", references[i + ""].value)
    //         references[i + ""].value[0].validate();
    //     }
    // }
    return 0;

}

const getSaveActive = () => {
    let mainObject = formData.value.find(obj => obj.id === active.value || obj.id <= active.value && obj.endid >= active.value);
    return mainObject.id;
}

const getTitle = () => {
    let mainObject = formData.value.find(obj => obj.id === active.value || obj.id <= active.value && obj.endid >= active.value);

    return mainObject?.title;
}

const getInstructionText = (instr) => {
    let text = instr?.text;
    if (Array.isArray(text)) {
        let mainObject = formData.value.find(obj => obj.id === active.value || obj.id <= active.value && obj.endid >= active.value);
        let id = 0;
        if (mainObject.optionSkipEvenOnNO) id = Math.floor(mainObject.optionid / 2);
        else id = mainObject.optionid;
        return text[id];
    } else {
        return text;
    }
}

const getArray = (instrContent) => {
    let mainObject = formData.value.find(obj => obj.id === active.value || obj.id <= active.value && obj.endid >= active.value);
    if (mainObject.component == 'Instruction') return instrContent;

    if (instrContent[0] !== null && !Array.isArray(instrContent[0])) return instrContent;

    let id = 0;
    if (mainObject.optionSkipEvenOnNO) id = Math.floor(mainObject.optionid / 2);
    else id = mainObject.optionid;
    return instrContent[id];
}

const custom = (name) => {
    if (name.match(/currency\d*/gi)) currency.value = activeObject.value[name]
}

const acceptSubObject = () => {
    previousDiff = true;
    jumped.value = false;

    let mainObject = formData.value.find(obj => obj.id === active.value);

    if (jumpedToEnd.value && lastSectionEndId.value == mainObject.id) {
        saveSubObject(lastSectionEndId.value);
        jumpedToEnd.value = false;
        lastSectionEndId.value = 0;
    }

    let option;
    if (mainObject.customBehaviour !== undefined) {
        option = mainObject.customBehaviour.find(obj => obj.optionid === mainObject.optionid);
        if (option !== undefined) {
            if (option.optionChangeOnYes && mainObject.optionid < mainObject.labels.length - 1 && active.value == option.jumpOnYes) mainObject.optionid += 1;

            if (active.value + 1 == option.jumpOnYes) active.value += 1;
            else {
                active.value = option.jumpOnYes;
                jumped.value = true;
                lastJumpId.value = mainObject.id;
            }

            if (option.exitOnYes) leaveOnEndId.value = true;
            else leaveOnEndId.value = false;
        }
    }

    if (option === undefined) {
        active.value += 1;
    }


    let newSection = formData.value.find(obj => obj.id === active.value || obj.id <= active.value && obj.endid >= active.value);

    if (newSection?.component === 'autoJump') {
        if (newSection?.cond()) lastJumpId.value = mainObject.id;

        while (newSection !== undefined && newSection.component === 'autoJump') {
            if (newSection.cond()) {
                active.value = newSection.jumpid;
                if (newSection.actionOnJump !== undefined) newSection.actionOnJump();
                jumped.value = true;
            } else active.value += 1;
            newSection = formData.value.find(obj => obj.id === active.value);
        }
    } else if (newSection?.component === 'SubObject') {
        newSection = newSection.content.find(obj => obj.id === active.value);
    }

    updateMaxActive();

    instructionActive.value = true;
    sideInstructionActive.value = false;
    if (newSection?.component === 'Instruction' || newSection?.component === 'Checklist') {

        instructionActive.value = false;
        if (newSection?.component === 'Checklist') {
            sideInstructionActive.value = true;
        }
    }
}

const rejectSubObject = () => {
    previousDiff = true;
    jumped.value = false;
    disableNextBtn.value = true;
    let mainObject = formData.value.find(obj => obj.id === active.value);

    if (jumpedToEnd.value && lastSectionEndId.value == mainObject.id) {
        saveSubObject(lastSectionEndId.value);
        jumpedToEnd.value = false;
        lastSectionEndId.value = 0;
    }

    let option;
    if (mainObject.customBehaviour !== undefined) {
        option = mainObject.customBehaviour.find(obj => obj.optionid === mainObject.optionid);
        if (option !== undefined) {
            if (active.value + 1 == option.jumpOnNo) active.value += 1;
            else {
                active.value = option.jumpOnNo;
                jumped.value = true;
                lastJumpId.value = mainObject.id;
            }
            if (option.exitOnNo) leaveOnEndId.value = true;
            else leaveOnEndId.value = false;
            if (option.optionChangeOnNo && mainObject.optionid < mainObject.labels.length - 1) mainObject.optionid += 1;
        }
    }

    if (option === undefined) {
        if (mainObject.optionid + 1 >= mainObject.labels.length) active.value = mainObject.endid + 1;
        else active.value = mainObject.id;
        if (mainObject.optionid < mainObject.labels.length - 1) mainObject.optionid++;

        if (mainObject.optionSkipEvenOnNO && mainObject.optionid < mainObject.labels.length - 1 && mainObject.optionid % 2 === 1) mainObject.optionid++;
        else if (mainObject.optionSkipEvenOnNO && mainObject.optionid % 2 === 1) active.value = mainObject.endid + 1;
    }

    let newSection = formData.value.find(obj => obj.id === active.value);

    if (newSection?.component === 'MultiLoop') {
        if (newSection.optionid == newSection.loopArray.length) {
            newSection.optionid = 0;
            active.value += 1;
            jumped.value = false;
            newSection = formData.value.find(obj => obj.id === active.value);
        }
    }

    if (newSection?.component === 'autoJump') {
        if (newSection?.cond()) lastJumpId.value = mainObject.id;

        while (newSection !== undefined && newSection.component === 'autoJump') {
            if (newSection.cond()) {
                active.value = newSection.jumpid;
                if (newSection.actionOnJump !== undefined) newSection?.actionOnJump();
                jumped.value = true;
            } else active.value += 1;
            newSection = formData.value.find(obj => obj.id === active.value);
        }
    }

    if (newSection?.component === 'Checklist') {
        buildCheckList();
    }

    updateMaxActive();

    instructionActive.value = true;
    sideInstructionActive.value = false;
    if (newSection?.component === 'Instruction' || newSection?.component === 'Checklist') {
        instructionActive.value = false;
        if (newSection?.component === 'Checklist') {
            sideInstructionActive.value = true;
        }
    }

    setTimeout(() => {
        disableNextBtn.value = false;
        if (savingMatrix.includes(newSection?.id) && newSection?.component !== 'SubObject') {
            save();
        }
    }, 350);
}

const nextInputCond = () => {
    let currentSection = formData.value.find(obj => obj.id === active.value && (obj.component === 'SubObject' || obj.component === 'Jump'));

    let dec = true;
    if (currentSection !== undefined) {
        dec = false;
    }

    currentSection = formData.value.find(obj => obj.id === active.value || obj.id <= active.value && obj.endid >= active.value)

    if (currentSection?.metadata?.nextInput === false) {
        return false;
    }

    return active.value < formData.value[formData.value.length - 1].id && dec;
}

const previousInputCond = () => {
    let currentSection = formData.value.find(obj => obj.id === active.value || obj.id <= active.value && obj.endid >= active.value);

    if (currentSection.id != active.value && currentSection.endid != active.value) {
        currentSection = currentSection.content.find(obj => obj.id === active.value);
    }

    if (currentSection.component === 'Checklist') {
        return false;
    }

    if (currentSection?.metadata?.prevInput === false) {
        return false;
    }

    let dec = true;
    if (currentSection?.generating) {
        dec = false;
    }

    return active.value > 0 && dec && previousDiff;
}

const nextInput = async () => {
    btnnext.value.$el.focus();
    disableNextBtn.value = true;
    let mainObject = formData.value.find(obj => obj.id === active.value || obj.id <= active.value && obj.endid >= active.value);
    let currentObject;

    if (mainObject.component === 'SubObject') {
        currentObject = mainObject.content.find(obj => obj.id === active.value);
    }
    else if (mainObject.frontComponent === 'Jump') currentObject = mainObject;

    let status = true;
    if (currentObject !== undefined && currentObject.component !== 'Instruction') {
        status = references[currentObject.uuid].value[0].validate();
    }
    else if (mainObject.component == 'Checklist') {
        status = checkChecklist(true);
    }
    else if (mainObject.component !== 'Instruction' && mainObject.component !== 'MultiLoop' && currentObject?.component !== 'Instruction') {
        status = references[mainObject.uuid].value[0].validate();

        let arr = [];
        if (mainObject.name == 'country') arr = countries.value;
        else if (mainObject.name == 'city') arr = cities.value;
        else if (mainObject.name == 'street') arr = streets.value;
        else if (mainObject.name == 'house_number') arr = houseNumbers.value;
        else if (mainObject.name == 'flat_number') arr = flatNumbers.value;
        else if (mainObject.name == 'postal_code') arr = postalCodes.value;

        if (mainObject.saveType == 'address' &&
            formValues.value[mainObject.id] !== undefined &&
            formValues.value[mainObject.id] !== null &&
            formValues.value[mainObject.id] !== '' &&
            !arr.includes(formValues.value[mainObject.id]))
            arr.push(formValues.value[mainObject.id]);
    }

    let newSection;

    if (status) {
        setTimeout(() => {
            previousDiff = true;
            if (mainObject.component == 'MultiLoop') {
                if (mainObject.optionid < mainObject.loopArray.length) {
                    mainObject.optionid++;
                    active.value = mainObject.jumpid;
                    if (mainObject.actionOnJump !== undefined) mainObject?.actionOnJump();
                    jumped.value = true;
                    lastJumpId.value = mainObject.id;
                } else {
                    mainObject.optionid = 0;
                    jumped.value = false;
                    active.value += 1;
                }
            }
            else if (currentObject !== undefined && currentObject.jumpid !== undefined && currentObject.component === 'Instruction') {
                active.value = currentObject.jumpid;
                if (currentObject.actionOnJump !== undefined) currentObject?.actionOnJump();
                jumped.value = true;
                lastJumpId.value = currentObject.id;
                if (mainObject.endid == active.value) jumpedToEnd.value = true;
            }
            else if (currentObject !== undefined &&
                currentObject.jumpid !== undefined &&
                (currentObject.options[currentObject.jumpActivation] == activeObject.value[currentObject.name] && !currentObject.negateJumpActivation ||
                    currentObject.options[currentObject.jumpActivation] == formValues.value[currentObject.id] && !currentObject.negateJumpActivation ||
                    currentObject.negateJumpActivation &&
                    currentObject.options.includes(activeObject.value[currentObject.name]) &&
                    currentObject.options[currentObject.jumpActivation] != activeObject.value[currentObject.name])) {
                active.value = currentObject.jumpid;
                if (currentObject.actionOnJump !== undefined) currentObject?.actionOnJump();
                jumped.value = true;
                lastJumpId.value = currentObject.id;
                if (mainObject.endid == active.value) jumpedToEnd.value = true;
            }
            else {
                if (currentObject?.actionOnNoJump !== undefined) currentObject?.actionOnNoJump();
                jumped.value = false;
                active.value += 1;
            }

            let currentSectionEnd = formData.value.find(obj => obj.endid === active.value);

            if (currentSectionEnd !== undefined) {
                if (!jumpedToEnd.value) saveSubObject(currentSectionEnd.id);
                else if (jumpedToEnd.value && !jumped.value) {
                    saveSubObject(lastSectionEndId.value);
                    jumpedToEnd.value = false;
                    lastSectionEndId.value = 0;
                }
                else lastSectionEndId.value = currentSectionEnd.id;

                if (leaveOnEndId.value) {
                    leaveOnEndId.value = false;
                    active.value += 1;
                }
                else active.value = currentSectionEnd.id;

                let option;
                if (currentSectionEnd.customBehaviour !== undefined) {
                    option = currentSectionEnd.customBehaviour.find(obj => obj.optionid === currentSectionEnd.optionid);
                    if (option !== undefined) {

                        if (option.optionChangeOnYes && currentSectionEnd.optionid < currentSectionEnd.labels.length - 1) currentSectionEnd.optionid += 1;
                    }
                }

                if (option === undefined) {
                    if (currentSectionEnd.optionChangeOnEntry && currentSectionEnd.optionid < currentSectionEnd.labels.length - 1) currentSectionEnd.optionid += 1;
                    else if (currentSectionEnd.optionChangeOddOnEntry && currentSectionEnd.optionid % 2 == 0 && currentSectionEnd.optionid < currentSectionEnd.labels.length - 1) currentSectionEnd.optionid += 1;
                }
            }

            newSection = formData.value.find(obj => obj.id === active.value);

            if (newSection?.component === 'MultiLoop') {
                if (formValues.value[newSection.id - 2] !== null) {
                    let obj = newSection.loopArray[newSection.loopArray.length == 1 ? 0 : newSection.optionid - 1];
                    let doc = {
                        document_name: newSection.documentLabel + ' ' + (obj?.windycation_company_name ? obj.windycation_company_name : obj.inspector_name + " " + obj.inspector_surname),
                        document_date: formValues.value[newSection.id - 2]
                    };

                    if (subObjectValues.value[newSection.subObjectIndex].find(obj => obj.document_name == doc.document_name) === undefined) {
                        subObjectValues.value[newSection.subObjectIndex].push(doc);
                    }
                    formValues.value[newSection.id - 2] = null;
                }

                if (newSection.optionid == newSection.loopArray.length) {
                    newSection.optionid = 0;
                    formValues.value[newSection.id - 2] = null;
                    jumped.value = false;
                    active.value += 1;
                    newSection = formData.value.find(obj => obj.id === active.value);
                }
            }

            if (newSection?.component === 'autoJump') {
                if (newSection?.cond() && !jumped.value) lastJumpId.value = newSection.id - 1;

                while (newSection !== undefined && newSection.component === 'autoJump') {
                    if (newSection.cond()) {
                        active.value = newSection.jumpid;
                        if (newSection.actionOnJump !== undefined) newSection?.actionOnJump();
                        jumped.value = true;
                    } else active.value += 1;

                    newSection = formData.value.find(obj => obj.id === active.value);
                }
            }

            if (newSection === undefined) {
                let main = formData.value.find(obj => obj.id <= active.value && obj.endid >= active.value);
                newSection = main.content.find(obj => obj.id === active.value);
            }

            if (newSection?.component === 'Checklist') {
                buildCheckList();
            }
            else if (newSection?.component === 'SubObject' && newSection.optionRenewal && newSection.optionid >= newSection.labels.length - 1 && currentSectionEnd === undefined) {
                newSection.optionid = 0;
            }

            updateMaxActive();

            instructionActive.value = true;
            sideInstructionActive.value = false;
            if (newSection !== undefined && (newSection.component === 'Instruction' || newSection.component === 'Checklist')) {
                instructionActive.value = false;
                if (newSection.component === 'Checklist') {
                    sideInstructionActive.value = true;
                }
            }
        }, 20)
    }

    setTimeout(() => {
        if (newSection?.generating) return;
        disableNextBtn.value = false;
        if (savingMatrix.includes(newSection?.id)) {
            save();
        }
    }, 350)
}

const buildCheckList = () => {
    for (let i = 0; i < subObjectValues.value[2].length; i++) {

        let company_name;
        if (subObjectValues.value[2][i].windycation_company_name_select == 'Inna Firma') {
            company_name = subObjectValues.value[2][i].windycation_company_name;
        } else {
            company_name = subObjectValues.value[2][i].windycation_company_name_select;
        }

        let obj = {
            name: 'Firma windykacyjna ' + company_name,
            activation: KRDid,
            val: null,
            uuid: (300 + i) + "",
            component: "SelectButton",
            sideInstructionId: 6,
            options: ['Tak', 'Nie'],
            normal: false,
            errorLabels: ['Odpowiedź na to pytanie jest wymagana', 'Dokumenty od firm windykacyjnych jest wymagany do dalszego wypełnienia wniosku'],
            conditions: [{
                type: "required"
            },
            {
                type: "value",
                option: 0
            }]
        };

        if (!applications.value.some(app => app.name === obj.name)) {
            applications.value.push(obj);
        }
    }

    for (let i = 0; i < subObjectValues.value[3].length; i++) {
        let obj = {
            name: 'Komornik ' + subObjectValues.value[3][i].inspector_name + " " + subObjectValues.value[3][i].inspector_surname,
            activation: KRDid,
            val: null,
            uuid: (350 + i) + "",
            component: "SelectButton",
            sideInstructionId: 7,
            options: ['Tak', 'Nie'],
            normal: false,
            errorLabels: ['Odpowiedź na to pytanie jest wymagana', 'Dokument od komornika jest wymagany do dalszego wypełnienia wniosku'],
            conditions: [{
                type: "required"
            },
            {
                type: "value",
                option: 0
            }]
        };

        if (!applications.value.some(app => app.name === obj.name)) {
            applications.value.push(obj);
        }
    }
}

const checkChecklist = (notchecked = false) => {
    let status = true;

    const lastObjValIndex = applications.value.findLastIndex(obj => obj.val !== null);

    sideActive.value = []

    for (let i = 0; i < (notchecked ? applications.value.length : lastObjValIndex + 1); i++) {
        if (formValues.value[applications.value[i].activation] == "Tak") {
            if (applications.value[i].val == null || applications.value[i].val == 'Nie') {
                status = references[applications.value[i].uuid].value[0].validate();
                sideActive.value.push(applications.value[i].sideInstructionId);
            }
        }
    }

    if (status) sideActive.value = [0];

    return status;
}

const previousInput = () => {
    previousDiff = false;
    btnprev.value.$el.focus();
    let mainObject = formData.value.find(obj => obj.id === active.value);
    if (mainObject === undefined) mainObject = formData.value.find(obj => obj.endid === active.value - 1);

    let previousActive = active.value;
    // console.log("🚀 ~ setTimeout ~ mainObject:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m1", mainObject)
    setTimeout(() => {
        if (mainObject?.component == 'MultiLoop' && mainObject?.optionid > 0) mainObject.optionid--;
        else if (jumped.value) {
            jumped.value = false;
            // console.log("🚀 ~ setTimeout ~ mainObject:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m2", mainObject)
            if (mainObject?.component === 'SubObject' && mainObject.optionid > 0 && active.value == lastJumpId.value) {

                if (mainObject?.optionSkipEvenOnNO && mainObject.optionid % 2 === 0 && mainObject.optionid >= 2) mainObject.optionid -= 2;
                else if (mainObject?.optionSkipEvenOnNO && mainObject.optionid % 2 === 1 && mainObject.optionid >= 3) mainObject.optionid -= 3;
                else mainObject.optionid--;
            }

            active.value = lastJumpId.value;
        }
        else if (mainObject !== undefined && mainObject.endid === active.value - 1) {
            // console.log("🚀 ~ setTimeout ~ mainObject:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m4", mainObject)
            active.value = mainObject.id;
        }
        else if (mainObject !== undefined && mainObject.id === active.value && mainObject.component === 'SubObject') {
            // console.log("🚀 ~ setTimeout ~ mainObject:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m3", mainObject)
            if (mainObject.optionid > 0) {
                if (mainObject?.optionSkipEvenOnNO && mainObject.optionid % 2 === 0 && mainObject.optionid >= 2) mainObject.optionid -= 2;
                else if (mainObject?.optionSkipEvenOnNO && mainObject.optionid % 2 === 1 && mainObject.optionid >= 3) mainObject.optionid -= 3;
                else if (mainObject?.optionSkipEvenOnNO) active.value -= 1;
                else mainObject.optionid--;
            }
            else active.value -= 1;
        }
        else active.value -= 1;

        let prevSection = formData.value.find(obj => obj.id === active.value || obj.endid == active.value);
        if (prevSection?.endid === active.value) active.value = prevSection.id;

        if (prevSection === undefined) {
            let main = formData.value.find(obj => obj.id <= active.value && obj.endid >= active.value);

            prevSection = main.content.find(obj => obj.id === active.value);
        }

        if (previousActive != active.value && prevSection.component === 'SubObject' && prevSection?.optionSkipEvenOnNO && prevSection?.optionid >= 1 && prevSection?.optionid % 2 == 0) prevSection.optionid -= 2;
        else if (previousActive != active.value && prevSection.component === 'SubObject' && prevSection?.optionSkipEvenOnNO && prevSection?.optionid >= 1) prevSection.optionid -= 1;
        else if (previousActive != active.value && prevSection.component === 'SubObject' && prevSection?.customBehaviour.find(obj => obj.optionid === prevSection?.optionid - 1)?.optionChangeOnNo && prevSection?.optionid >= 1) prevSection.optionid -= 1;
        else if (previousActive != active.value && prevSection.component === 'SubObject' && prevSection?.customBehaviour.find(obj => obj.optionid === prevSection?.optionid - 1)?.optionChangeOnYes && prevSection?.optionid >= 1) prevSection.optionid -= 1;

        if (mainObject?.component != 'MultiLoop' && prevSection.component == 'MultiLoop' && prevSection.optionid > 0) prevSection.optionid--;

        instructionActive.value = true;
        sideInstructionActive.value = false;
        if (prevSection.component === 'Instruction' || prevSection.component === 'Checklist') {
            instructionActive.value = false;
            if (prevSection.component === 'Checklist') {
                sideInstructionActive.value = true;
            }
        }
    }, 20)
}

const downloadFile = async (pdf) => {
    console.log("🚀 ~ downloadFile ~ pdf:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", pdf.documentType)
    let url, fileName;

    if (pdf.documentType == 'link') {
        let optMatrixes = formData.value.filter(obj => obj.component === 'SubObject' || obj.component === 'MultiLoop')
            .map(obj => obj.optionid);
        const response = await fetch('/api/objectModify', {
            body: JSON.stringify({
                object: {
                    root: {
                        labels: [
                            "SimpleForm"
                        ],
                        properties: {      
                            data: JSON.stringify({
                                active: 20,
                                previousDiff: previousDiff,
                                instructionActive: true,
                                sideInstructionActive: sideInstructionActive.value,
                                sideActive: sideActive.value,
                                lastJumpId: lastJumpId.value,
                                jumped: jumped.value,
                                jumpedToEnd: jumpedToEnd.value,
                                lastSectionEndId: lastSectionEndId.value,
                                disableNextBtn: disableNextBtn.value,
                                countries: countries.value,
                                cities: cities.value,
                                streets: streets.value,
                                houseNumbers: houseNumbers.value,
                                flatNumbers: flatNumbers.value,
                                postalCodes: postalCodes.value,
                                court: null,
                                formVal: formValues.value,
                                optionMatrix: optMatrixes
                            }),                      
                            status: 'no_access'
                        },
                        uuid: uuid.value,
                        list_prop: {
                            dataType: "Map",
                            value: [

                            ]
                        },
                        orgUUID: "0a754dfd-5c83-4356-baff-1b687aaf3fd1",
                        mod_parent: 0,
                        mod_order: 0,
                        mod_org: 0,
                        mod: 2
                    },
                    formUUID: "169fe196-b2de-4d7d-8ebe-d1217251717a",
                    objects: {
                        dataType: "Map",
                        value: []
                    },
                    structure: {
                        dataType: "Map",
                        value: []
                    },
                    formEditDate: 1810959199504
                }
            })
        });

        if (response.ok) {
            const data = await response.json();
            console.log("🚀 ~ save ~ data:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", data)
            if (data.success == 1) {
                router.push(pdf.link);
            }
            else {
                showToast('error', 'Błąd', data.result);
            }
        }

        
    } else if (active.value == maxVal) {
        generating.value = true;
        progressBarActive.value = true;
        let x = save();
        progressBarValue.value = 5;

        btnActiveText.value = 'Dopasowywanie sądu do adresu wnioskodawcy...';
        await new Promise(resolve => setTimeout(resolve, 4000));
        progressBarValue.value = 10;

        btnActiveText.value = 'Przewalutowywanie kwot podanych w innych walutach...';
        await new Promise(resolve => setTimeout(resolve, 2000));
        progressBarValue.value = 20;

        btnActiveText.value = 'Generowanie uzasadnienia do wniosku...';
        await new Promise(resolve => setTimeout(resolve, 5000));
        progressBarValue.value = 60;

        btnActiveText.value = 'Zmiana statusu wniosku...';
        await new Promise(resolve => setTimeout(resolve, 2000));
        progressBarValue.value = 80;

        btnActiveText.value = 'Zapisywanie...';
        await new Promise(resolve => setTimeout(resolve, 2000));
        progressBarValue.value = 99;

        btnActiveText.value = 'Przekierowywanie do dashboardu...';
        await new Promise(resolve => setTimeout(resolve, 2000));
        x = await x;
        progressBarActive.value = false;
        generating.value = false;
        if (x) router.push({ name: 'Dashboard Klient' });
        return;
    } else if (pdf.documentType != 'static') {
        generating.value = true;
        disableNextBtn.value = false;
        btnActiveText.value = 'Generowanie...';
        saveSubObject(active.value, false);
        await save();

        let response = await fetch('/api/export-pdf', {
            body: JSON.stringify({
                objectUUID: route.params.id,
                functionalityUUID: "c98b6831-bc1a-4d84-b78a-fbb1188ca440",
                type: pdf.documentType,
                option: subObjectValues.value[pdf.documentType == "inspector" ? 3 : 2].length - 1
            })
        })

        subObjectValues.value[pdf.documentType == "inspector" ? 3 : 2].pop();

        if (response.ok) {
            if (response.headers.get('Content-Type') === 'application/pdf') {
                const data = await response.blob();

                url = URL.createObjectURL(data);
                fileName = response.headers.get('Content-Disposition')?.split('filename=')?.[1]?.trim() || 'example.pdf';

            } else {
                showToast('error', 'Błąd', langObject.error_unknown);
                generating.value = false;
                return;
            }
        } else {
            showToast('error', 'Błąd', langObject.error_unknown);
            generating.value = false;
            return;
        }
    } else {
        url = pdf.document;
        fileName = pdf.document.split('/').pop();
    }


    if (pdf.documentType != 'link') {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        generating.value = false;
    }
}

const getDateMinus = (minus) => {
    minus = minus % 12;
    const date = new Date();
    date.setMonth(date.getMonth() - minus);

    if (date.getMonth() <= 0) {
        date.setFullYear(date.getFullYear() - 1);
        date.setMonth(date.getMonth() + 12);
    }

    let data = format(date, 'MMMM yyyy', { locale: pl })

    data = data.replace('stycznia', 'styczniu');
    data = data.replace('lutego', 'lutym');
    data = data.replace('marca', 'marcu');
    data = data.replace('kwietnia', 'kwietniu');
    data = data.replace('maja', 'maju');
    data = data.replace('czerwca', 'czerwcu');
    data = data.replace('lipca', 'lipcu');
    data = data.replace('sierpnia', 'sierpniu');
    data = data.replace('września', 'wrześniu');
    data = data.replace('pazdziernika', 'pazdzierniku');
    data = data.replace('listopada', 'listopadzie');
    data = data.replace('grudnia', 'grudniu');

    return data;
}

const generateInspectorQuery = () => {
    let name = activeObject.value.inspector_name.trim();
    let surname = activeObject.value.inspector_surname.trim();
    name = name.charAt(0).toUpperCase() + name.slice(1);
    surname = surname.charAt(0).toUpperCase() + surname.slice(1);
    let query = "komornik+" + name + "+" + surname + "+adres";
    return instructionData.value[active.value].text.replace(/{{ query }}/g, query);
}

const generateWindycationCompanyQuery = () => {
    let creditor_name = activeObject.value.creditor_name.trim().replace(/ +/g, ' ').replace(/ /g, '+');
    creditor_name = creditor_name.charAt(0).toUpperCase() + creditor_name.slice(1);
    let query = "firma windykacyjna+" + creditor_name + "+adres";
    return instructionData.value[active.value].text.replace(/{{ query }}/g, query);
}

const diffrentSpending = () => {
    let query = activeObject.value.spending_name;
    return instructionData.value[active.value].text.replace(/{{ query }}/g, query);
};

const applications = ref([
    {
        name: 'KRD',
        activation: KRDid,
        val: null,
        uuid: "228",
        component: "SelectButton",
        sideInstructionId: 1,
        options: ['Tak', 'Nie'],
        normal: false,
        errorLabels: ['Odpowiedź na to pytanie jest wymagana', 'Dokument KRD jest wymagany do dalszego wypełnienia wniosku'],
        conditions: [{
            type: "required"
        },
        {
            type: "value",
            option: 0
        }]
    },
    {
        name: 'BIK',
        activation: KRDid + 1,
        val: null,
        uuid: "229",
        component: "SelectButton",
        sideInstructionId: 2,
        options: ['Tak', 'Nie'],
        normal: false,
        errorLabels: ['Odpowiedź na to pytanie jest wymagana', 'Dokument BIK jest wymagany do dalszego wypełnienia wniosku'],
        conditions: [{
            type: "required"
        },
        {
            type: "value",
            option: 0
        }]
    },
    {
        name: 'ERIF',
        activation: KRDid + 2,
        val: null,
        uuid: "230",
        component: "SelectButton",
        sideInstructionId: 3,
        options: ['Tak', 'Nie'],
        normal: false,
        errorLabels: ['Odpowiedź na to pytanie jest wymagana', 'Dokument ERIF jest wymagany do dalszego wypełnienia wniosku'],
        conditions: [{
            type: "required"
        },
        {
            type: "value",
            option: 0
        }]
    }, {
        name: 'ZAS',
        activation: KRDid + 3,
        val: null,
        uuid: "231",
        component: "SelectButton",
        sideInstructionId: 4,
        options: ['Tak', 'Nie'],
        normal: false,
        errorLabels: ['Odpowiedź na to pytanie jest wymagana', 'Dokument z urzędu skarbowego są wymagane do dalszego wypełnienia wniosku'],
        conditions: [{
            type: "required"
        },
        {
            type: "value",
            option: 0
        }]
    }, {
        name: 'ZUS',
        activation: KRDid + 5,
        val: null,
        uuid: "232",
        component: "SelectButton",
        sideInstructionId: 5,
        options: ['Tak', 'Nie'],
        normal: false,
        errorLabels: ['Odpowiedź na to pytanie jest wymagana', 'Dokument ZUS jest wymagany do dalszego wypełnienia wniosku'],
        conditions: [{
            type: "required"
        },
        {
            type: "value",
            option: 0
        }]
    },
]);
const sideInstructionData = ref([
    {
        label: 'Lista wysłanych dokumentów',
        text: 'Zaznacz <b>Tak</b> jeżeli otrzymałeś/łaś odpowiedź na wysłany dokument.'
    },
    {
        label: 'Raport KRD',
        text: `Raport KRD jest wymagany do dalszego wypełnienia wniosku. Jeżeli jeszcze go nie otrzymałeś/łaś to: <br>
        - poczekaj na odpowiedź z KRD jeśli nie minął miesiąc od wysłania Twojego pisma.<br>
        - zadzwoń do KRD w sprawie wysłanego przez Ciebie pisma jeśli minął ponad miesiąc od jego wysłania.`
    },
    {
        label: 'Informacja BIK',
        text: `Informacja BIK jest wymagana do dalszego wypełnienia wniosku. Jeżeli jeszcze jej nie otrzymałeś/łaś to: <br>
        - poczekaj na odpowiedź z BIK jeśli nie minęły 2 miesiące od wysłania Twojego pisma.<br>
        - zadzwoń do BIK w sprawie wysłanego przez Ciebie pisma jeśli minęły ponad 2 miesiące od jego wysłania.`
    },
    {
        label: 'Informacja ERIF',
        text: `Informacja ERIF jest wymagana do dalszego wypełnienia wniosku. Jeżeli jeszcze jej nie otrzymałeś/łaś to: <br>
        - poczekaj na odpowiedź z ERIF jeśli nie minął miesiąc od wysłania Twojego pisma.<br>
        - zadzwoń do ERIF w sprawie wysłanego przez Ciebie pisma jeśli minął ponad miesiąc od jego wysłania.`
    },
    {
        label: 'Zaświadczenie z Urzędu Skarbowego',
        text: `Zaświadczenie z US jest wymagane do dalszego wypełnienia wniosku. Jeżeli jeszcze go nie otrzymałeś/łaś to: <br>
        - poczekaj na odpowiedź z US jeśli nie minął miesiąc od wysłania Twojego pisma.<br>
        - zadzwoń do US w sprawie wysłanego przez Ciebie pisma jeśli minął ponad miesiąc od jego wysłania.`
    },
    {
        label: 'Informacja ZUS',
        text: `Informacja ZUS jest wymagana do dalszego wypełnienia wniosku. Jeżeli jeszcze jej nie otrzymałeś/łaś to: <br>
        - poczekaj na odpowiedź z ZUS jeśli nie minął miesiąc od wysłania Twojego pisma.<br>
        - zadzwoń do ZUS w sprawie wysłanego przez Ciebie pisma jeśli minął ponad miesiąc od jego wysłania.`
    },
    {
        label: 'Dokumenty od firm windykacyjnych',
        text: `Dokumenty od firm windykacyjnych są wymagane do dalszego wypełnienia wniosku. Jeżeli jeszcze ich nie otrzymałeś/łaś to: <br>
        - poczekaj na odpowiedź z firmy windykacyjnej jeśli nie minął miesiąc od wysłania Twojego pisma.<br>
        - zadzwoń do firmy windykacyjnej w sprawie wysłanego przez Ciebie pisma jeśli minął ponad miesiąc od jego wysłania.`
    },
    {
        label: 'Informacje od komorników',
        text: `Informacje od komorników są wymagane do dalszego wypełnienia wniosku. Jeżeli jeszcze ich nie otrzymałeś/łaś to: <br>
        - poczekaj na odpowiedź od danego komornika jeśli nie minął miesiąc od wysłania Twojego pisma.<br>
        - zadzwoń do danego komornika w sprawie wysłanego przez Ciebie pisma jeśli minął ponad miesiąc od jego wysłania.`
    }
]);

const formName = ref('Wniosek o upadłość konsumencką')
const formData = ref([
    {
        id: 0,
        uuid: "1",
        title: 'Wniosek o upadłość konsumencką',
        component: "Instruction",
    },
    {
        id: 1,
        uuid: "2",
        label: 'Imię',
        title: 'Dane osobowe',
        name: 'name',
        component: "AutoComplete",
        suggestions: userdata.name ? [userdata.name] : [],
        errorLabels: ['Imię jest wymagane', 'Podane imię jest za długie'],
        conditions: [{
            type: "required"
        },
        {
            type: "length",
            minvalue: 1,
            maxvalue: 150
        }]
    }, {
        id: 2,
        uuid: "3",
        label: 'Nazwisko',
        title: 'Dane osobowe',
        name: 'surname',
        component: "AutoComplete",
        suggestions: userdata.surname ? [userdata.surname] : [],
        errorLabels: ['Nazwisko jest wymagane', 'Podane nazwisko jest za długie'],
        conditions: [{
            type: "required"
        },
        {
            type: "length",
            minvalue: 1,
            maxvalue: 150
        }]
    }, {
        id: 3,
        uuid: "4",
        label: 'PESEL:',
        title: 'Dane osobowe',
        name: 'pesel',
        component: "InputText",
        errorLabels: ['PESEL jest wymagany', 'Podana wartość nie jest właściwym numerem pesel'],
        conditions: [{
            type: "required"
        },
        {
            type: "pesel",
        }]
    }, {
        id: 4,
        uuid: "5",
        label: 'Adres email:',
        title: 'Dane kontaktowe',
        name: 'email',
        component: "AutoComplete",
        suggestions: userdata.email ? [userdata.email] : [],
        errorLabels: ['Adres email jest wymagany', 'Podany adres email jest nieprawidłowy', 'Podany adres email jest za długi'],
        conditions: [{
            type: "required"
        },
        {
            type: "regex",
            value: "^\\w+([\\-\\.]\\w+)*@([\\w\\-]+\\.)+[\\w\\-]{2,4}$"
        }, {
            type: "length",
            minvalue: 1,
            maxvalue: 150
        }]
    }, {
        id: 5,
        uuid: "6",
        label: 'Numer telefonu:',
        title: 'Dane kontaktowe',
        name: 'phone_number',
        component: "InputText",
        placeholder: 'Pole opcjonalne',
        errorLabels: ['Podany numer telefonu jest za długi', 'Podany numer telefonu jest nieprawidłowy'],
        conditions: [{
            type: "length",
            minvalue: 0,
            maxvalue: 20
        },
        {
            type: "regex",
            value: "^(\\(?\\ *(\\+\\ *[1-9]{1}[0-9]{0,3}\\ *)?\\)?\\ *([0-9]\\ *){9})?$"
        }]
    }, {
        id: 6,
        uuid: "7",
        label: 'Miejscowość zamieszkania:',
        title: 'Adres zamieszkania',
        saveType: 'address',
        saveName: 'city',
        name: 'city',
        component: "AutoComplete",
        suggestions: cities,
        errorLabels: ['Należy podać odpowiedź', 'Podana nazwa miasta jest za długa'],
        conditions: [{
            type: "required"
        }, {
            type: "length",
            minvalue: 1,
            maxvalue: 150
        }]
    }, {
        id: 7,
        uuid: "8",
        label: 'Ulica:',
        title: 'Adres zamieszkania',
        saveType: 'address',
        saveName: 'street',
        name: 'street',
        component: "AutoComplete",
        suggestions: streets,
        errorLabels: ['Należy podać odpowiedź', 'Podana nazwa ulicy jest za długa'],
        conditions: [{
            type: "required"
        }, {
            type: "length",
            minvalue: 1,
            maxvalue: 150
        }]
    }, {
        id: 8,
        uuid: "9",
        label: 'Numer domu:',
        title: 'Adres zamieszkania',
        saveType: 'address',
        saveName: 'house_number',
        name: 'house_number',
        component: "AutoComplete",
        suggestions: houseNumbers,
        errorLabels: ['Należy podać odpowiedź', 'Podany numer domu jest za długi'],
        conditions: [{
            type: "required",
        }, {
            type: "length",
            minvalue: 1,
            maxvalue: 10
        }]
    }, {
        id: 9,
        uuid: "10",
        label: 'Numer mieszkania:',
        title: 'Adres zamieszkania',
        saveType: 'address',
        saveName: 'flat_number',
        name: 'flat_number',
        component: "AutoComplete",
        suggestions: flatNumbers,
        errorLabels: ['Podany numer mieszkania jest za długi'],
        conditions: [{
            type: "length",
            minvalue: 0,
            maxvalue: 10
        }]
    }, {
        id: 10,
        uuid: "11",
        label: 'Kod pocztowy:',
        title: 'Adres zamieszkania',
        saveType: 'address',
        saveName: 'postal_code',
        name: 'postal_code',
        component: "AutoComplete",
        suggestions: postalCodes,
        errorLabels: ['Należy podać odpowiedź', 'Podany kod jest nieprawidłowy'],
        conditions: [{
            type: "required",
        }, {
            type: "regex",
            value: "^[0-9]{2}-[0-9]{3}$"
        }]
    }, {
        id: 11,
        uuid: "12",
        label: 'Czy twój adres do doręczenia jest taki sam jak adres zamieszkania?',
        title: 'Adres doręczenia',
        frontComponent: "Jump",
        component: "SelectButton",
        jumpActivation: 0,
        jumpid: 19,
        options: ['Tak', 'Nie'],
        errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        id: 12,
        uuid: "13",
        label: 'Kraj doręczenia:',
        title: 'Adres doręczenia',
        saveType: 'address',
        saveName: 'delivery_country',
        name: 'country',
        component: "AutoComplete",
        suggestions: countries,
        errorLabels: ['Należy podać odpowiedź', 'Podana nazwa kraju jest za długa'],
        conditions: [{
            type: "required"
        }, {
            type: "length",
            minvalue: 1,
            maxvalue: 150
        }]
    }, {
        id: 13,
        uuid: "14",
        label: 'Miejscowość:',
        title: 'Adres doręczenia',
        saveType: 'address',
        saveName: 'delivery_city',
        name: 'city',
        component: "AutoComplete",
        suggestions: cities,
        errorLabels: ['Należy podać odpowiedź', 'Podana nazwa miasta jest za długa'],
        conditions: [{
            type: "required"
        }, {
            type: "length",
            minvalue: 1,
            maxvalue: 150
        }]
    }, {
        id: 14,
        uuid: "15",
        label: 'Ulica:',
        title: 'Adres doręczenia',
        saveType: 'address',
        saveName: 'delivery_street',
        name: 'street',
        component: "AutoComplete",
        suggestions: streets,
        errorLabels: ['Należy podać odpowiedź', 'Podana nazwa ulicy jest za długa'],
        conditions: [{
            type: "required"
        }, {
            type: "length",
            minvalue: 1,
            maxvalue: 150
        }]
    }, {
        id: 15,
        uuid: "16",
        label: 'Numer domu:',
        title: 'Adres doręczenia',
        saveType: 'address',
        saveName: 'delivery_house_number',
        name: 'house_number',
        component: "AutoComplete",
        suggestions: houseNumbers,
        errorLabels: ['Należy podać odpowiedź', 'Podany numer domu jest za długi'],
        conditions: [{
            type: "required",
        }, {
            type: "length",
            minvalue: 1,
            maxvalue: 10
        }]
    }, {
        id: 16,
        uuid: "17",
        label: 'Numer mieszkania:',
        title: 'Adres doręczenia',
        saveType: 'address',
        saveName: 'delivery_flat_number',
        name: 'flat_number',
        component: "AutoComplete",
        suggestions: flatNumbers,
        errorLabels: ['Podany numer mieszkania jest za długi'],
        conditions: [{
            type: "length",
            minvalue: 0,
            maxvalue: 10
        }]
    }, {
        id: 17,
        uuid: "18",
        label: 'Numer skrytki pocztowej:',
        title: 'Adres doręczenia',
        saveType: 'address',
        saveName: 'delivery_postal_box_number',
        name: 'postal_box_number',
        placeholder: 'Pole opcjonalne',
        component: "InputText",
        errorLabels: ['Podany numer jest za długi'],
        conditions: [{
            type: "length",
            minvalue: 0,
            maxvalue: 10
        }]
    }, {
        id: 18,
        uuid: "19",
        label: 'Kod pocztowy:',
        title: 'Adres doręczenia',
        saveType: 'address',
        saveName: 'delivery_postal_code',
        name: 'postal_code',
        component: "AutoComplete",
        suggestions: postalCodes,
        errorLabels: ['Należy podać odpowiedź', 'Podany kod jest nieprawidłowy'],
        conditions: [{
            type: "required",
        }, {
            type: "regex",
            value: "^[A-Z0-9]{2,3}[-\ ]?[A-Z0-9]{3,4}$"
        }]
    }, {
        id: 19,
        uuid: "20",
        component: "autoJump",
        cond: () => {
            return formStatus === "trial";
        },
        frontComponent: "Jump",
        jumpid: 21
    }, {
        id: 20,
        uuid: "21",
        label: 'Czy majątek, który podawałeś/łaś przed opłaceniem wniosku jest aktualny i pełny?',
        title: 'Majątek dłużnika',
        frontComponent: "Jump",
        component: "SelectButton",
        jumpActivation: 0,
        actionOnNoJump: () => { subObjectValues.value[0]=[] },
        jumpid: 36,
        options: ['Tak', 'Nie jestem pewny/na'],
        errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        id: 21,
        labels: [
            "Czy posiadasz, bądź jesteś współwłaścicielem nieruchomości? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz, bądź jesteś współwłaścicielem kolejnej nieruchomości? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz, bądź jesteś współwłaścicielem samochodu? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz, bądź jesteś współwłaścicielem kolejnego samochodu? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz, bądź jesteś współwłaścicielem motocykla? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz, bądź jesteś współwłaścicielem kolejnego motocykla? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz, bądź jesteś współwłaścicielem motorowera? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz, bądź jesteś współwłaścicielem kolejnego motorowera? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz, bądź jesteś współwłaścicielem skutera? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz, bądź jesteś współwłaścicielem kolejnego skutera? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz prawa spadkowe? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz kolejne prawa spadkowe? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz, bądź jesteś współwłaścicielem dzieł sztuki? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz, bądź jesteś współwłaścicielem innych dzieł sztuki? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz udziały w spółkach? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz inne udziały w spółkach? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz akcje? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz inne akcje? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz jednostki funduszy inwestycyjnych? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz inne jednostki funduszy inwestycyjnych? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz inne składniki majątku o znacznej wartości? (najpierw przeczytaj instrukcję)",
            "Czy posiadasz inne składniki majątku o znacznej wartości? (najpierw przeczytaj instrukcję)"
        ],
        options: [
            "Nieruchomość",
            "Nieruchomość",
            "Samochód",
            "Samochód",
            "Motocykl",
            "Motocykl",
            "Motorower",
            "Motorower",
            "Skuter",
            "Skuter",
            "Prawa spadkowe",
            "Prawa spadkowe",
            "Dzieła sztuki",
            "Dzieła sztuki",
            "Udziały w spółkach",
            "Udziały w spółkach",
            "Akcje",
            "Akcje",
            "Jednostki funduszy inwestycyjnych",
            "Jednostki funduszy inwestycyjnych",
            "Inne",
            "Inne"
        ],
        uuid: "22",
        name: 'asset_type',
        title: 'Majątek dłużnika',
        optionid: 0,
        optionRenewal: true,
        optionChangeOddOnEntry: true,
        optionSkipEvenOnNO: true,
        endid: 33,
        subObjectIndex: 0,
        component: "SubObject",
        metadata: {
            prevInput: false
        },
        content: [
            {
                id: 22,
                uuid: "23",
                label: 'Opis składnika majątku',
                name: 'asset_description',
                component: "InputText",
                errorLabels: ['Należy podać odpowiedź', 'Podana treść jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 23,
                uuid: "24",
                label: 'Czy ustanowiono zabezpieczenie na majątku?',
                name: 'asset_secured',
                component: "SelectButton",
                jumpid: 25,
                jumpActivation: 1,
                options: ['Tak', 'Nie'],
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            },
            {
                id: 24,
                uuid: "25",
                label: 'Data ustanowienia zabezpieczenia',
                name: 'asset_securing_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            },
            {
                id: 25,
                uuid: "26",
                label: 'Wybierz walutę wyceny majątku',
                name: 'currency',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
                errorLabels: ['Waluta jest wymagana', 'Nieprawidłowy format waluty'],
                conditions: [{
                    type: "required"
                }, {
                    type: "regex",
                    value: "^[a-zA-Z]{3}$"
                }]
            },
            {
                id: 26,
                uuid: "27",
                label: 'Przybliżona wycena majątku',
                name: 'asset_price',
                component: "InputNumber",
                innerInputProps: {
                    mode: 'currency',
                    currency: currency,
                },
                errorLabels: ['Należy podać odpowiedź', 'Podana wartość musi być wieksza od 0'],
                conditions: [{
                    type: "required"
                }, {
                    type: "value",
                    minvalue: 0
                }]
            },
            {
                id: 27,
                uuid: "28",
                label: 'Kraj',
                saveType: 'address',
                saveName: 'asset_country',
                name: 'country',
                component: "AutoComplete",
                suggestions: countries,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 28,
                uuid: "29",
                label: 'Miejscowość',
                saveType: 'address',
                saveName: 'asset_city',
                name: 'city',
                component: "AutoComplete",
                suggestions: cities,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 29,
                uuid: "30",
                label: 'Ulica',
                saveType: 'address',
                saveName: 'asset_street',
                name: 'street',
                component: "AutoComplete",
                suggestions: streets,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 30,
                uuid: "31",
                label: 'Numer domu',
                saveType: 'address',
                saveName: 'asset_house_number',
                name: 'house_number',
                component: "AutoComplete",
                suggestions: houseNumbers,
                errorLabels: ['Należy podać odpowiedź', 'Podany numer jest za długi'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 10
                }]
            },
            {
                id: 31,
                uuid: "32",
                label: 'Numer mieszkania',
                saveType: 'address',
                saveName: 'asset_flat_number',
                name: 'flat_number',
                component: "AutoComplete",
                suggestions: flatNumbers,
                errorLabels: ['Podany numer jest za długi'],
                conditions: [{
                    type: "length",
                    minvalue: 0,
                    maxvalue: 10
                }]
            },
            {
                id: 32,
                uuid: "33",
                label: 'Kod pocztowy',
                saveType: 'address',
                saveName: 'asset_postal_code',
                name: 'postal_code',
                component: "AutoComplete",
                suggestions: postalCodes,
                errorLabels: ['Należy podać odpowiedź', "Podany kod jest nieprawidłowy"],
                conditions: [{
                    type: "required"
                },
                {
                    type: "regex",
                    value: "^[A-Z0-9]{2,3}[-\ ]?[A-Z0-9]{3,4}$"
                }]
            }
        ]
    }, {
        id: 34,
        uuid: "35",
        component: "autoJump",
        cond: () => {
            return formStatus !== "trial";
        },
        frontComponent: "Jump",
        jumpid: 36
    }, {
        id: 35,
        uuid: "36",
        title: 'Dostęp do wniosku',
        label: 'Instrukcja',
        component: "Instruction",
        frontComponent: "Jump",
        jumpid: 35,
        metadata: {
            nextInput: false,
            prevInput: false
        }
    }, {
        id: 36,
        labels: [
            "Czy jakiś podmiot ma wobec Ciebie dług?", "Czy jeszcze jakiś podmiot ma wobec Ciebie dług?"
        ],
        options: ["Tak", "Tak"],
        uuid: "37",
        name: 'ignore',
        title: 'Należności (wierzytelności) dłużnika względem innych podmiotów',
        optionid: 0,
        optionRenewal: false,
        optionChangeOnEntry: true,
        optionSkipEvenOnNO: true,
        endid: 50,
        subObjectIndex: 1,
        component: "SubObject",
        content: [
            {
                id: 37,
                uuid: "38",
                label: 'Imię i nazwisko/nazwa podmiotu:',
                name: 'entity_name',
                component: "InputText",
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa podmiotu jest za długa'],
                conditions: [{
                    type: "required"
                },
                {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 38,
                uuid: "39",
                label: 'Kraj podmiotu',
                saveType: 'address',
                saveName: 'entity_country',
                name: 'country',
                component: "AutoComplete",
                suggestions: countries,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 39,
                uuid: "40",
                label: 'Miejscowość podmiotu',
                saveType: 'address',
                saveName: 'entity_city',
                name: 'city',
                component: "AutoComplete",
                suggestions: cities,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 40,
                uuid: "41",
                label: 'Ulica podmiotu',
                saveType: 'address',
                saveName: 'entity_street',
                name: 'street',
                component: "AutoComplete",
                suggestions: streets,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 41,
                uuid: "42",
                label: 'Numer domu/budynku podmiotu',
                saveType: 'address',
                saveName: 'entity_house_number',
                name: 'house_number',
                component: "AutoComplete",
                suggestions: houseNumbers,
                errorLabels: ['Należy podać odpowiedź', 'Podany numer jest za długi'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 10
                }]
            },
            {
                id: 42,
                uuid: "43",
                label: 'Numer lokalu/mieszkania podmiotu',
                saveType: 'address',
                saveName: 'entity_flat_number',
                name: 'flat_number',
                component: "AutoComplete",
                suggestions: flatNumbers,
                errorLabels: ['Podany numer jest za długi'],
                conditions: [{
                    type: "length",
                    minvalue: 0,
                    maxvalue: 10
                }]
            },
            {
                id: 43,
                uuid: "44",
                label: 'Kod pocztowy',
                saveType: 'address',
                saveName: 'entity_postal_code',
                name: 'postal_code',
                component: "AutoComplete",
                suggestions: postalCodes,
                errorLabels: ['Należy podać odpowiedź', "Podany kod jest nieprawidłowy"],
                conditions: [{
                    type: "required"
                },
                {
                    type: "regex",
                    value: "^[A-Z0-9]{2,3}[-\ ]?[A-Z0-9]{3,4}$"
                }]
            }, {
                id: 44,
                uuid: "45",
                label: 'Waluta wierzytelności',
                name: 'currency',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
                errorLabels: ['Waluta jest wymagana', 'Nieprawidłowy format waluty'],
                conditions: [{
                    type: "required"
                }, {
                    type: "regex",
                    value: "^[a-zA-Z]{3}$"
                }]
            }, {
                id: 45,
                uuid: "46",
                label: 'Wysokość wierzytelności',
                name: 'amount',
                component: "InputNumber",
                innerInputProps: {
                    mode: 'currency',
                    currency: currency,
                },
                errorLabels: ['Należy podać wartość', 'Wartość musi być większa od 0'],
                conditions: [{
                    type: "required"
                },
                {
                    type: "value",
                    minvalue: 0,
                }]
            }, {
                id: 46,
                uuid: "47",
                label: 'Termin zapłaty',
                name: 'payment_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
                errorLabels: ['Należy podać datę'],
                conditions: [{
                    type: "required"
                }]
            }, {
                id: 47,
                uuid: "48",
                label: 'Nazwa dokumentu potwierdzającego informację',
                name: 'document_name',
                fact: 'Na okoliczność posiadanej wierzytelności.',
                component: "InputText",
                errorLabels: ['Nazwa dokumentu jest wymagana', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 200
                }]
            }, {
                id: 48,
                uuid: "49",
                label: 'Data dokumentu',
                name: 'document_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            }, {
                id: 49,
                uuid: "50",
                label: 'Instrukcja',
                component: "Instruction",
                //wykonaj 2 kopie dokumentu
            },
        ]
    }, {
        id: 51,
        uuid: "52",
        title: 'Spis wierzycieli (zbieranie informacji)',
        label: 'Instrukcja',
        component: "Instruction",
        // pobierz wniosek krd
    }, {
        id: 52,
        uuid: "53",
        title: 'Spis wierzycieli (zbieranie informacji)',
        label: 'Instrukcja',
        component: "Instruction",
        // pobierz wniosek bik
    }, {
        id: 53,
        uuid: "54",
        title: 'Spis wierzycieli (zbieranie informacji)',
        label: 'Instrukcja',
        component: "Instruction",
        // pobierz wniosek erif
    }, {
        id: 54,
        uuid: "55",
        title: 'Spis wierzycieli (zbieranie informacji)',
        label: 'Czy podejrzewasz, że masz długi w Urzędzie Skarbowym?',
        frontComponent: "Jump",
        component: "SelectButton",
        jumpActivation: 1,
        jumpid: 56,
        options: ['Tak', 'Nie'],
        errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        id: 55,
        uuid: "56",
        title: 'Spis wierzycieli (zbieranie informacji)',
        label: 'Instrukcja',
        component: "Instruction",
        // pobierz wniosek urząd
    }, {
        id: 56,
        uuid: "57",
        title: 'Spis wierzycieli (zbieranie informacji)',
        label: 'Czy masz długi w ZUS?',
        frontComponent: "Jump",
        component: "SelectButton",
        jumpActivation: 1,
        jumpid: 58,
        options: ['Tak', 'Nie'],
        errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        id: 57,
        uuid: "58",
        title: 'Spis wierzycieli (zbieranie informacji)',
        label: 'Instrukcja',
        component: "Instruction",
        // pobierz wniosek zus
    }, {
        id: 58,
        title: 'Spis wierzycieli (zbieranie informacji)',
        labels: [
            "Czy kontaktują się z Tobą telefonicznie/pisemnie firmy windykacyjne?", "Czy kontaktują się z Tobą telefonicznie/pisemnie kolejne firmy windykacyjne?"
        ],
        options: ["Tak", "Tak"],
        uuid: "59",
        name: 'ignore',
        optionid: 0,
        optionRenewal: false,
        optionChangeOnEntry: true,
        optionSkipEvenOnNO: true,
        endid: 68,
        subObjectIndex: 2,
        component: "SubObject",
        content: [
            {
                id: 59,
                uuid: "60",
                label: 'Wybierz firmę windykacyjną, która się z Tobą kontaktowała',
                name: 'windycation_company_name_select',
                frontComponent: "Jump",
                component: "Select",
                options: ["Best", "Kruk/Prokura", "Ultimo", "Koksztys", "Eos", "Hoist", "Pra Group", "Raport/Axfina", "Kredyt Inkaso", "Intrum Justitia", "Asekuracja", "Aasa Polska", "Presco Investments", 'Debito NSFIZ', "Inna Firma"],
                jumpActivation: 14,
                negateJumpActivation: true,
                jumpid: 66,
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            },
            {
                id: 60,
                uuid: "61",
                label: 'Nazwa firmy windykacyjnej',
                name: 'windycation_company_name',
                component: "InputText",
                errorLabels: ['Nazwa firmy windykacyjnej jest wymagana'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 200
                }]
            },
            {
                id: 61,
                uuid: "62",
                label: 'Miejscowość siedziby firmy windykacyjnej',
                saveType: 'address',
                saveName: 'windycation_company_city',
                name: 'city',
                component: "AutoComplete",
                suggestions: cities,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 62,
                uuid: "63",
                label: 'Ulica siedziby firmy windykacyjnej',
                saveType: 'address',
                saveName: 'windycation_company_street',
                name: 'street',
                component: "AutoComplete",
                suggestions: streets,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 63,
                uuid: "64",
                label: 'Numer budynku siedziby firmy windykacyjnej',
                saveType: 'address',
                saveName: 'windycation_company_house_number',
                name: 'house_number',
                component: "AutoComplete",
                suggestions: houseNumbers,
                errorLabels: ['Należy podać odpowiedź', 'Podany numer jest za długi'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 10
                }]
            },
            {
                id: 64,
                uuid: "65",
                label: 'Numer lokalu siedziby firmy windykacyjnej',
                saveType: 'address',
                saveName: 'windycation_company_flat_number',
                name: 'flat_number',
                component: "AutoComplete",
                suggestions: flatNumbers,
                errorLabels: ['Podany numer jest za długi'],
                conditions: [{
                    type: "length",
                    minvalue: 0,
                    maxvalue: 10
                }]
            },
            {
                id: 65,
                uuid: "66",
                label: 'Kod pocztowy',
                saveType: 'address',
                saveName: 'windycation_company_postal_code',
                name: 'postal_code',
                component: "AutoComplete",
                suggestions: postalCodes,
                errorLabels: ['Należy podać odpowiedź', "Podany kod jest nieprawidłowy"],
                conditions: [{
                    type: "required"
                },
                {
                    type: "regex",
                    value: "^[A-Z0-9]{2,3}[-\ ]?[A-Z0-9]{3,4}$"
                }]
            }, {
                id: 66,
                uuid: "67",
                label: 'Instrukcja',
                component: "Instruction",
                backendComponent: "GenerateWindycationCompanyPDF",
                generating: true
                // pobierz wniosek do firmy windykacyjnej
            }, {
                id: 67,
                uuid: "68",
                label: 'Czy dane w wydrukowanym piśmie są poprawne?',
                name: 'ignore',
                frontComponent: "Jump",
                component: "SelectButton",
                jumpActivation: 1,
                jumpid: 59,
                actionOnJump: () => { subObjectValues.value[2].pop() },
                options: ['Tak', 'Nie'],
                errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
                conditions: [{
                    type: "required"
                }]
            }
        ]
    }, {
        id: 69,
        uuid: "70",
        title: 'Spis wierzycieli (zbieranie informacji)',
        label: 'Czy są prowadzone/lub były prowadzone wobec Ciebie egzekucje komornicze?',
        frontComponent: "Jump",
        component: "SelectButton",
        jumpActivation: 1,
        jumpid: 82,
        options: ['Tak', 'Nie'],
        errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        id: 70,
        uuid: "71",
        label: 'Instrukcja',
        component: "Instruction",
        //Jeśli nie znasz imię,naz komornika wyszukaj je w internecie
    }, {
        id: 71,
        title: 'Spis wierzycieli (zbieranie informacji)',
        labels: [
            "Przygotuj dokument od jakiegokolwiek komornika. Czy możemy przejść dalej?", "Czy posiadasz jakikolwiek dokument od kolejnego komornika?"
        ],
        options: ["Tak", "Tak"],
        uuid: "72",
        name: 'ignore',
        optionid: 1,
        customBehaviour: [
            {
                optionid: 0,
                jumpOnYes: 72,
                jumpOnNo: 69,
                optionChangeOnYes: true,
                optionChangeOnNo: false,
            }
        ],
        optionRenewal: false,
        optionChangeOnEntry: true,
        optionSkipEvenOnNO: true,
        endid: 81,
        subObjectIndex: 3,
        component: "SubObject",
        content: [
            {
                id: 72,
                uuid: "73",
                label: 'Imię komornika',
                name: 'inspector_name',
                component: "InputText",
                errorLabels: ['Należy podać odpowiedź', 'Podane imię jest za długie'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 73,
                uuid: "74",
                label: 'Nazwisko komornika',
                name: 'inspector_surname',
                component: "InputText",
                errorLabels: ['Należy podać odpowiedź', 'Podane nazwisko jest za długie'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 74,
                uuid: "75",
                label: 'Miejscowość siedziby komornika',
                saveType: 'address',
                saveName: 'inspector_city',
                name: 'city',
                component: "AutoComplete",
                suggestions: cities,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 75,
                uuid: "76",
                label: 'Ulica siedziby komornika',
                saveType: 'address',
                saveName: 'inspector_street',
                name: 'street',
                component: "AutoComplete",
                suggestions: streets,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 76,
                uuid: "77",
                label: 'Numer budynku siedziby komornika',
                saveType: 'address',
                saveName: 'inspector_house_number',
                name: 'house_number',
                component: "AutoComplete",
                suggestions: houseNumbers,
                errorLabels: ['Należy podać odpowiedź', 'Podany numer jest za długi'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 10
                }]
            },
            {
                id: 77,
                uuid: "78",
                label: 'Numer lokalu siedziby komornika',
                saveType: 'address',
                saveName: 'inspector_flat_number',
                name: 'flat_number',
                component: "AutoComplete",
                suggestions: flatNumbers,
                errorLabels: ['Podany numer jest za długi'],
                conditions: [{
                    type: "length",
                    minvalue: 0,
                    maxvalue: 10
                }]
            },
            {
                id: 78,
                uuid: "79",
                label: 'Kod pocztowy',
                saveType: 'address',
                saveName: 'inspector_postal_code',
                name: 'postal_code',
                component: "AutoComplete",
                suggestions: postalCodes,
                errorLabels: ['Należy podać odpowiedź', "Podany kod jest nieprawidłowy"],
                conditions: [{
                    type: "required"
                },
                {
                    type: "regex",
                    value: "^[0-9]{2}-[0-9]{3}$"
                }]
            }, {
                id: 79,
                uuid: "80",
                label: 'Instrukcja',
                component: "Instruction",
                backendComponent: "GenerateInspectorPDF",
                generating: true
                // pobierz wniosek do komornika
            }, {
                id: 80,
                uuid: "81",
                label: 'Czy dane w wydrukowanym piśmie są poprawne?',
                name: 'ignore',
                frontComponent: "Jump",
                component: "SelectButton",
                jumpActivation: 1,
                jumpid: 72,
                actionOnJump: () => { subObjectValues.value[3].pop() },
                options: ['Tak', 'Nie'],
                errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
                conditions: [{
                    type: "required"
                }]
            }
        ]
    }, {
        id: 82,
        uuid: "83",
        title: 'Spis wierzycieli (zbieranie informacji)',
        label: 'Lista wniosków',
        checklist: 1,
        component: "Checklist",
        errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        id: 83,
        uuid: "84",
        title: 'Spis wierzycieli',
        label: 'Instrukcja',
        component: "Instruction",
        //przygotuj raport KRD
    }, {
        id: 84,
        title: 'Spis wierzycieli',
        labels: [
            "Czy raport KRD zawiera wierzycieli?", "Czy raport KRD zawiera kolejnych wierzycieli?"
        ],
        options: ["Tak", "Tak"],
        uuid: "85",
        name: 'ignore',
        customBehaviour: [
            {
                optionid: 0,
                jumpOnYes: 85,
                jumpOnNo: 98,
                optionChangeOnYes: true,
                optionChangeOnNo: false,
            }
        ],
        optionid: 0,
        optionRenewal: false,
        optionChangeOnEntry: true,
        optionSkipEvenOnNO: true,
        endid: 95,
        subObjectIndex: 4,
        component: "SubObject",
        content: [
            {
                id: 85,
                uuid: "86",
                label: 'Imię i Nazwisko lub Nazwa wierzyciela',
                name: 'creditor_name',
                component: "InputText",
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 86,
                uuid: "87",
                label: 'Kraj wierzyciela',
                saveType: 'address',
                saveName: 'creditor_country',
                name: 'country',
                component: "AutoComplete",
                suggestions: countries,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 87,
                uuid: "88",
                label: 'Miejscowość wierzyciela',
                saveType: 'address',
                saveName: 'creditor_city',
                name: 'city',
                component: "AutoComplete",
                suggestions: cities,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 88,
                uuid: "89",
                label: 'Ulica wierzyciela',
                saveType: 'address',
                saveName: 'creditor_street',
                name: 'street',
                component: "AutoComplete",
                suggestions: streets,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 89,
                uuid: "90",
                label: 'Numer budynku wierzyciela',
                saveType: 'address',
                saveName: 'creditor_house_number',
                name: 'house_number',
                component: "AutoComplete",
                suggestions: houseNumbers,
                errorLabels: ['Należy podać odpowiedź', 'Podany numer jest za długi'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 10
                }]
            },
            {
                id: 90,
                uuid: "91",
                label: 'Numer lokalu wierzyciela',
                saveType: 'address',
                saveName: 'creditor_flat_number',
                name: 'flat_number',
                component: "AutoComplete",
                suggestions: flatNumbers,
                errorLabels: ['Podany numer jest za długi'],
                conditions: [{
                    type: "length",
                    minvalue: 0,
                    maxvalue: 10
                }]
            },
            {
                id: 91,
                uuid: "92",
                label: 'Kod pocztowy',
                saveType: 'address',
                saveName: 'creditor_postal_code',
                name: 'postal_code',
                component: "AutoComplete",
                suggestions: postalCodes,
                errorLabels: ['Należy podać odpowiedź', "Podany kod jest nieprawidłowy"],
                conditions: [{
                    type: "required"
                },
                {
                    type: "regex",
                    value: "^[A-Z0-9]{2,3}[-\ ]?[A-Z0-9]{3,4}$"
                }]
            },
            {
                id: 92,
                uuid: "93",
                label: 'Waluta wierzytelności',
                name: 'currency',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
                errorLabels: ['Waluta jest wymagana', 'Nieprawidłowy format waluty'],
                conditions: [{
                    type: "required"
                }, {
                    type: "regex",
                    value: "^[a-zA-Z]{3}$"
                }]
            },
            {
                id: 93,
                uuid: "94",
                label: 'Kwota wierzytelności',
                name: 'amount',
                component: "InputNumber",
                innerInputProps: {
                    mode: 'currency',
                    currency: currency,
                },
                errorLabels: ['Należy podać odpowiedź', 'Podana wartość musi być wieksza od 0'],
                conditions: [{
                    type: "required"
                }, {
                    type: "value",
                    minvalue: 0
                }]
            },
            {
                id: 94,
                uuid: "95",
                label: 'Termin zapłaty',
                name: 'payment_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            }
        ]
    }, {
        id: 96,
        uuid: "97",
        title: 'Spis wierzycieli',
        label: 'Data raportu KRD',
        fact: 'Raport KRD',
        component: "DatePicker",
        innerInputProps: {
            showIcon: true,
            iconDisplay: "input",
            dateFormat: "dd/mm/yy",
            manualInput: false
        },
        errorLabels: ['Data raportu KRD jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        id: 97,
        uuid: "98",
        title: 'Spis wierzycieli',
        label: 'Instrukcja',
        component: "Instruction",
        // wykonaj kopie raportu KRD
    }, {
        id: 98,
        uuid: "99",
        component: "autoJump",
        cond: () => {
            return inspectors.value.length == 0;
        },
        frontComponent: "Jump",
        jumpid: 115
    }, {
        id: 99,
        uuid: "100",
        title: 'Spis wierzycieli',
        label: 'Instrukcja',
        component: "Instruction",
        // przygotuj dokumenty od komorników
        frontComponent: "Jump",
        jumpid: 114
    }, {
        id: 100,
        title: 'Spis wierzycieli',
        labels: [
            "Czy dokument od tego komornika zawiera wierzyciela/wierzycieli?", "Czy dokument od tego komornika zawiera kolejnych wierzycieli?"
        ],
        options: ["Tak", "Tak"],
        uuid: "101",
        name: 'ignore',
        optionid: 0,
        customBehaviour: [
            {
                optionid: 0,
                jumpOnYes: 101,
                jumpOnNo: 114,
                optionChangeOnYes: true,
                optionChangeOnNo: false,
            }
        ],
        optionRenewal: true,
        optionChangeOnEntry: true,
        optionSkipEvenOnNO: true,
        endid: 111,
        subObjectIndex: 4,
        component: "SubObject",
        content: [
            {
                id: 101,
                uuid: "102",
                label: 'Imię i Nazwisko lub Nazwa wierzyciela',
                name: 'creditor_name',
                component: "InputText",
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 102,
                uuid: "103",
                label: 'Kraj wierzyciela',
                saveType: 'address',
                saveName: 'creditor_country',
                name: 'country',
                component: "AutoComplete",
                suggestions: countries,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 103,
                uuid: "104",
                label: 'Miejscowość wierzyciela',
                saveType: 'address',
                saveName: 'creditor_city',
                name: 'city',
                component: "AutoComplete",
                suggestions: cities,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 104,
                uuid: "105",
                label: 'Ulica wierzyciela',
                saveType: 'address',
                saveName: 'creditor_street',
                name: 'street',
                component: "AutoComplete",
                suggestions: streets,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 105,
                uuid: "106",
                label: 'Numer budynku wierzyciela',
                saveType: 'address',
                saveName: 'creditor_house_number',
                name: 'house_number',
                component: "AutoComplete",
                suggestions: houseNumbers,
                errorLabels: ['Należy podać odpowiedź', 'Podany numer jest za długi'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 10
                }]
            },
            {
                id: 106,
                uuid: "107",
                label: 'Numer lokalu wierzyciela',
                saveType: 'address',
                saveName: 'creditor_flat_number',
                name: 'flat_number',
                component: "AutoComplete",
                suggestions: flatNumbers,
                errorLabels: ['Podany numer jest za długi'],
                conditions: [{
                    type: "length",
                    minvalue: 0,
                    maxvalue: 10
                }]
            },
            {
                id: 107,
                uuid: "108",
                label: 'Kod pocztowy',
                saveType: 'address',
                saveName: 'creditor_postal_code',
                name: 'postal_code',
                component: "AutoComplete",
                suggestions: postalCodes,
                errorLabels: ['Należy podać odpowiedź', "Podany kod jest nieprawidłowy"],
                conditions: [{
                    type: "required"
                },
                {
                    type: "regex",
                    value: "^[A-Z0-9]{2,3}[-\ ]?[A-Z0-9]{3,4}$"
                }]
            },
            {
                id: 108,
                uuid: "109",
                label: 'Waluta wierzytelności',
                name: 'currency',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
                errorLabels: ['Waluta jest wymagana', 'Nieprawidłowy format waluty'],
                conditions: [{
                    type: "required"
                }, {
                    type: "regex",
                    value: "^[a-zA-Z]{3}$"
                }]
            },
            {
                id: 109,
                uuid: "110",
                label: 'Kwota wierzytelności',
                name: 'amount',
                component: "InputNumber",
                innerInputProps: {
                    mode: 'currency',
                    currency: currency,
                },
                errorLabels: ['Należy podać odpowiedź', 'Podana wartość musi być wieksza od 0'],
                conditions: [{
                    type: "required"
                }, {
                    type: "value",
                    minvalue: 0
                }]
            },
            {
                id: 110,
                uuid: "111",
                label: 'Termin zapłaty',
                name: 'payment_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            }
        ]
    }, {
        id: 112,
        uuid: "113",
        title: 'Spis wierzycieli',
        label: 'Data dokumentu od komornika',
        component: "DatePicker",
        errorLabels: ['Data dokumentu od komornika jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        id: 113,
        uuid: "114",
        title: 'Spis wierzycieli',
        label: 'Instrukcja',
        component: 'Instruction',
        // wykonaj 2 kopie dokumentu od komornika
    }, {
        id: 114,
        uuid: "115",
        title: 'Spis wierzycieli',
        labelStart: 'Przygotuj dokument od komornika ',
        label: (optionid) => inspectors.value[optionid]?.inspector_name + ' ' + inspectors.value[optionid]?.inspector_surname,
        labelEnd: '',
        jumpid: 100,
        optionid: 0,
        subObjectIndex: 11,
        loopArray: inspectors,
        documentLabel: 'Dokument od komornika ',
        component: "MultiLoop",
    }, {
        id: 115,
        uuid: "116",
        component: "autoJump",
        cond: () => {
            return windycation_companies.value.length == 0;
        },
        frontComponent: "Jump",
        jumpid: 132
    }, {
        id: 116,
        uuid: "117",
        title: 'Spis wierzycieli',
        label: 'Instrukcja',
        component: 'Instruction',
        //przygotuj dokumenty od firm windykacyjnych 
        frontComponent: "Jump",
        jumpid: 131
    }, {
        id: 117,
        title: 'Spis wierzycieli',
        labels: [
            "Czy dokument od tej firmy windykacyjnej zawiera wierzycieli/wierzytelności?",
            "Czy firma windykacyjna występuje jako wierzyciel?",
            "Czy dokument od tej firmy windykacyjnej zawiera kolejnych wierzycieli?"
        ],
        options: ["Tak", "windycation_company", "windycation_company"],
        uuid: "118",
        name: 'creditor_type',
        optionid: 0,
        customBehaviour: [
            {
                optionid: 0,
                jumpOnYes: 117,
                jumpOnNo: 131,
                optionChangeOnYes: true,
                optionChangeOnNo: false,
            },
            {
                optionid: 1,
                jumpOnYes: 125,
                jumpOnNo: 118,
                optionChangeOnYes: true,
                optionChangeOnNo: true,
                exitOnYes: true
            },
            {
                optionid: 2,
                jumpOnYes: 118,
                jumpOnNo: 129,
                optionChangeOnYes: false,
                optionChangeOnNo: true,
            }
        ],
        optionRenewal: true,
        optionChangeOnEntry: true,
        optionSkipEvenOnNO: false,
        endid: 128,
        subObjectIndex: 4,
        component: "SubObject",
        content: [
            {
                id: 118,
                uuid: "119",
                label: 'Imię i Nazwisko lub Nazwa wierzyciela',
                name: 'creditor_name',
                component: "InputText",
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 119,
                uuid: "120",
                label: 'Kraj wierzyciela',
                saveType: 'address',
                saveName: 'creditor_country',
                name: 'country',
                component: "AutoComplete",
                suggestions: countries,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 120,
                uuid: "121",
                label: 'Miejscowość wierzyciela',
                saveType: 'address',
                saveName: 'creditor_city',
                name: 'city',
                component: "AutoComplete",
                suggestions: cities,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 121,
                uuid: "122",
                label: 'Ulica wierzyciela',
                saveType: 'address',
                saveName: 'creditor_street',
                name: 'street',
                component: "AutoComplete",
                suggestions: streets,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 122,
                uuid: "123",
                label: 'Numer budynku wierzyciela',
                saveType: 'address',
                saveName: 'creditor_house_number',
                name: 'house_number',
                component: "AutoComplete",
                suggestions: houseNumbers,
                errorLabels: ['Należy podać odpowiedź', 'Podany numer jest za długi'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 10
                }]
            },
            {
                id: 123,
                uuid: "124",
                label: 'Numer lokalu wierzyciela',
                saveType: 'address',
                saveName: 'creditor_flat_number',
                name: 'flat_number',
                component: "AutoComplete",
                suggestions: flatNumbers,
                errorLabels: ['Podany numer jest za długi'],
                conditions: [{
                    type: "length",
                    minvalue: 0,
                    maxvalue: 10
                }]
            },
            {
                id: 124,
                uuid: "125",
                label: 'Kod pocztowy',
                saveType: 'address',
                saveName: 'creditor_postal_code',
                name: 'postal_code',
                component: "AutoComplete",
                suggestions: postalCodes,
                errorLabels: ['Należy podać odpowiedź', "Podany kod jest nieprawidłowy"],
                conditions: [{
                    type: "required"
                },
                {
                    type: "regex",
                    value: "^[A-Z0-9]{2,3}[-\ ]?[A-Z0-9]{3,4}$"
                }]
            },
            {
                id: 125,
                uuid: "126",
                label: 'Waluta wierzytelności',
                name: 'currency',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
                errorLabels: ['Waluta jest wymagana', 'Nieprawidłowy format waluty'],
                conditions: [{
                    type: "required"
                }, {
                    type: "regex",
                    value: "^[a-zA-Z]{3}$"
                }]
            },
            {
                id: 126,
                uuid: "127",
                label: 'Kwota wierzytelności',
                name: 'amount',
                component: "InputNumber",
                innerInputProps: {
                    mode: 'currency',
                    currency: currency,
                },
                errorLabels: ['Należy podać odpowiedź', 'Podana wartość musi być wieksza od 0'],
                conditions: [{
                    type: "required"
                }, {
                    type: "value",
                    minvalue: 0
                }]
            },
            {
                id: 127,
                uuid: "128",
                label: 'Termin zapłaty',
                name: 'payment_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            }
        ]
    }, {
        id: 129,
        uuid: "130",
        title: 'Spis wierzycieli',
        label: 'Data dokumentu od firmy windykacyjnej',
        component: "DatePicker",
        errorLabels: ['Data dokumentu od firmy windykacyjnej jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        id: 130,
        uuid: "131",
        title: 'Spis wierzycieli',
        label: 'Instrukcja',
        component: "Instruction",
        //wykonaj 2 kopie każdego dokumentu od firm windykacyjnych
    }, {
        id: 131,
        uuid: "132",
        title: 'Spis wierzycieli',
        labelStart: 'Przygotuj dokument od firmy ',
        label: (optionid) => {
            if (windycation_companies.value[optionid]?.windycation_company_name_select == 'Inna Firma')
                return windycation_companies.value[optionid]?.windycation_company_name
            return windycation_companies.value[optionid]?.windycation_company_name_select
        },
        labelEnd: '',
        jumpid: 117,
        optionid: 0,
        subObjectIndex: 11,
        loopArray: windycation_companies,
        documentLabel: 'Dokument od firmy windykacyjnej ',
        component: "MultiLoop",
    }, {
        id: 132,
        uuid: "133",
        title: 'Spis wierzycieli',
        label: 'Instrukcja',
        component: "Instruction",
        //przygotuj raport ERIF
    }, {
        id: 133,
        title: 'Spis wierzycieli',
        labels: [
            "Czy raport ERIF zawiera wierzycieli?", "Czy raport ERIF zawiera kolejnych wierzycieli?"
        ],
        options: ["Tak", "Tak"],
        uuid: "134",
        name: 'ignore',
        optionid: 0,
        customBehaviour: [
            {
                optionid: 0,
                jumpOnYes: 134,
                jumpOnNo: 147,
                optionChangeOnYes: true,
                optionChangeOnNo: false,
            }
        ],
        optionRenewal: false,
        optionChangeOnEntry: true,
        optionSkipEvenOnNO: true,
        endid: 144,
        subObjectIndex: 4,
        component: "SubObject",
        content: [
            {
                id: 134,
                uuid: "135",
                label: 'Imię i Nazwisko lub Nazwa wierzyciela',
                name: 'creditor_name',
                component: "InputText",
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 135,
                uuid: "136",
                label: 'Kraj wierzyciela',
                saveType: 'address',
                saveName: 'creditor_country',
                name: 'country',
                component: "AutoComplete",
                suggestions: countries,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 136,
                uuid: "137",
                label: 'Miejscowość wierzyciela',
                saveType: 'address',
                saveName: 'creditor_city',
                name: 'city',
                component: "AutoComplete",
                suggestions: cities,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 137,
                uuid: "138",
                label: 'Ulica wierzyciela',
                saveType: 'address',
                saveName: 'creditor_street',
                name: 'street',
                component: "AutoComplete",
                suggestions: streets,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 138,
                uuid: "139",
                label: 'Numer budynku wierzyciela',
                saveType: 'address',
                saveName: 'creditor_house_number',
                name: 'house_number',
                component: "AutoComplete",
                suggestions: houseNumbers,
                errorLabels: ['Należy podać odpowiedź', 'Podany numer jest za długi'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 10
                }]
            },
            {
                id: 139,
                uuid: "140",
                label: 'Numer lokalu wierzyciela',
                saveType: 'address',
                saveName: 'creditor_flat_number',
                name: 'flat_number',
                component: "AutoComplete",
                suggestions: flatNumbers,
                errorLabels: ['Podany numer jest za długi'],
                conditions: [{
                    type: "length",
                    minvalue: 0,
                    maxvalue: 10
                }]
            },
            {
                id: 140,
                uuid: "141",
                label: 'Kod pocztowy',
                saveType: 'address',
                saveName: 'creditor_postal_code',
                name: 'postal_code',
                component: "AutoComplete",
                suggestions: postalCodes,
                errorLabels: ['Należy podać odpowiedź', "Podany kod jest nieprawidłowy"],
                conditions: [{
                    type: "required"
                },
                {
                    type: "regex",
                    value: "^[A-Z0-9]{2,3}[-\ ]?[A-Z0-9]{3,4}$"
                }]
            },
            {
                id: 141,
                uuid: "142",
                label: 'Waluta wierzytelności',
                name: 'currency',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
                errorLabels: ['Waluta jest wymagana', 'Nieprawidłowy format waluty'],
                conditions: [{
                    type: "required"
                }, {
                    type: "regex",
                    value: "^[a-zA-Z]{3}$"
                }]
            },
            {
                id: 142,
                uuid: "143",
                label: 'Kwota wierzytelności',
                name: 'amount',
                component: "InputNumber",
                innerInputProps: {
                    mode: 'currency',
                    currency: currency,
                },
                errorLabels: ['Należy podać odpowiedź', 'Podana wartość musi być wieksza od 0'],
                conditions: [{
                    type: "required"
                }, {
                    type: "value",
                    minvalue: 0
                }]
            },
            {
                id: 143,
                uuid: "144",
                label: 'Termin zapłaty',
                name: 'payment_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            }
        ]
    }, {
        id: 145,
        uuid: "146",
        title: 'Spis wierzycieli',
        label: 'Data raportu ERIF',
        component: "DatePicker",
        errorLabels: ['Data raportu ERIF jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        id: 146,
        uuid: "147",
        title: 'Spis wierzycieli',
        label: 'Instrukcja',
        component: "Instruction",
        //wykonaj kopie raportu ERIF
    }, {
        id: 147,
        uuid: "148",
        component: "autoJump",
        cond: () => {
            return formValues.value[KRDid + 3] != 'Tak';
        },
        frontComponent: "Jump",
        jumpid: 161
    }, {
        id: 148,
        uuid: "149",
        title: 'Spis wierzycieli',
        label: 'Instrukcja',
        component: "Instruction",
        //przygotuj dokumenty z urzędu skarbowego
    }, {
        id: 149,
        title: 'Spis wierzycieli',
        labels: [
            "Czy urząd skarbowy wskazał zaległości?"
        ],
        options: ["US-ZASW"],
        uuid: "150",
        name: 'creditor_type',
        optionid: 0,
        customBehaviour: [
            {
                optionid: 0,
                jumpOnYes: 150,
                jumpOnNo: 161,
                optionChangeOnYes: true,
                optionChangeOnNo: false,
                exitOnYes: true
            }
        ],
        optionRenewal: false,
        optionChangeOnEntry: true,
        optionSkipEvenOnNO: true,
        endid: 158,
        subObjectIndex: 4,
        component: "SubObject",
        content: [
            {
                id: 150,
                uuid: "151",
                label: 'Nazwa Urzędu Skarbowego',
                name: 'creditor_name',
                component: "InputText",
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 151,
                uuid: "152",
                label: 'Miejscowość Urzędu Skarbowego',
                saveType: 'address',
                saveName: 'creditor_city',
                name: 'city',
                component: "AutoComplete",
                suggestions: cities,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 152,
                uuid: "153",
                label: 'Ulica Urzędu Skarbowego',
                saveType: 'address',
                saveName: 'creditor_street',
                name: 'street',
                component: "AutoComplete",
                suggestions: streets,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 153,
                uuid: "154",
                label: 'Numer budynku Urzędu Skarbowego',
                saveType: 'address',
                saveName: 'creditor_house_number',
                name: 'house_number',
                component: "AutoComplete",
                suggestions: houseNumbers,
                errorLabels: ['Należy podać odpowiedź', 'Podany numer jest za długi'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 10
                }]
            },
            {
                id: 154,
                uuid: "155",
                label: 'Numer lokalu Urzędu Skarbowego',
                saveType: 'address',
                saveName: 'creditor_flat_number',
                name: 'flat_number',
                component: "AutoComplete",
                suggestions: flatNumbers,
                errorLabels: ['Podany numer jest za długi'],
                conditions: [{
                    type: "length",
                    minvalue: 0,
                    maxvalue: 10
                }]
            },
            {
                id: 155,
                uuid: "156",
                label: 'Kod pocztowy Urzędu Skarbowego',
                saveType: 'address',
                saveName: 'creditor_postal_code',
                name: 'postal_code',
                component: "AutoComplete",
                suggestions: postalCodes,
                errorLabels: ['Należy podać odpowiedź', "Podany kod jest nieprawidłowy"],
                conditions: [{
                    type: "required"
                },
                {
                    type: "regex",
                    value: "^[A-Z0-9]{2,3}[-\ ]?[A-Z0-9]{3,4}$"
                }]
            },
            {
                id: 156,
                uuid: "157",
                label: 'Kwota wierzytelności',
                name: 'amount',
                component: "InputNumber",
                innerInputProps: {
                    mode: 'currency',
                    currency: 'PLN',
                },
                errorLabels: ['Należy podać odpowiedź', 'Podana wartość musi być wieksza od 0'],
                conditions: [{
                    type: "required"
                }, {
                    type: "value",
                    minvalue: 0
                }]
            },
            {
                id: 157,
                uuid: "158",
                label: 'Termin zapłaty',
                name: 'payment_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            }
        ]
    }, {
        id: 159,
        uuid: "160",
        title: 'Spis wierzycieli',
        label: 'Data zaświadczenia z Urzędu Skarbowego',
        component: "DatePicker",
        errorLabels: ['Data zaświadczenia z Urzędu Skarbowego jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        id: 160,
        uuid: "161",
        title: 'Spis wierzycieli',
        label: 'Instrukcja',
        component: "Instruction",
        // wykonaj kopie dokumentu z urzędu skarbowego
    }, {
        id: 161,
        uuid: "162",
        component: "autoJump",
        cond: () => {
            return formValues.value[KRDid + 5] != 'Tak';
        },
        frontComponent: "Jump",
        jumpid: 175
    }, {
        id: 162,
        uuid: "163",
        title: 'Spis wierzycieli',
        label: 'Instrukcja',
        component: "Instruction",
        // przygotuj dokumenty z ZUS
    }, {
        id: 163,
        title: 'Spis wierzycieli',
        labels: [
            "Czy ZUS wskazał zaległości?"
        ],
        options: ["ZUS"],
        uuid: "164",
        name: 'creditor_type',
        optionid: 0,
        customBehaviour: [
            {
                optionid: 0,
                jumpOnYes: 164,
                jumpOnNo: 175,
                optionChangeOnYes: true,
                optionChangeOnNo: false,
                exitOnYes: true
            }
        ],
        optionRenewal: false,
        optionChangeOnEntry: true,
        optionSkipEvenOnNO: true,
        endid: 172,
        subObjectIndex: 4,
        component: "SubObject",
        content: [
            {
                id: 164,
                uuid: "165",
                label: 'Nazwa oddziału ZUS',
                name: 'creditor_name',
                component: "InputText",
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 165,
                uuid: "166",
                label: 'Miejscowość siedziby oddziału ZUS',
                saveType: 'address',
                saveName: 'creditor_city',
                name: 'city',
                component: "AutoComplete",
                suggestions: cities,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 166,
                uuid: "167",
                label: 'Ulica siedziby oddziału ZUS',
                saveType: 'address',
                saveName: 'creditor_street',
                name: 'street',
                component: "AutoComplete",
                suggestions: streets,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 167,
                uuid: "168",
                label: 'Numer budynku siedziby oddziału ZUS',
                saveType: 'address',
                saveName: 'creditor_house_number',
                name: 'house_number',
                component: "AutoComplete",
                suggestions: houseNumbers,
                errorLabels: ['Należy podać odpowiedź', 'Podany numer jest za długi'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 10
                }]
            },
            {
                id: 168,
                uuid: "169",
                label: 'Numer lokalu siedziby oddziału ZUS',
                saveType: 'address',
                saveName: 'creditor_flat_number',
                name: 'flat_number',
                component: "AutoComplete",
                suggestions: flatNumbers,
                errorLabels: ['Podany numer jest za długi'],
                conditions: [{
                    type: "length",
                    minvalue: 0,
                    maxvalue: 10
                }]
            },
            {
                id: 169,
                uuid: "170",
                label: 'Kod pocztowy',
                saveType: 'address',
                saveName: 'creditor_postal_code',
                name: 'postal_code',
                component: "AutoComplete",
                suggestions: postalCodes,
                errorLabels: ['Należy podać odpowiedź', "Podany kod jest nieprawidłowy"],
                conditions: [{
                    type: "required"
                },
                {
                    type: "regex",
                    value: "^[A-Z0-9]{2,3}[-\ ]?[A-Z0-9]{3,4}$"
                }]
            },
            {
                id: 170,
                uuid: "171",
                label: 'Kwota wierzytelności',
                name: 'amount',
                component: "InputNumber",
                innerInputProps: {
                    mode: 'currency',
                    currency: 'PLN',
                },
                errorLabels: ['Należy podać odpowiedź', 'Podana wartość musi być wieksza od 0'],
                conditions: [{
                    type: "required"
                }, {
                    type: "value",
                    minvalue: 0
                }]
            },
            {
                id: 171,
                uuid: "172",
                label: 'Termin zapłaty',
                name: 'payment_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            }
        ]
    }, {
        id: 173,
        uuid: "174",
        title: 'Spis wierzycieli',
        label: 'Data dokumentu z ZUS',
        component: "DatePicker",
        errorLabels: ['Data dokumentu z ZUS jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        id: 174,
        uuid: "175",
        title: 'Spis wierzycieli',
        label: 'Instrukcja',
        component: "Instruction",
        //wykonaj kopie documentu z ZUS
    }, {
        id: 175,
        uuid: "176",
        title: 'Spis wierzycieli',
        label: 'Instrukcja',
        component: "Instruction",
        // przygotuj dokumenty BIK
    }, {
        id: 176,
        title: 'Spis wierzycieli',
        labels: [
            "Czy rozpoznajesz jakiekolwiek zobowiązanie którego mogłeś/mogłaś nie wpisać w poprzednich punktach?", "Czy rozpoznajesz kolejne zobowiązanie którego mogłeś/mogłaś nie wpisać w poprzednich punktach?"
        ],
        options: ["Tak", "Tak"],
        uuid: "177",
        name: 'ignore',
        optionid: 0,
        customBehaviour: [
            {
                optionid: 0,
                jumpOnYes: 177,
                jumpOnNo: 197,
                optionChangeOnYes: true,
                optionChangeOnNo: false
            }
        ],
        optionRenewal: false,
        optionChangeOnEntry: true,
        optionSkipEvenOnNO: true,
        endid: 194,
        subObjectIndex: 4,
        component: "SubObject",
        content: [
            {
                id: 177,
                uuid: "178",
                label: 'Czy posiadasz inną dokumentację na temat tego zadłużenia np.: umowę?',
                name: 'document_confirmation',
                frontComponent: "Jump",
                component: "SelectButton",
                jumpActivation: 1,
                jumpid: 181,
                options: ['Tak', 'Nie'],
                errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
                conditions: [{
                    type: "required"
                }]
            }, {
                id: 178,
                uuid: "179",
                label: 'Nazwa dokumentu',
                name: 'document_name',
                fact: 'Na okoliczność posiadanej wierzytelności.',
                component: "InputText",
                errorLabels: ['Nazwa dokumentu jest wymagana', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 200
                }]
            }, {
                id: 179,
                uuid: "180",
                label: 'Data dokumentu',
                name: 'document_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            }, {
                id: 180,
                uuid: "181",
                label: 'Instrukcja',
                component: "Instruction",
                frontComponent: "Jump",
                jumpid: 184
                // 2 kopie dokument klienta
            },
            {
                id: 181,
                uuid: "182",
                label: 'Czy znasz termin zapłaty tego zobowiązania?',
                name: 'date_confirmation',
                frontComponent: "Jump",
                component: "SelectButton",
                jumpActivation: 0,
                jumpid: 184,
                options: ['Tak', 'Nie'],
                errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
                conditions: [{
                    type: "required"
                }]
            },
            {
                id: 182,
                uuid: "183",
                label: 'Czy jesteś pewny/a że ta wierzytelność nie była wcześniej wymieniona?',
                name: 'confirmation',
                frontComponent: "Jump",
                component: "SelectButton",
                jumpActivation: 1,
                jumpid: 176,
                options: ['Tak', 'Nie'],
                errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
                conditions: [{
                    type: "required"
                }]
            },
            {
                id: 183,
                uuid: "184",
                label: 'Instrukcja',
                component: "Instruction",
                frontComponent: "Jump",
                jumpid: 181
                // telefon do przyjaciela
            },
            {
                id: 184,
                uuid: "185",
                label: 'Imię i Nazwisko lub Nazwa wierzyciela',
                name: 'creditor_name',
                component: "InputText",
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 185,
                uuid: "186",
                label: 'Kraj wierzyciela',
                saveType: 'address',
                saveName: 'creditor_country',
                name: 'country',
                component: "AutoComplete",
                suggestions: countries,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 186,
                uuid: "187",
                label: 'Miejscowość wierzyciela',
                saveType: 'address',
                saveName: 'creditor_city',
                name: 'city',
                component: "AutoComplete",
                suggestions: cities,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 187,
                uuid: "188",
                label: 'Ulica wierzyciela',
                saveType: 'address',
                saveName: 'creditor_street',
                name: 'street',
                component: "AutoComplete",
                suggestions: streets,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 188,
                uuid: "189",
                label: 'Numer budynku wierzyciela',
                saveType: 'address',
                saveName: 'creditor_house_number',
                name: 'house_number',
                component: "AutoComplete",
                suggestions: houseNumbers,
                errorLabels: ['Należy podać odpowiedź', 'Podany numer jest za długi'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 10
                }]
            },
            {
                id: 189,
                uuid: "190",
                label: 'Numer lokalu wierzyciela',
                saveType: 'address',
                saveName: 'creditor_flat_number',
                name: 'flat_number',
                component: "AutoComplete",
                suggestions: flatNumbers,
                errorLabels: ['Podany numer jest za długi'],
                conditions: [{
                    type: "length",
                    minvalue: 0,
                    maxvalue: 10
                }]
            },
            {
                id: 190,
                uuid: "191",
                label: 'Kod pocztowy',
                saveType: 'address',
                saveName: 'creditor_postal_code',
                name: 'postal_code',
                component: "AutoComplete",
                suggestions: postalCodes,
                errorLabels: ['Należy podać odpowiedź', "Podany kod jest nieprawidłowy"],
                conditions: [{
                    type: "required"
                },
                {
                    type: "regex",
                    value: "^[A-Z0-9]{2,3}[-\ ]?[A-Z0-9]{3,4}$"
                }]
            },
            {
                id: 191,
                uuid: "192",
                label: 'Waluta wierzytelności',
                name: 'currency',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
                errorLabels: ['Waluta jest wymagana', 'Nieprawidłowy format waluty'],
                conditions: [{
                    type: "required"
                }, {
                    type: "regex",
                    value: "^[a-zA-Z]{3}$"
                }]
            },
            {
                id: 192,
                uuid: "193",
                label: 'Kwota wierzytelności',
                name: 'amount',
                component: "InputNumber",
                innerInputProps: {
                    mode: 'currency',
                    currency: currency,
                },
                errorLabels: ['Należy podać odpowiedź', 'Podana wartość musi być wieksza od 0'],
                conditions: [{
                    type: "required"
                }, {
                    type: "value",
                    minvalue: 0
                }]
            },
            {
                id: 193,
                uuid: "194",
                label: 'Termin zapłaty',
                name: 'payment_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            }
        ]
    }, {
        id: 195,
        uuid: "196",
        title: 'Spis wierzycieli',
        label: 'Data dokumentu BIK',
        component: "DatePicker",
        errorLabels: ['Data dokumentu BIK jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        id: 196,
        uuid: "197",
        title: 'Spis wierzycieli',
        label: 'Instrukcja',
        component: "Instruction",
        // wykonaj kopie raportu BIK
    }, {
        id: 197,
        title: 'Spis wierzycieli',
        labels: [
            "Czy posiadasz inne zobowiązania które nie zostały jeszcze wymienione?", "Czy posiadasz kolejne zobowiązania które nie zostały jeszcze wymienione?"
        ],
        options: ["Tak", "Tak"],
        uuid: "198",
        name: 'ignore',
        optionid: 0,
        optionRenewal: false,
        optionChangeOnEntry: true,
        optionSkipEvenOnNO: true,
        endid: 208,
        subObjectIndex: 4,
        component: "SubObject",
        content: [
            {
                id: 198,
                uuid: "199",
                label: 'Imię i Nazwisko lub Nazwa wierzyciela',
                name: 'creditor_name',
                component: "InputText",
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 199,
                uuid: "200",
                label: 'Kraj wierzyciela',
                saveType: 'address',
                saveName: 'creditor_country',
                name: 'country',
                component: "AutoComplete",
                suggestions: countries,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 200,
                uuid: "201",
                label: 'Miejscowość wierzyciela',
                saveType: 'address',
                saveName: 'creditor_city',
                name: 'city',
                component: "AutoComplete",
                suggestions: cities,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 201,
                uuid: "202",
                label: 'Ulica wierzyciela',
                saveType: 'address',
                saveName: 'creditor_street',
                name: 'street',
                component: "AutoComplete",
                suggestions: streets,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 202,
                uuid: "203",
                label: 'Numer budynku wierzyciela',
                saveType: 'address',
                saveName: 'creditor_house_number',
                name: 'house_number',
                component: "AutoComplete",
                suggestions: houseNumbers,
                errorLabels: ['Należy podać odpowiedź', 'Podany numer jest za długi'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 10
                }]
            },
            {
                id: 203,
                uuid: "204",
                label: 'Numer lokalu wierzyciela',
                saveType: 'address',
                saveName: 'creditor_flat_number',
                name: 'flat_number',
                component: "AutoComplete",
                suggestions: flatNumbers,
                errorLabels: ['Podany numer jest za długi'],
                conditions: [{
                    type: "length",
                    minvalue: 0,
                    maxvalue: 10
                }]
            },
            {
                id: 204,
                uuid: "205",
                label: 'Kod pocztowy',
                saveType: 'address',
                saveName: 'creditor_postal_code',
                name: 'postal_code',
                component: "AutoComplete",
                suggestions: postalCodes,
                errorLabels: ['Należy podać odpowiedź', "Podany kod jest nieprawidłowy"],
                conditions: [{
                    type: "required"
                },
                {
                    type: "regex",
                    value: "^[A-Z0-9]{2,3}[-\ ]?[A-Z0-9]{3,4}$"
                }]
            },
            {
                id: 205,
                uuid: "206",
                label: 'Waluta wierzytelności',
                name: 'currency',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
                errorLabels: ['Waluta jest wymagana', 'Nieprawidłowy format waluty'],
                conditions: [{
                    type: "required"
                }, {
                    type: "regex",
                    value: "^[a-zA-Z]{3}$"
                }]
            },
            {
                id: 206,
                uuid: "207",
                label: 'Kwota wierzytelności',
                name: 'amount',
                component: "InputNumber",
                innerInputProps: {
                    mode: 'currency',
                    currency: currency,
                },
                errorLabels: ['Należy podać odpowiedź', 'Podana wartość musi być wieksza od 0'],
                conditions: [{
                    type: "required"
                }, {
                    type: "value",
                    minvalue: 0
                }]
            },
            {
                id: 207,
                uuid: "208",
                label: 'Termin zapłaty',
                name: 'payment_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            }
        ]
    }, {
        id: 209,
        title: 'Wierzytelności sporne',
        labels: [
            "Czy posiadasz wierzytelności sporne?", "Czy posiadasz kolejne wierzytelności sporne?"
        ],
        options: ["Tak", "Tak"],
        uuid: "210",
        name: 'ignore',
        optionid: 0,
        optionRenewal: false,
        optionChangeOnEntry: true,
        optionSkipEvenOnNO: true,
        endid: 226,
        subObjectIndex: 5,
        component: "SubObject",
        content: [
            {
                id: 210,
                uuid: "211",
                label: 'Czy komornik kiedykolwiek prowadził egzekucję tej wierzytelność?',
                name: 'confirmation',
                frontComponent: "Jump",
                component: "SelectButton",
                jumpActivation: 1,
                jumpid: 212,
                options: ['Tak', 'Nie'],
                errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
                conditions: [{
                    type: "required"
                }]
            },
            {
                id: 211,
                uuid: "212",
                label: 'Instrukcja',
                component: "Instruction",
                frontComponent: "Jump",
                jumpid: 197
                // nie jest sporna
            },
            {
                id: 212,
                uuid: "213",
                label: 'Imię i Nazwisko lub Nazwa wierzyciela',
                name: 'creditor_name',
                component: "InputText",
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 213,
                uuid: "214",
                label: 'Kraj wierzyciela',
                saveType: 'address',
                saveName: 'creditor_country',
                name: 'country',
                component: "AutoComplete",
                suggestions: countries,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 214,
                uuid: "215",
                label: 'Miejscowość wierzyciela',
                saveType: 'address',
                saveName: 'creditor_city',
                name: 'city',
                component: "AutoComplete",
                suggestions: cities,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 215,
                uuid: "216",
                label: 'Ulica wierzyciela',
                saveType: 'address',
                saveName: 'creditor_street',
                name: 'street',
                component: "AutoComplete",
                suggestions: streets,
                errorLabels: ['Należy podać odpowiedź', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 216,
                uuid: "217",
                label: 'Numer budynku wierzyciela',
                saveType: 'address',
                saveName: 'creditor_house_number',
                name: 'house_number',
                component: "AutoComplete",
                suggestions: houseNumbers,
                errorLabels: ['Należy podać odpowiedź', 'Podany numer jest za długi'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 10
                }]
            },
            {
                id: 217,
                uuid: "218",
                label: 'Numer lokalu wierzyciela',
                saveType: 'address',
                saveName: 'creditor_flat_number',
                name: 'flat_number',
                component: "AutoComplete",
                suggestions: flatNumbers,
                errorLabels: ['Podany numer jest za długi'],
                conditions: [{
                    type: "length",
                    minvalue: 0,
                    maxvalue: 10
                }]
            },
            {
                id: 218,
                uuid: "219",
                label: 'Kod pocztowy',
                saveType: 'address',
                saveName: 'creditor_postal_code',
                name: 'postal_code',
                component: "AutoComplete",
                suggestions: postalCodes,
                errorLabels: ['Należy podać odpowiedź', "Podany kod jest nieprawidłowy"],
                conditions: [{
                    type: "required"
                },
                {
                    type: "regex",
                    value: "^[A-Z0-9]{2,3}[-\ ]?[A-Z0-9]{3,4}$"
                }]
            },
            {
                id: 219,
                uuid: "220",
                label: 'Waluta wierzytelności',
                name: 'currency',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
                errorLabels: ['Waluta jest wymagana', 'Nieprawidłowy format waluty'],
                conditions: [{
                    type: "required"
                }, {
                    type: "regex",
                    value: "^[a-zA-Z]{3}$"
                }]
            },
            {
                id: 220,
                uuid: "221",
                label: 'Kwota wierzytelności',
                name: 'amount',
                component: "InputNumber",
                innerInputProps: {
                    mode: 'currency',
                    currency: currency,
                },
                errorLabels: ['Należy podać odpowiedź', 'Podana wartość musi być wieksza od 0'],
                conditions: [{
                    type: "required"
                }, {
                    type: "value",
                    minvalue: 0
                }]
            },
            {
                id: 221,
                uuid: "222",
                label: 'Termin zapłaty',
                name: 'payment_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            },
            {
                id: 222,
                uuid: "223",
                label: 'Zakres kwestionowania',
                name: 'questioning_scope',
                component: "TextArea",
                innerInputProps: {
                    rows: 5,
                    cols: 50,
                    autoResize: true
                },
                errorLabels: ['Należy podać odpowiedź', 'Podana treść jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 1000
                }]
            }, {
                id: 223,
                uuid: "224",
                label: 'Nazwa dokumentu',
                name: 'document_name',
                fact: 'Na okoliczność posiadania wierzytelności spornej.',
                component: "InputText",
                errorLabels: ['Nazwa dokumentu jest wymagana', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 200
                }]
            }, {
                id: 224,
                uuid: "225",
                label: 'Data dokumentu',
                name: 'document_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            },
            {
                id: 225,
                uuid: "226",
                label: 'Instrukcja',
                component: "Instruction",
                // 2 kopie dokument klienta
            }
        ]
    }, {
        id: 227,
        title: 'Dochody dłużnika',
        labels: [
            "Czy uzyskałeś/łaś dochód w ostatnich 6-ciu miesiącach z tytułu umowy o pracę?",
            "Czy uzyskałeś/łaś dochód w ostatnich 6-ciu miesiącach z tytułu umowy zlecenia?",
            "Czy uzyskałeś/łaś dochód w ostatnich 6-ciu miesiącach z tytułu umowy o dzieło?",
            "Czy uzyskałeś/łaś dochód w ostatnich 6-ciu miesiącach z tytułu świadczenia zdrowotnego (L4)?",
            "Czy uzyskałeś/łaś dochód w ostatnich 6-ciu miesiącach z tytułu świadczenia rehabilitacyjnego?",
            "Czy uzyskałeś/łaś dochód w ostatnich 6-ciu miesiącach z tytułu renty?",
            "Czy uzyskałeś/łaś dochód w ostatnich 6-ciu miesiącach z tytułu emerytury?",
            "Czy uzyskałeś/łaś dochód w ostatnich 6-ciu miesiącach z tytułu świadczenia pielęgnacyjnego?",
            "Czy uzyskałeś/łaś dochód w ostatnich 6-ciu miesiącach z tytułu dodatku pielęgnacyjnego?",
            "Czy uzyskałeś/łaś dochód w ostatnich 6-ciu miesiącach z tytułu świadczenia wychowawczego (800+)?",
            "Czy uzyskałeś/łaś dochód w ostatnich 6-ciu miesiącach z tytułu pomocy społecznej MOPS?",
            "Czy uzyskałeś/łaś dochód w ostatnich 6-ciu miesiącach z tytułu pracy dorywczej?",
            "Czy uzyskałeś/łaś inne dochody w ostatnich 6-ciu miesiącach?",
        ],
        options: [
            "Wynagrodzenie - Umowa o pracę",
            "Wynagrodzenie - Umowa zlecenie",
            "Wynagrodzenie - Umowa o dzieło",
            "Świadczenie zdrowotne (L4)",
            "Świadczenie rehabilitacyjne",
            "Renta",
            "Emerytura",
            "Świadczenie pielęgnacyjne",
            "Dodatek pielęgnacyjny",
            "Świadczenie wychowawcze (800+)",
            "Pomoc społeczna MOPS",
            "Prace dorywcze",
            "Inne"
        ],
        uuid: "228",
        name: 'income_source',
        optionid: 0,
        optionRenewal: false,
        optionChangeOnEntry: true,
        optionSkipEvenOnNO: false,
        endid: 246,
        subObjectIndex: 6,
        component: "SubObject",
        content: [
            {
                id: 228,
                uuid: "229",
                label: 'Waluta dochodu uzyskanego w ' + getDateMinus(1),
                name: 'currency1',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
                errorLabels: ['Waluta jest wymagana', 'Nieprawidłowy format waluty'],
                conditions: [{
                    type: "required"
                }, {
                    type: "regex",
                    value: "^[a-zA-Z]{3}$"
                }]
            },
            {
                id: 229,
                uuid: "230",
                label: 'Kwota przychodu netto uzyskana w ' + getDateMinus(1),
                name: 'income_amount1',
                component: "InputNumber",
                innerInputProps: {
                    mode: 'currency',
                    currency: currency,
                },
                errorLabels: ['Należy podać odpowiedź', 'Podana wartość musi być wieksza od 0'],
                conditions: [{
                    type: "required"
                }, {
                    type: "value",
                    minvalue: 0
                }]
            },
            {
                id: 230,
                uuid: "231",
                label: 'Czy kwota uwzględnia egzekucję komorniczą?',
                name: 'income_deduction1',
                component: "Select",
                options: ["Otrzymywana kwota po egzekucji komorniczej", "Otrzymywana kwota bez obciążeń komorniczych"],
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            },
            {
                id: 231,
                uuid: "232",
                label: 'Waluta dochodu uzyskanego w ' + getDateMinus(2),
                name: 'currency2',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
                errorLabels: ['Waluta jest wymagana', 'Nieprawidłowy format waluty'],
                conditions: [{
                    type: "required"
                }, {
                    type: "regex",
                    value: "^[a-zA-Z]{3}$"
                }]
            },
            {
                id: 232,
                uuid: "233",
                label: 'Kwota przychodu netto uzyskana w ' + getDateMinus(2),
                name: 'income_amount2',
                component: "InputNumber",
                innerInputProps: {
                    mode: 'currency',
                    currency: currency,
                },
                errorLabels: ['Należy podać odpowiedź', 'Podana wartość musi być wieksza od 0'],
                conditions: [{
                    type: "required"
                }, {
                    type: "value",
                    minvalue: 0
                }]
            },
            {
                id: 233,
                uuid: "234",
                label: 'Czy kwota uwzględnia egzekucję komorniczą?',
                name: 'income_deduction2',
                component: "Select",
                options: ["Otrzymywana kwota po egzekucji komorniczej", "Otrzymywana kwota bez obciążeń komorniczych"],
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            },
            {
                id: 234,
                uuid: "235",
                label: 'Waluta dochodu uzyskanego w ' + getDateMinus(3),
                name: 'currency3',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
                errorLabels: ['Waluta jest wymagana', 'Nieprawidłowy format waluty'],
                conditions: [{
                    type: "required"
                }, {
                    type: "regex",
                    value: "^[a-zA-Z]{3}$"
                }]
            },
            {
                id: 235,
                uuid: "236",
                label: 'Kwota przychodu netto uzyskana w ' + getDateMinus(3),
                name: 'income_amount3',
                component: "InputNumber",
                innerInputProps: {
                    mode: 'currency',
                    currency: currency,
                },
                errorLabels: ['Należy podać odpowiedź', 'Podana wartość musi być wieksza od 0'],
                conditions: [{
                    type: "required"
                }, {
                    type: "value",
                    minvalue: 0
                }]
            },
            {
                id: 236,
                uuid: "237",
                label: 'Czy kwota uwzględnia egzekucję komorniczą?',
                name: 'income_deduction3',
                component: "Select",
                options: ["Otrzymywana kwota po egzekucji komorniczej", "Otrzymywana kwota bez obciążeń komorniczych"],
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            },
            {
                id: 237,
                uuid: "238",
                label: 'Waluta dochodu uzyskanego w ' + getDateMinus(4),
                name: 'currency4',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
                errorLabels: ['Waluta jest wymagana', 'Nieprawidłowy format waluty'],
                conditions: [{
                    type: "required"
                }, {
                    type: "regex",
                    value: "^[a-zA-Z]{3}$"
                }]
            },
            {
                id: 238,
                uuid: "239",
                label: 'Kwota przychodu netto uzyskana w ' + getDateMinus(4),
                name: 'income_amount4',
                component: "InputNumber",
                innerInputProps: {
                    mode: 'currency',
                    currency: currency,
                },
                errorLabels: ['Należy podać odpowiedź', 'Podana wartość musi być wieksza od 0'],
                conditions: [{
                    type: "required"
                }, {
                    type: "value",
                    minvalue: 0
                }]
            },
            {
                id: 239,
                uuid: "240",
                label: 'Czy kwota uwzględnia egzekucję komorniczą?',
                name: 'income_deduction4',
                component: "Select",
                options: ["Otrzymywana kwota po egzekucji komorniczej", "Otrzymywana kwota bez obciążeń komorniczych"],
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            },
            {
                id: 240,
                uuid: "241",
                label: 'Waluta dochodu uzyskanego w ' + getDateMinus(5),
                name: 'currency5',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
                errorLabels: ['Waluta jest wymagana', 'Nieprawidłowy format waluty'],
                conditions: [{
                    type: "required"
                }, {
                    type: "regex",
                    value: "^[a-zA-Z]{3}$"
                }]
            },
            {
                id: 241,
                uuid: "242",
                label: 'Kwota przychodu netto uzyskana w ' + getDateMinus(5),
                name: 'income_amount5',
                component: "InputNumber",
                innerInputProps: {
                    mode: 'currency',
                    currency: currency,
                },
                errorLabels: ['Należy podać odpowiedź', 'Podana wartość musi być wieksza od 0'],
                conditions: [{
                    type: "required"
                }, {
                    type: "value",
                    minvalue: 0
                }]
            },
            {
                id: 242,
                uuid: "243",
                label: 'Czy kwota uwzględnia egzekucję komorniczą?',
                name: 'income_deduction5',
                component: "Select",
                options: ["Otrzymywana kwota po egzekucji komorniczej", "Otrzymywana kwota bez obciążeń komorniczych"],
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            },
            {
                id: 243,
                uuid: "244",
                label: 'Waluta dochodu uzyskanego w ' + getDateMinus(6),
                name: 'currency6',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
                errorLabels: ['Waluta jest wymagana', 'Nieprawidłowy format waluty'],
                conditions: [{
                    type: "required"
                }, {
                    type: "regex",
                    value: "^[a-zA-Z]{3}$"
                }]
            },
            {
                id: 244,
                uuid: "245",
                label: 'Kwota przychodu netto uzyskana w ' + getDateMinus(6),
                name: 'income_amount6',
                component: "InputNumber",
                innerInputProps: {
                    mode: 'currency',
                    currency: currency,
                },
                errorLabels: ['Należy podać odpowiedź', 'Podana wartość musi być wieksza od 0'],
                conditions: [{
                    type: "required"
                }, {
                    type: "value",
                    minvalue: 0
                }]
            },
            {
                id: 245,
                uuid: "246",
                label: 'Czy kwota uwzględnia egzekucję komorniczą?',
                name: 'income_deduction6',
                component: "Select",
                options: ["Otrzymywana kwota po egzekucji komorniczej", "Otrzymywana kwota bez obciążeń komorniczych"],
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            }
        ]
    }, {
        id: 247,
        title: 'Wydatki dłużnika',
        labels: [
            "Czy poniosłeś/łaś w ostatnich 6-ciu miesiącach koszty z tytułu czynszu i innych opłat za mieszkanie/dom?",
            "Czy poniosłeś/łaś w ostatnich 6-ciu miesiącach koszty z tytułu opłat za energię elektryczną?",
            "Czy poniosłeś/łaś w ostatnich 6-ciu miesiącach koszty z tytułu opłat za gaz?",
            "Czy poniosłeś/łaś w ostatnich 6-ciu miesiącach koszty z tytułu opłat za wodę i kanalizację (wywóz nieczystności płynnych)?",
            "Czy poniosłeś/łaś w ostatnich 6-ciu miesiącach koszty z tytułu opłat za wywóz śmieci i innych odpadów?",
            "Czy poniosłeś/łaś w ostatnich 6-ciu miesiącach koszty z tytułu zakupu środków czystości i higieny osobistej?",
            "Czy poniosłeś/łaś w ostatnich 6-ciu miesiącach koszty z tytułu zakupu żywności i napojów bezalkoholowych?",
            "Czy poniosłeś/łaś w ostatnich 6-ciu miesiącach koszty z tytułu wydatków na zdrowie (wizyty lekarskie, leki, rehabilitacja)?",
            "Czy poniosłeś/łaś w ostatnich 6-ciu miesiącach koszty z tytułu opłat za telewizję?",
            "Czy poniosłeś/łaś w ostatnich 6-ciu miesiącach koszty z tytułu opłat za Internet?",
            "Czy poniosłeś/łaś w ostatnich 6-ciu miesiącach koszty z tytułu opłat za telefon?",
            "Czy poniosłeś/łaś w ostatnich 6-ciu miesiącach koszty z tytułu zakupu odzieży i obuwia?",
            "Czy poniosłeś/łaś w ostatnich 6-ciu miesiącach koszty z tytułu wydatków na transport i przejazdy?",
            // "Czy poniosłeś/łaś w ostatnich 6-ciu miesiącach koszty z tytułu sportu, rekreacji, kultury?",
            // "Czy poniosłeś/łaś w ostatnich 6-ciu miesiącach inne wydatki?"
        ],
        options: [
            "Czynsz i inne opłaty za mieszkanie/dom",
            "Opłaty za energię elektryczną",
            "Opłaty za gaz",
            "Opłaty za wodę i kanalizację (wywóz nieczystości płyn.)",
            "Opłaty za wywóz śmieci i innych odpadów",
            "Środki czystości i higieny osobistej",
            "Żywność i napoje bezalkoholowe",
            "Wydatki na zdrowie (wizyty lekarskie, leki, rehabilitacja)",
            "Opłaty za telewizję ",
            "Opłaty za Internet ",
            "Opłaty za telefon ",
            "Odzież i obuwie ",
            "Wydatki na transport i przejazdy",
            // "Sport, rekreacja, kultura",
            // "Pozostałe wydatki"
        ],
        uuid: "248",
        name: 'spending_type',
        optionid: 0,
        optionChangeOnEntry: true,
        endid: 250,
        subObjectIndex: 7,
        customBehaviour: [
            {
                optionid: 12,
                jumpOnYes: 248,
                jumpOnNo: 251,
                optionChangeOnYes: false,
                optionChangeOnNo: false,
                exitOnYes: true
            }
        ],
        component: "SubObject",
        content: [
            {
                id: 248,
                uuid: "249",
                label: 'Waluta wydatku',
                name: 'currency',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
                errorLabels: ['Waluta jest wymagana', 'Nieprawidłowy format waluty'],
                conditions: [{
                    type: "required"
                }, {
                    type: "regex",
                    value: "^[a-zA-Z]{3}$"
                }]
            },
            {
                id: 249,
                uuid: "250",
                label: "Miesięczna kwota przeznaczana na ten cel:",
                name: 'amount',
                component: "InputNumber",
                innerInputProps: {
                    mode: 'currency',
                    currency: currency,
                },
                errorLabels: ['Należy podać odpowiedź', 'Podana wartość nie może być ujemna'],
                conditions: [{
                    type: "required"
                }, {
                    type: "value",
                    minvalue: -0.001
                }]
            },
        ]
    }, {
        id: 251,
        title: 'Wydatki dłużnika',
        labels: ["Czy poniosłeś/łaś w ostatnich 6-ciu miesiącach inne wydatki?", "Czy poniosłeś/łaś w ostatnich 6-ciu miesiącach kolejne inne wydatki?"],
        options: ["Pozostałe wydatki", "Pozostałe wydatki"],
        uuid: "252",
        name: 'spending_type',
        optionid: 0,
        optionChangeOnEntry: true,
        endid: 255,
        subObjectIndex: 7,
        component: "SubObject",
        content: [
            {
                id: 252,
                uuid: "253",
                label: 'Opis wydatku',
                name: 'spending_name',
                component: "InputText",
                errorLabels: ['Opis wydatku jest wymagany', 'Podany opis jest za długi'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 150
                }]
            },
            {
                id: 253,
                uuid: "254",
                label: 'Waluta wydatku',
                name: 'currency',
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["PLN", "EUR", "USD", "GBP", "NOK", "CZK", "CHF"],
                errorLabels: ['Waluta jest wymagana', 'Nieprawidłowy format waluty'],
                conditions: [{
                    type: "required"
                }, {
                    type: "regex",
                    value: "^[a-zA-Z]{3}$"
                }]
            },
            {
                id: 254,
                uuid: "255",
                label: "Miesięczna kwota przeznaczana na ten cel:",
                name: 'amount',
                component: "InputNumber",
                innerInputProps: {
                    mode: 'currency',
                    currency: currency,
                },
                errorLabels: ['Należy podać odpowiedź', 'Podana wartość nie może być ujemna'],
                conditions: [{
                    type: "required"
                }, {
                    type: "value",
                    minvalue: -0.001
                }]
            },
        ]
    }, {
        id: 256,
        title: 'Czynności prawne',
        labels: [
            "Czy w ostatnich 12 miesiącach dokonywałeś/łaś czynności prawnych dotyczących nieruchomości, akcji lub udziałów w spółkach?",
            "Czy w ostatnich 12 miesiącach dokonywałeś/łaś kolejnych czynności prawnych dotyczących nieruchomości, akcji lub udziałów w spółkach?",
        ],
        options: ["Tak", "Tak"],
        uuid: "257",
        name: 'ignore',
        optionid: 0,
        optionChangeOnEntry: true,
        optionRenewal: false,
        optionSkipEvenOnNO: true,
        endid: 260,
        subObjectIndex: 8,
        component: "SubObject",
        content: [
            {
                id: 257,
                uuid: "258",
                label: "Przedmiot czynności prawnej:",
                name: "legal_action_name",
                component: "Select",
                options: ["Nieruchomość", "Akcje", "Udziały w spółkach"],
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            },
            {
                id: 258,
                uuid: "259",
                label: "Rodzaj czynności prawnej:",
                name: "legal_action_type",
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["Sprzedaż", "Darowizna", "Cesja", "Zakup"],
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            },
            {
                id: 259,
                uuid: "260",
                label: "Uwagi do czynności prawnej:",
                name: 'legal_action_additional_info',
                component: "TextArea",
                placeholder: 'Pole opcjonalne',
                innerInputProps: {
                    rows: 5,
                    cols: 50,
                    autoResize: true
                },
                errorLabels: ['Podana treść jest za długa'],
                conditions: [{
                    type: "length",
                    minvalue: 0,
                    maxvalue: 1000
                }]
            },
        ]
    }, {
        id: 261,
        title: 'Czynności prawne',
        labels: [
            "Czy w ostatnich 12 miesiącach dokonywałeś/łaś czynności prawnych których przedmiotem były ruchomości, wierzytelności lub inne prawa, których wartość przekracza 10 000 zł?",
            "Czy w ostatnich 12 miesiącach dokonywałeś/łaś kolejnych czynności prawnych których przedmiotem były ruchomości, wierzytelności lub inne prawa, których wartość przekracza 10 000 zł?",
        ],
        options: ["Tak", "Tak"],
        uuid: "262",
        name: 'ignore',
        optionid: 0,
        optionChangeOnEntry: true,
        optionRenewal: false,
        optionSkipEvenOnNO: true,
        endid: 265,
        subObjectIndex: 9,
        component: "SubObject",
        content: [
            {
                id: 262,
                uuid: "263",
                label: "Przedmiot czynności prawnej:",
                name: "legal_action_name",
                component: "InputText",
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 200
                }]
            },
            {
                id: 263,
                uuid: "264",
                label: "Rodzaj czynności prawnej:",
                name: "legal_action_type",
                component: "Select",
                innerInputProps: {
                    editable: true,
                },
                options: ["Sprzedaż", "Zakup", "Darowizna", "Cesja"],
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            },
            {
                id: 264,
                uuid: "265",
                label: "Uwagi do czynności prawnej:",
                name: 'legal_action_additional_info',
                component: "TextArea",
                placeholder: 'Pole opcjonalne',
                innerInputProps: {
                    rows: 5,
                    cols: 50,
                    autoResize: true
                },
                errorLabels: ['Podana treść jest za długa'],
                conditions: [{
                    type: "length",
                    minvalue: 0,
                    maxvalue: 1000
                }]
            },
        ]
    }, {
        id: 266,
        uuid: "267",
        label: 'Czy chciałeś/łaś podjąć dodatkową pracę lub wyjechać za granicę w celach zarobkowych, w celu spłaty długu?',
        title: 'Uzasadnienie wniosku',
        frontComponent: "Jump",
        component: "SelectButton",
        jumpActivation: 1,
        jumpid: 267,
        options: ['Tak', 'Nie'],
        errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        id: 267,
        uuid: "268",
        label: 'Czy rodzina lub znajomi pomagali bądź pomagają Ci się utrzymać?',
        title: 'Uzasadnienie wniosku',
        frontComponent: "Jump",
        component: "SelectButton",
        jumpActivation: 1,
        jumpid: 268,
        options: ['Tak', 'Nie'],
        errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        id: 268,
        uuid: "269",
        label: 'Czy Twoje zobowiązania powstały w wyniku zaciągniętych osobiście przez Ciebie lub z współkredytobiorcą kredytów/pożyczek itp.?',
        title: 'Uzasadnienie wniosku',
        frontComponent: "Jump",
        component: "SelectButton",
        jumpActivation: 1,
        jumpid: 270,
        options: ['Tak', 'Nie'],
        errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        id: 269,
        uuid: "270",
        title: 'Uzasadnienie wniosku',
        label: "Opisz na co przeznaczyłeś środki z pierwszych zaciągniętych zobowiązań.",
        name: 'description_part1',
        component: "TextArea",
        innerInputProps: {
            rows: 5,
            cols: 50,
            autoResize: true
        },
        errorLabels: ['Należy podać odpowiedź', 'Podana treść jest za krótka - opisz to dokładniej.', 'Podana treść jest za długa'],
        conditions: [
            {
                type: "required"
            },
            {
                type: "length",
                minvalue: 50,
                maxvalue: 10000
            },
            {
                type: "length",
                minvalue: 0,
                maxvalue: 1000
            }]
    }, {
        id: 270,
        uuid: "271",
        title: 'Uzasadnienie wniosku',
        label: "Jeśli posiadasz zobowiązania inne niż zaciągane osobiście to opisz je krótko w 2-3 zdaniach.",
        name: 'description_part1',
        component: "TextArea",
        innerInputProps: {
            rows: 5,
            cols: 50,
            autoResize: true
        },
        errorLabels: ['Podana treść jest za długa'],
        conditions: [
            {
                type: "length",
                minvalue: 0,
                maxvalue: 1000
            }]
    }, {
        id: 271,
        uuid: "272",
        title: 'Uzasadnienie wniosku',
        label: "Kiedy i dlaczego przestałeś/łaś prawidłowo regulować swoje zobowiązania?",
        name: 'description_part2',
        component: "TextArea",
        innerInputProps: {
            rows: 5,
            cols: 50,
            autoResize: true
        },
        errorLabels: ['Należy podać odpowiedź', 'Podana treść jest za krótka - opisz te wydarzenia dokładniej.', 'Podana treść jest za długa', 'Opisz w którym roku się to wydarzyło i dlaczego.'],
        conditions: [{
            type: "required"
        },
        {
            type: "length",
            minvalue: 50,
            maxvalue: 10000
        },
        {
            type: "length",
            minvalue: 0,
            maxvalue: 1000
        },
        {
            type: "regex",
            value: "(^|[\\W])(19|20)[0-9]{2}($|[\\W]|r\\.)",
        }]
    }, {
        id: 272,
        uuid: "273",
        title: 'Uzasadnienie wniosku',
        label: "Opisz swoją sytuację rodzinną w 2-3 zdaniach.",
        name: 'description_part3',
        component: "TextArea",
        innerInputProps: {
            rows: 5,
            cols: 50,
            autoResize: true
        },
        errorLabels: ['Należy podać odpowiedź', 'Podana treść jest za krótka - opisz te wydarzenia dokładniej.', 'Podana treść jest za długa'],
        conditions: [{
            type: "required"
        },
        {
            type: "length",
            minvalue: 50,
            maxvalue: 10000
        },
        {
            type: "length",
            minvalue: 0,
            maxvalue: 1000
        }]
    }, {
        id: 273,
        uuid: "274",
        title: 'Uzasadnienie wniosku',
        label: "Opisz swoją sytuację zdrowotną w 2-3 zdaniach.",
        name: 'description_part3',
        component: "TextArea",
        innerInputProps: {
            rows: 5,
            cols: 50,
            autoResize: true
        },
        errorLabels: ['Należy podać odpowiedź', 'Podana treść jest za krótka - opisz te wydarzenia dokładniej.', 'Podana treść jest za długa'],
        conditions: [{
            type: "required"
        },
        {
            type: "length",
            minvalue: 50,
            maxvalue: 10000
        },
        {
            type: "length",
            minvalue: 0,
            maxvalue: 1000
        }]
    }, {
        id: 274,
        title: 'Uzasadnienie wniosku',
        labels: [
            "Czy posiadasz dokumenty na potwierdzenie swojego stanu zdrowia?", "Czy posiadasz kolejne dokumenty na potwierdzenie swojego stanu zdrowia?",
        ],
        options: ["Tak", "Tak"],
        uuid: "275",
        name: 'ignore',
        optionid: 0,
        optionRenewal: false,
        optionChangeOnEntry: true,
        optionSkipEvenOnNO: true,
        endid: 278,
        subObjectIndex: 10,
        component: "SubObject",
        content: [
            {
                id: 275,
                uuid: "276",
                label: 'Nazwa dokumentu',
                name: 'document_name',
                fact: 'Na okoliczność mojego złego stanu zdrowia i podjętego leczenia.',
                component: "InputText",
                errorLabels: ['Nazwa dokumentu jest wymagana', 'Podana nazwa jest za długa'],
                conditions: [{
                    type: "required"
                }, {
                    type: "length",
                    minvalue: 1,
                    maxvalue: 200
                }]
            }, {
                id: 276,
                uuid: "277",
                label: 'Data wystawienia dokumentu',
                name: 'document_date',
                component: "DatePicker",
                innerInputProps: {
                    showIcon: true,
                    iconDisplay: "input",
                    dateFormat: "dd/mm/yy",
                    manualInput: false
                },
                errorLabels: ['Należy podać odpowiedź'],
                conditions: [{
                    type: "required"
                }]
            },
            {
                id: 277,
                uuid: "278",
                label: 'Instrukcja',
                component: "Instruction",
                // 2 kopie dokument klienta
            }
        ]
    }, {
        id: 279,
        uuid: "280",
        title: 'Uzasadnienie wniosku',
        label: "Opisz swoją sytuację zawodową w 2-3 zdaniach.",
        name: 'description_part3',
        component: "TextArea",
        innerInputProps: {
            rows: 5,
            cols: 50,
            autoResize: true
        },
        errorLabels: ['Należy podać odpowiedź', 'Podana treść jest za krótka - opisz te wydarzenia dokładniej.', 'Podana treść jest za długa'],
        conditions: [{
            type: "required"
        },
        {
            type: "length",
            minvalue: 50,
            maxvalue: 10000
        },
        {
            type: "length",
            minvalue: 0,
            maxvalue: 1000
        }]
    }, {
        id: 280,
        uuid: "281",
        label: 'Czy kiedykolwiek prowadziłeś/łaś działalność gospodarczą?',
        title: 'Uzasadnienie wniosku',
        frontComponent: "Jump",
        component: "SelectButton",
        jumpActivation: 1,
        jumpid: 288,
        options: ['Tak', 'Nie'],
        errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        id: 281,
        uuid: "282",
        label: 'Czy Twoja działalność gospodarcza jest zamknięta i wykreślona z rejestru?',
        title: 'Uzasadnienie wniosku',
        frontComponent: "Jump",
        component: "SelectButton",
        jumpActivation: 0,
        jumpid: 283,
        options: ['Tak', 'Nie'],
        errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        id: 282,
        uuid: "283",
        label: 'Instrukcja',
        title: 'Uzasadnienie wniosku',
        component: "Instruction",
        frontComponent: "Jump",
        jumpid: 281
        // zamknij działalność
    }, {
        id: 283,
        uuid: "284",
        label: 'Czy posiadasz dokument potwierdzający zamknięcie działalności i wykreślenie z rejestru?',
        title: 'Uzasadnienie wniosku',
        frontComponent: "Jump",
        component: "SelectButton",
        jumpActivation: 0,
        jumpid: 285,
        options: ['Tak', 'Nie'],
        errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        id: 284,
        uuid: "285",
        label: 'Instrukcja',
        title: 'Uzasadnienie wniosku',
        component: "Instruction",
        frontComponent: "Jump",
        jumpid: 283
        // pobierz doc z ceidg
    }, {
        id: 285,
        uuid: "286",
        label: 'Nazwa dokumentu',
        name: 'document_name',
        fact: 'Na okoliczność nieprowadzenia działalności gospodarczej.',
        component: "InputText",
        errorLabels: ['Nazwa dokumentu jest wymagana', 'Podana nazwa jest za długa'],
        conditions: [{
            type: "required"
        }, {
            type: "length",
            minvalue: 1,
            maxvalue: 200
        }]
    }, {
        id: 286,
        uuid: "287",
        label: 'Data wystawienia dokumentu',
        title: 'Uzasadnienie wniosku',
        name: 'document_date',
        component: "DatePicker",
        innerInputProps: {
            showIcon: true,
            iconDisplay: "input",
            dateFormat: "dd/mm/yy",
            manualInput: false
        },
        errorLabels: ['Należy podać odpowiedź'],
        conditions: [{
            type: "required"
        }]
    }, {
        id: 287,
        uuid: "288",
        title: 'Uzasadnienie wniosku',
        label: 'Instrukcja',
        component: "Instruction",
        // wykonaj 2 kopie dokumentu
    }, {
        id: 288,
        uuid: "289",
        title: 'Przygotowanie wniosku',
        label: 'Instrukcja',
        component: "Instruction",
        // wyślij do weryfikacji
    },
]);

const instructionData = ref([
    {
        id: 0,
        text: `<ol class="list-decimal list-outside pl-3 text-justify">
        <li>Wprowadzane przez Ciebie informacje muszą być zgodne, ze znanym Tobie stanem faktycznym.</li>
        <li>W trakcie wypełniania wniosku ściśle przestrzegaj instrukcji, które towarzyszą kolejnym krokom.</li>
        <li>W trakcie wypełniania wniosku <b>masz możliwość cofnąć się jedynie o jeden krok</b>, dlatego wprowadzaj dane bardzo uważnie.</li>
        </ol>`,
    },
    {
        id: 1,
        text: "Podaj pierwsze imię",
    },
    {
        id: 2,
        text: "Podaj nazwisko",
    },
    {
        id: 3,
        text: "Podaj PESEL",
    },
    {
        id: 4,
        text: "Podaj adres e-mail",
    },
    {
        id: 5,
        text: "Jeśli chcesz aby syndyk szybko skontaktował się z Tobą po ogłoszeniu upadłości to podaj numer telefonu.",
    },
    {
        id: 6,
        text: "Wpisz nazwę miejscowości, w której faktycznie mieszkasz. Nie jest istotny Twój adres zameldowania.",
    },
    {
        id: 7,
        text: "Wpisz nazwę ulicy, na której faktycznie mieszkasz. Nie jest istotny Twój adres zameldowania.",
    },
    {
        id: 8,
        text: "Wpisz numer domu, w którym faktycznie mieszkasz. Nie jest istotny Twój adres zameldowania.",
    },
    {
        id: 9,
        text: "Wpisz numer lokalu/mieszkania, w którym faktycznie mieszkasz. Nie jest istotny Twój adres zameldowania. Jeżeli mieszkasz w domu jednorodzinnym i podałeś/łaś już numer tego domu, to nie wypełniaj tego pola.",
    },
    {
        id: 10,
        text: "Wpisz kod pocztowy właściwy dla Twojego adresu zamieszkania, w którym faktycznie mieszkasz. Nie jest istotny Twój adres zameldowania.",
    },
    {
        id: 11,
        text: "W trakcie postępowania jest bardzo istotne, aby odbierać terminowo korespondencję z sądu czy też od syndyka. Jeżeli odbierasz korespondencję pod adresem zamieszkania zaznacz <b>Tak</b>. Jeżeli odbierasz korespondencję pod innym adresem to zaznacz <b>Nie</b>",
    },
    {
        id: 12,
        text: "Wpisz kraj doręczenia.",
    },
    {
        id: 13,
        text: "Wpisz miejscowość doręczenia.",
    },
    {
        id: 14,
        text: "Wpisz ulicę doręczenia.",
    },
    {
        id: 15,
        text: "Wpisz numer domu doręczenia.",
    },
    {
        id: 16,
        text: "Wpisz numer lokalu/mieszkania doręczenia.",
    },
    {
        id: 17,
        text: "Wpisz numer skrytki pocztowej. Nie musisz go wpisywać jeżeli jest taki sam jak numer domu lub lokalu/mieszkania.",
    },
    {
        id: 18,
        text: "Wpisz kod pocztowy doręczenia.",
    },
    {
        id: 19,
        text: `auto`,
    },
    {
        id: 20,
        text: "Jeśli wybierzesz <b>Nie jestem pewny/na</b> to system usunie Twoje poprzednie wpisy i poprosi Cię o ponowne wpisanie <b>całego</b> majątku.",
    },
    {
        id: 21,
        text: [
            `Jesteś właścicielem lub współwłaścicielem nieruchomości, jeżeli np.:<br>
            <br>
            - jesteś wpisany w księdze wieczystej jako właściciel lub współwłaściciel,</li><br>
            - jesteś spadkobiercą osoby zmarłej, po której została jakakolwiek nieruchomość (działka, dom, mieszkanie). Nie jest istotne czy zostało przeprowadzone postępowanie spadkowe, ani czy jesteś wpisany w księdze wieczystej jako właściciel lub współwłaściciel.</li>
            <br>
            <br>
            W takich przypadkach zaznaczasz <b>Tak</b>`,
            `Zaznacz <b>Tak</b>, jeżeli:<br>
            <br>
            - jesteś wpisany w dowodzie rejestracyjnym pojazdu jako właściciel lub współwłaściciel. <b>Nie ma znaczenia kto faktycznie użytkuje pojazd.</b><br>`,
            `Zaznacz <b>Tak</b>, jeżeli:<br>
            <br>
            - jesteś wpisany w dowodzie rejestracyjnym pojazdu jako właściciel lub współwłaściciel. <b>Nie ma znaczenia kto faktycznie użytkuje pojazd.</b><br>`,
            `Zaznacz <b>Tak</b>, jeżeli:<br>
            <br>
            - jesteś wpisany w dowodzie rejestracyjnym pojazdu jako właściciel lub współwłaściciel. <b>Nie ma znaczenia kto faktycznie użytkuje pojazd.</b><br>`,
            `Zaznacz <b>Tak</b>, jeżeli:<br>
            <br>
            - jesteś wpisany w dowodzie rejestracyjnym pojazdu jako właściciel lub współwłaściciel. <b>Nie ma znaczenia kto faktycznie użytkuje pojazd.</b><br>`,
            `Zaznacz <b>Tak</b>, jeżeli:<br>
            <br>
            - odziedziczyłeś/łaś jakikolwiek spadek, którego nie odrzuciłeś/łaś. Nie ma znaczenia czy postępowanie spadkowe zostało przeprowadzone, jest w trakcie lub nie rozpoczęło się.`,
            `Zaznacz <b>Tak</b> jeżeli:<br>
            <br>
            - odziedziczyłeś/łaś całość lub część jakiegoś dzieła sztuki,<br>
            - nabyłeś/łaś dzieło sztuki na podstawie umowy kupna/sprzedaży,<br>
            - zostałeś/łaś obdarowany dziełem sztuki.`,
            `Zaznacz <b>Tak</b>, jeżeli:<br>
            <br>
            - jesteś wpisany w KRS jako właściciel jakiejkolwiek liczby udziałów w dowolnej spółce prawa handlowego (spółka akcyjna, spółka z o.o.).<br>`,
            `Zaznacz <b>Tak</b>, jeżeli:<br>
            <br>
            - jesteś właścicielem akcji w niepublicznej spółce akcyjnej<br>
            lub <br>
            - posiadasz akcje kupione na giełdzie, które znajdują się na należącym do Ciebie dowolnym rachunku maklerskim/inwestycyjnym.`,
            `Zaznacz <b>Tak</b>, jeżeli:<br>
            <br>
            - jakakolwiek instytucja finansowa (bank, towarzystwo ubezpieczeń, dom maklerski, towarzystwo funduszy inwestycyjnych) prowadzi rejestr jednostek uczestnictwa funduszy inwestycyjnych, którego jesteś właścicielem i na którym znajduje się jakakolwiek liczba jednostek uczestnictwa funduszy inwestycyjnych.`,
            `Zaznacz <b>Tak</b>, jeżeli jesteś właścicielem np.:<br>
            <br>
            - wartościowych kolekcji (np. znaczków, monet),<br>
            - zabytkowych mebli lub bardzo drogich mebli,<br>
            - roweru elektrycznego,<br>
            - innego majątku o znacznej wartości.`
        ],
    },
    {
        id: 22,
        text: [
            `Opisz nieruchomość (np.: działka, dom, mieszkanie). Jeżeli jesteś właścicielem wpisz <b>właściciel</b>. Jeżeli jesteś wpółwłaścicielem wpisz <b>współwłaściciel</b> i jeśli jest to możliwe określ faktyczną wielkość udziału np. 1/2. Podaj numer księgi wieczystej prowadzonej dla tej nieruchomości.<br>
            Przykład: <b>dom, współwłaściciel, udział 1/2, nr KW: AA1A/00011122/0</b>`,
            `Podaj markę, model oraz rocznik pojazdu np.: <b>Audi A4, 1999r.</b>`,
            `Podaj markę, model oraz rocznik pojazdu np.: <b>Yamaha 1100, 2002r.</b>`,
            `Podaj markę, model oraz rocznik pojazdu np.: <b>Romet Cadet, 1986r.</b>`,
            `Podaj markę, model oraz rocznik pojazdu np.: <b>Piaggio Vespa, 1999r.</b>`,
            `Podaj co wchodzi w skład masy spadkowej (np.: działka, samochód, biżuteria) oraz jakiej części masy spadkowej jesteś spadkobiercą (np.: 1/4)<br>
            Przykład: <b>mieszkanie, rower, 15 000 zł na kontach, udział w spadku: 1/6</b>`,
            `Opisz dzieło sztuki, np.: <b>Obraz Piotra Malczewskiego</b>`,
            `Podaj nazwę firmy, której udziały posiadasz oraz liczbę tych udziałów np.: <b>Przykład Sp. z o.o., posiadam 50 udziałów ze 100</b>`,
            `Podaj nazwę spółki akcyjnej oraz ilość akcji tej spółki, które posiadasz, np.: <b>Orlen S.A., jestem właścicielem 49 akcji</b>`,
            `Podaj nazwę funduszu inwestycyjnego oraz liczbę jednostek uczestnictwa które posiadasz, np.: <b>FIO PKO Akcji Polskich Plus, jestem właścicielem 12 jednostek uczestnictwa</b>`,
            `Opisz składnik majątku, np. <b>Kolekcja znaczków</b>`
        ],
    },
    {
        id: 23,
        text: [
            `Wybierz <b>Tak</b>, jeżeli:<br>
            <br>
            - w księdze wieczystej nieruchomości w Dziale III jest wpisane ostrzeżenie o egzekucji z nieruchomości lub w Dziale IV jest wpisana jakakolwiek hipoteka.`,
            `Wybierz <b>Tak</b>, jeżeli:<br>
            <br>
            - pojazd jest wpisany w rejestrze zastawów,<br>
            lub<br>
            - komornik sądowy wydał postanowienie o zabezpieczeniu.`,
            `Wybierz <b>Tak</b>, jeżeli:<br>
            <br>
            - pojazd jest wpisany w rejestrze zastawów,<br>
            lub<br>
            - komornik sądowy wydał postanowienie o zabezpieczeniu.`,
            `Wybierz <b>Tak</b>, jeżeli:<br>
            <br>
            - pojazd jest wpisany w rejestrze zastawów,<br>
            lub<br>
            - komornik sądowy wydał postanowienie o zabezpieczeniu.`,
            `Wybierz <b>Tak</b>, jeżeli:<br>
            <br>
            - pojazd jest wpisany w rejestrze zastawów,<br>
            lub<br>
            - komornik sądowy wydał postanowienie o zabezpieczeniu.`,
            `Wybierz <b>Nie</b>, jeżeli nie posiadasz takiej wiedzy.<br>`,
            `Wybierz <b>Tak</b>, jeżeli:<br>
            <br>
            - składnik majątku jest wpisany w rejestrze zastawów,<br>
            lub<br>
            - komornik sądowy wydał postanowienie o zabezpieczeniu.`,
            `Wybierz <b>Tak</b>, jeżeli:<br>
            <br>
            - składnik majątku jest wpisany w rejestrze zastawów,<br>
            lub<br>
            - komornik sądowy wydał postanowienie o zabezpieczeniu.`,
            `Wybierz <b>Tak</b>, jeżeli:<br>
            <br>
            - składnik majątku jest wpisany w rejestrze zastawów,<br>
            lub<br>
            - komornik sądowy wydał postanowienie o zabezpieczeniu.`,
            `Wybierz <b>Tak</b>, jeżeli:<br>
            <br>
            - składnik majątku jest wpisany w rejestrze zastawów,<br>
            lub<br>
            - komornik sądowy wydał postanowienie o zabezpieczeniu.`,
            `Wybierz <b>Tak</b>, jeżeli:<br>
            <br>
            - składnik majątku jest wpisany w rejestrze zastawów,<br>
            lub<br>
            - komornik sądowy wydał postanowienie o zabezpieczeniu.`,
        ],
    },
    {
        id: 24,
        text: [
            `Podaj datę wpisania hipoteki (z Działu IV księgi wieczystej) lub datę wpisania ostrzeżenie o egzekucji z nieruchomości (z Działu III księgi wieczystej).<br>
            Dostęp do aktualnej treści księgi wieczystej, po wpisaniu jej numeru, znajdziesz 
            <a target="_blank" style="color: var(--p-primary-500);text-decoration:underline;" href="https://przegladarka-ekw.ms.gov.pl/eukw_prz/KsiegiWieczyste/wyszukiwanieKW?komunikaty=true&kontakt=true&okienkoSerwisowe=false">tutaj</a>.`,
            `Wpisz: <br>
            <br>
            - datę wpisania do rejestru zastawów<br>
            lub<br>
            - datę wydania postanowienia o zabezpieczeniu przez komornika sądowego.`,
            `Wpisz: <br>
            <br>
            - datę wpisania do rejestru zastawów<br>
            lub<br>
            - datę wydania postanowienia o zabezpieczeniu przez komornika sądowego.`,
            `Wpisz: <br>
            <br>
            - datę wpisania do rejestru zastawów<br>
            lub<br>
            - datę wydania postanowienia o zabezpieczeniu przez komornika sądowego.`,
            `Wpisz: <br>
            <br>
            - datę wpisania do rejestru zastawów<br>
            lub<br>
            - datę wydania postanowienia o zabezpieczeniu przez komornika sądowego.`,
            `Wybierz datę ustanowienia zabezpieczenia (np.: ustanowienia hipoteki, wpisu do rejestru zastawów, zajęcia/zabezpieczenia przez komornika sądowego).`,
            `Wpisz: <br>
            <br>
            - datę wpisania do rejestru zastawów<br>
            lub<br>
            - datę wydania postanowienia o zabezpieczeniu przez komornika sądowego.`,
            `Wpisz: <br>
            <br>
            - datę wpisania do rejestru zastawów<br>
            lub<br>
            - datę wydania postanowienia o zabezpieczeniu przez komornika sądowego.`,
            `Wpisz: <br>
            <br>
            - datę wpisania do rejestru zastawów<br>
            lub<br>
            - datę wydania postanowienia o zabezpieczeniu przez komornika sądowego.`,
            `Wpisz: <br>
            <br>
            - datę wpisania do rejestru zastawów<br>
            lub<br>
            - datę wydania postanowienia o zabezpieczeniu przez komornika sądowego.`,
            `Wpisz: <br>
            <br>
            - datę wpisania do rejestru zastawów<br>
            lub<br>
            - datę wydania postanowienia o zabezpieczeniu przez komornika sądowego.`,
        ],
    },
    {
        id: 25,
        text: `Jeśli chcesz zmienić walutę wyceny to wpisz ją lub wybierz z listy.`,
    },
    {
        id: 26,
        text: [
            `Podaj własną, przybliżoną wycenę majątku. Jeśli nie jesteś rzeczoznawcą majątkowym, nie przejmuj się, że podana przez Ciebie wycena może odbiegać od wyceny wykonanej przez rzeczoznawcę, czyli jeśli wydaje Ci się, że wartość nieruchomości lub Twojego udziału (jeśli jesteś współwłaścicielem) wynosi np. około 200 000 zł to wpisz to co Ci się wydaje.`,
            `Podaj własną, przybliżoną wycenę majątku. Jeśli nie jesteś rzeczoznawcą majątkowym, nie przejmuj się, że podana przez Ciebie wycena może odbiegać od wyceny wykonanej przez rzeczoznawcę, czyli jeśli wydaje Ci się, że wartość pojazdu lub Twojego udziału (jeśli jesteś współwłaścicielem) wynosi np. około 2 000 zł to wpisz to co Ci się wydaje.`,
            `Podaj własną, przybliżoną wycenę majątku. Jeśli nie jesteś rzeczoznawcą majątkowym, nie przejmuj się, że podana przez Ciebie wycena może odbiegać od wyceny wykonanej przez rzeczoznawcę, czyli jeśli wydaje Ci się, że wartość pojazdu lub Twojego udziału (jeśli jesteś współwłaścicielem) wynosi np. około 2 000 zł to wpisz to co Ci się wydaje.`,
            `Podaj własną, przybliżoną wycenę majątku. Jeśli nie jesteś rzeczoznawcą majątkowym, nie przejmuj się, że podana przez Ciebie wycena może odbiegać od wyceny wykonanej przez rzeczoznawcę, czyli jeśli wydaje Ci się, że wartość pojazdu lub Twojego udziału (jeśli jesteś współwłaścicielem) wynosi np. około 2 000 zł to wpisz to co Ci się wydaje.`,
            `Podaj własną, przybliżoną wycenę majątku. Jeśli nie jesteś rzeczoznawcą majątkowym, nie przejmuj się, że podana przez Ciebie wycena może odbiegać od wyceny wykonanej przez rzeczoznawcę, czyli jeśli wydaje Ci się, że wartość pojazdu lub Twojego udziału (jeśli jesteś współwłaścicielem) wynosi np. około 2 000 zł to wpisz to co Ci się wydaje.`,
            `Podaj przybliżoną wycenę Twojego udziału w spadku.`,
            `Podaj własną, przybliżoną wycenę składnika majątku.`,
            `Podaj własną, przybliżoną wycenę składnika majątku.`,
            `Podaj własną, przybliżoną wycenę składnika majątku.`,
            `Podaj własną, przybliżoną wycenę składnika majątku.`,
            `Podaj własną, przybliżoną wycenę składnika majątku.`,
        ],
    },
    {
        id: 27,
        text: [
            `W kolejnych krokach podaj adres położenia nieruchomości.`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku.`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku.`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku.`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku.`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku.`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku.`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku.`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku (W przypadku akcji spółki publicznej, podaj adres biura maklerskiego, w którym masz te akcje na rachunku maklerskim).`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku.`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku.`,
        ],
    },
    {
        id: 28,
        text: [
            `W kolejnych krokach podaj adres położenia nieruchomości.`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku (W przypadku akcji spółki publicznej, podaj adres biura maklerskiego, w którym masz te akcje na rachunku maklerskim).`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
        ],
    },
    {
        id: 29,
        text: [
            `W kolejnych krokach podaj adres położenia nieruchomości.`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku (W przypadku akcji spółki publicznej, podaj adres biura maklerskiego, w którym masz te akcje na rachunku maklerskim).`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
        ],
    },
    {
        id: 30,
        text: [
            `W kolejnych krokach podaj adres położenia nieruchomości.`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku (W przypadku akcji spółki publicznej, podaj adres biura maklerskiego, w którym masz te akcje na rachunku maklerskim).`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
        ]
    },
    {
        id: 31,
        text: [
            `W kolejnych krokach podaj adres położenia nieruchomości.`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku (W przypadku akcji spółki publicznej, podaj adres biura maklerskiego, w którym masz te akcje na rachunku maklerskim).`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
            `Podaj adres miejsca, w którym znajduje się składnik majątku`,
        ],
    },
    {
        id: 32,
        text: [
            `W przypadku położenia nieruchomości za granicą, kod pocztowy można wpisać za pomocą cyfr i wielkich liter.`,
            `W przypadku gdy miejsce, w którym znajduje się pojazd, jest za granicą, kod pocztowy można wpisać za pomocą cyfr i wielkich liter.`,
            `W przypadku gdy miejsce, w którym znajduje się pojazd, jest za granicą, kod pocztowy można wpisać za pomocą cyfr i wielkich liter.`,
            `W przypadku gdy miejsce, w którym znajduje się pojazd, jest za granicą, kod pocztowy można wpisać za pomocą cyfr i wielkich liter.`,
            `W przypadku gdy miejsce, w którym znajduje się pojazd, jest za granicą, kod pocztowy można wpisać za pomocą cyfr i wielkich liter.`,
            `W przypadku gdy spadek znajduje się za granicą, kod pocztowy można wpisać za pomocą cyfr i wielkich liter.`,
            `W przypadku gdy składnik majątku znajduje się za granicą, kod pocztowy można wpisać za pomocą cyfr i wielkich liter.`,
            `W przypadku gdy składnik majątku znajduje się za granicą, kod pocztowy można wpisać za pomocą cyfr i wielkich liter.`,
            `W przypadku gdy składnik majątku znajduje się za granicą, kod pocztowy można wpisać za pomocą cyfr i wielkich liter. (W przypadku akcji spółki publicznej, podaj adres biura maklerskiego, w którym masz te akcje na rachunku maklerskim).`,
            `W przypadku gdy składnik majątku znajduje się za granicą, kod pocztowy można wpisać za pomocą cyfr i wielkich liter.`,
            `W przypadku gdy składnik majątku znajduje się za granicą, kod pocztowy można wpisać za pomocą cyfr i wielkich liter.`,
        ],
    },
    {
        id: 33,
        text: "endid",
    },
    {
        id: 34,
        text: `auto`,
    },
    {
        id: 35,
        content: [
            {
                type: "text",
                text: `Oto podgląd części wniosku, który wypełniłeś/łaś:<br><br>`
            },
            {
                type: "formView",

            },
            {
                type: "text",
                text: `<br>Możesz dokończyć wniosek po opłaceniu subskrypcji.`
            },
            {
                type: "btn",
                btnTitle: "Wróć do dashboardu",
                documentType: "link",
                link: "/dashboard"
            },
        ]
    },
    {
        id: 36,
        text: `Zaznacz <b>Tak</b> jeżeli jakakolwiek osoba fizyczna lub inny podmiot (firma, stowarzyszenie, etc.) jest Tobie winna pieniądze lub inne prawa na podstawie posiadanych przez Ciebie: umowy, wyroku sądowego lub innego dokumentu.
            <br>
            Zaznacz <b>Nie</b> jeżeli nie masz żadnych dłużników lub nie posiadasz dokumentów na udowodnienie ich długu.`,
    },
    {
        id: 37,
        text: "Wpisz imię i nazwisko swojego dłużnika (jeśli jest to osoba fizyczna) lub pełną nazwę innego podmiotu, który jest Twoim dłużnikiem (jeśli jest to firma, stowarzyszenie, itp).",
    },
    {
        id: 38,
        text: "Podaj kraj, w którym znajduje się siedziba Twojego dłużnika.",
    },
    {
        id: 39,
        text: "Podaj miejscowość, w którym znajduje się siedziba Twojego dłużnika.",
    },
    {
        id: 40,
        text: "Podaj ulicę, na której znajduje się siedziba Twojego dłużnika.",
    },
    {
        id: 41,
        text: "Podaj numer domu/budynku, w którym znajduje się siedziba Twojego dłużnika.",
    },
    {
        id: 42,
        text: "Podaj numer lokalu/mieszkania, w którym znajduje się siedziba Twojego dłużnika.",
    },
    {
        id: 43,
        text: "Podaj kod pocztowy, siedziby Twojego dłużnika.",
    },
    {
        id: 44,
        text: `Jeśli chcesz zmienić walutę wierzytelności to wpisz ją lub wybierz z listy.`,
    },
    {
        id: 45,
        text: `Podaj wysokość wierzytelności aktualnego długu na podstawie posiadanych dokumentów.`,
    },
    {
        id: 46,
        text: `Podaj termin zapłaty długu na podstawie posiadanych dokumentów.`,
    },
    {
        id: 47,
        text: "Podaj nazwę dokumentu stwierdzającego istnienie długu wobec Ciebie.",
    },
    {
        id: 48,
        text: "Podaj datę dokumentu stwierdzającego istnienie długu wobec Ciebie.",
    },
    {
        id: 49,
        text: "Wykonaj 2 kopie dokumentu stwierdzającego dług wobec Ciebie.",
    },
    {
        id: 50,
        text: "endid",
    },
    {
        id: 51,
        content: [
            {
                type: "text",
                text: `W kolejnych krokach wygenerujesz dokumenty lub formularze, które po wypełnieniu zgodnie z instrukcjami i podpisaniu <b>wyślesz listami poleconymi</b> do wymienionych w tych dokumentach instytucji. Faktyczny czas oczekiwania na odpowiedzi waha się w przedziale od dwóch do nawet siedmiu tygodni. W przypadku przekroczenia siedmiotygodniowego czasu oczekiwania należy zadzwonić do danej instytucji/podmiotu z zapytaniem czy odpowiedź została już wysłana lub kiedy zostanie wysłana.
                            `
            },
            {
                type: "btn",
                btnTitle: "Pobierz formularz KRD",
                documentType: "static",
                document: "/Auron/KRD.pdf"
            },
            {
                type: "text",
                text: `Wydrukuj pobrany formularz.<br>
                            UWAGA: Nie pobieraj raportu KRD poprzez internet, gdyż będziesz musiał wnieść opłatę.<br>
                            Za pomocą wydrukowanego formularza otrzymasz stosowny raport bezpłatnie (jeśli nie pobierałeś/łaś już raportu KRD w ciągu ostatniego pół roku).<br>
                            <br>
                            Wypełnij formularz w następujący sposób:<br>
                            - <b>Nie zaznaczaj</b> żadnego kwadracika (właściwy jest już zaznaczony)<br>
                            - <b>Nie podawaj</b> swojego adresu email (raport KRD chcesz otrzymać w formie papierowej)<br>
                            - wypełnij formularz swoimi danymi<br>
                            - w pierwszej dużej ramce, na dole podaj adres korespondencyjny, na który chcesz otrzymać raport<br>
                            - <b>Podpisz</b> wypełniony formularz<br>
                            - Przygotuj ksero obu stron dowodu osobistego jako załącznik<br><br>
                            <b>UWAGA</b> na przygotowanym ksero dowodu osobistego wykreśl np. zygzakiem wszystkie dane <b>oprócz 
                            <br><i>(tylko te dane pozostaw widoczne)</i></b>: <br>
                            - <b>imię i nazwisko</b>, <br>
                            - <b>PESEL</b>, <br>
                            - <b>data ważności dokumentu</b>,<br>
                            ... tak, wykreśl nawet zdjęcie oraz numer i serię dowodu osobistego...<br>
                            <br>
                            Wypełniony i podpisany formularz wraz z przygotowanym ksero dowodu osobistego wyślij listem poleconym do: <br>
                            <b>Krajowy Rejestr Długów Biuro Informacji Gospodarczej S.A.</b><br>
                            <b>ul. Danuty Siedzikówny 12<br>
                            51-214 Wrocław</b>`
            }
        ],
    },
    {
        id: 52,
        content: [
            {
                type: "btn",
                btnTitle: "Pobierz formularz BIK",
                documentType: "static",
                document: "/Auron/BIK.pdf"
            },
            {
                type: "text",
                text: `Wydrukuj pobrany formularz.<br>
            UWAGA: Nie pobieraj informacji BIK poprzez internet, gdyż będziesz musiał wnieść opłatę.<br>
            Za pomocą wydrukowanego formularza otrzymasz stosowną informację bezpłatnie (jeśli nie pobierałeś/łaś już raportu BIK w ciągu ostatniego pół roku).<br>
            <br>
            Wypełnij formularz w następujący sposób:<br>
            - <b>Nie zaznaczaj</b> żadnego kwadracika (właściwy jest już zaznaczony)<br>
            - wypełnij formularz swoimi danymi<br>
            - <b>Podpisz</b> wypełniony formularz<br>
            - Przygotuj ksero obu stron dowodu osobistego jako załącznik<br>
            <br>
            <b>UWAGA</b> na przygotowanym ksero dowodu osobistego <b>niczego nie wykreślaj</b> <br>
            <br>
            Wypełniony i podpisany formularz wraz z przygotowanym ksero dowodu osobistego wyślij listem poleconym do: <br>
            <b>Biuro Informacji Kredytowej S.A.</b><br>
            <b>ul. Zygmunta Modzelewskiego 77a<br>
            02-679 Warszawa</b>`
            }
        ],
    },
    {
        id: 53,
        content: [
            {
                type: "btn",
                btnTitle: "Pobierz formularz ERIF",
                documentType: "static",
                document: "/Auron/ERIF.pdf"
            },
            {
                type: "text",
                text: `Wydrukuj pobrany formularz.<br>
            UWAGA: Za pomocą wydrukowanego formularza otrzymasz stosowny raport bezpłatnie (jeśli nie pobierałeś/łaś już raportu ERIF w ciągu ostatniego pół roku).<br>
            <br>
            Wypełnij formularz zgodnie z instrukcją zawartą w formularzu.<br>
            - <b>Podpisz</b> wypełniony formularz<br>
            - Przygotuj ksero obu stron dowodu osobistego jako załącznik<br><br>
            <b>UWAGA</b> na przygotowanym ksero dowodu osobistego wykreśl np. zygzakiem wszystkie dane <b>oprócz 
            <br><i>(tylko te dane pozostaw widoczne)</i></b>: <br>
            - <b>imię i nazwisko</b>, <br>
            - <b>PESEL</b>, <br>
            - <b>data ważności dokumentu</b>,<br>
            ... tak, znowu wykreśl nawet zdjęcie oraz numer i serię dowodu osobistego...<br>
            <br>
            Wypełniony i podpisany formularz wraz z przygotowanym ksero dowodu osobistego wyślij listem poleconym do: <br>
            <b>ERIF Biuro Informacji Gospodarczej S.A.</b><br>
            <b>Al. Jerozolimskie 100<br>
            00-807 Warszawa</b>`
            }
        ],
    },
    {
        id: 54,
        text: `Zaznacz <b>Tak</b> jeżeli posiadasz korespondencję z Urzędu Skarbowego wzywającą do spłaty zaległości lub wydaje Ci się, że możesz mieć jakiekolwiek zaległości podatkowe (np. VAT, niedopłata do zeznania rocznego, itp.)`,
    },
    {
        id: 55,
        text: `Pobrany wniosek możesz wypełnić na komputerze, a następnie wydrukować lub możesz wypełnić go ręcznie po wydrukowaniu. <br>
        Do uzupełnionego i podpisanego wniosku należy załączyć potwierdzenie dokonania opłaty skarbowej w wysokości 21 zł.<br>
        Tak przygotowany wniosek wraz z załącznikiem należy wysłać listem poleconym do Urzędu Skarbowego wymienionego we wniosku.<br>
        <b>UWAGA: Wniosek składany przez e-Urząd Skarbowy jest bezpłatny.</b><br>`,
        btnTitle: "Pobierz wniosek do US",
        documentType: "static",
        document: "/Auron/US wniosek-o-wydanie-zaświadczenia-o-niezaleganiu-w-podatkach-lub-stwierdzającego-stan-zaległości.pdf"
    },
    {
        id: 56,
        text: `Zaznacz <b>Tak</b> jeżeli posiadasz jakiekolwiek zaległości w ZUS np.: z tytułu prowadzonej działalności gospodarczej lub nienależnie pobranych świadczeń.`,
    },
    {
        id: 57,
        text: `Pobierz, wydrukuj i wypełnij wniosek zgodnie z instrukcją wypełniania zawartą we wniosku.<br>
        Wypełniony i podpisany wniosek wyślij listem poleconym do dowolnego oddziału ZUS.`,
        btnTitle: "Pobierz wniosek do ZUS",
        documentType: "static",
        document: "/Auron/ZUS Wniosek o sumę zobowiązań RD3.pdf"
    },
    {
        id: 58,
        text: `Zaznacz <b>Tak</b> jeżeli otrzymujesz jakąkolwiek korespondencję z firm, które nie były Twoimi wierzycielami lub firmy te usiłują kontaktować się z Tobą telefonicznie. Mogą to być takie firmy jak np.: Best, Kruk/Prokura, Ultimo, Koksztys, Eos, Hoist, Pra Group, Raport/Axfina, Kredyt Inkaso, Intrum Justitia, Asekuracja, Aasa Polska, Presco Investments, Debito NSFIZ.`,
    },
    {
        id: 59,
        text: `Wybierz firmę windykacyjną z listy. <br>
        Jeżeli na liście nie ma firmy, która się z Tobą kontaktuje, to wybierz <b>Inna Firma</b>.`,
    },
    {
        id: 60,
        text: `Wpisz nazwę firmy windykacyjnej. Jeżeli firma kontaktowała się z Tobą wyłącznie telefonicznie i nie znasz jej nazwy ani adresu, to zadzwoń do nich prosząc o podanie nazwy firmy i adresu. Na wszelkie pytania drugiej strony odpowiedz, że prosisz o przesłanie żądań na piśmie i że też pisemnie na nie odpowiesz, dlatego chcesz znać nazwę firmy i jej adres.`,
    },
    {
        id: 61,
        text: `Podaj miejscowość, w której znajduje się siedziba firmy windykacyjnej.`,
    },
    {
        id: 62,
        text: `Podaj ulicę, na której znajduje się siedziba firmy windykacyjnej.`,
    },
    {
        id: 63,
        text: `Podaj numer budynku, w którym znajduje się siedziba firmy windykacyjnej.`,
    },
    {
        id: 64,
        text: "Podaj numer lokalu, siedziby firmy windykacyjnej.",
    },
    {
        id: 65,
        text: `Podaj kod pocztowy, siedziby firmy windykacyjnej.`,
    },
    {
        id: 66,
        text: `Pobierz, wydrukuj i <b>podpisz</b> pismo do firmy windykacyjnej.<br>
        Podpisany dokument wyślij listem poleconym na adres firmy windykacyjnej wskazany w dokumencie.`,
        btnTitle: "Generuj i pobierz pismo do firmy windykacyjnej",
        documentType: "windycation"
    },
    {
        id: 67,
        text: `Zweryfikuj informacje podane w wygenerowanym piśmie, czy zgadzają się z danymi rzeczywistymi. Jeśli Twoje dane <b>osobowe</b> są niepoprawne to skontaktuj się z nami pod numerem <b>534 123 123</b>.`
    },
    {
        id: 68,
        text: `endid`,
    },
    {
        id: 69,
        text: `Wybierz <b>Tak</b> jeżeli:<br>
        <br>
        - kiedykolwiek otrzymywałeś/łaś jakąkolwiek korespondencję od komorników sądowych,<br>
        lub<br>
        - masz realizowane zajęcie komornicze ze swojego wynagrodzenia/emerytury/renty,<br>
        lub<br>
        - na Twoich kontach bankowych są blokady komornicze.<br>`,
    },
    {
        id: 70,
        text: `Wśród posiadanych dokumentów, znajdź po jednym dokumencie od każdego komornika jaki kiedykolwiek się z Tobą kontaktował.<br>
        Jeżeli masz blokady komornicze na koncie/kontach, w oddziale każdego banku, w którym posiadasz konto, zażądaj listy komorników, którzy blokują Twoje konto/konta, wraz z adresami do nich.<br>
        Jeżeli pobierasz świadczenie z ZUS (emerytura/renta/inne) udaj się do najbliższego oddziału ZUS i uzyskaj listę komorników, którzy zajmują Twoje świadczenie, wraz z ich adresami.<br>
        UWAGA: Na obecnym etapie <b>nie interesują Cię ani kwoty ani inne szczegóły prowadzonych postępowań, a jedynie dane personalne oraz adresy komorników</b>.<br>
        Na podstawie uzyskanych informacji przygotuj listę wszystkich komorników wraz z ich adresami i przejdź do kolejnego etapu.<br>`,
    },
    {
        id: 71,
        mode: 'hide'
    },
    {
        id: 72,
        text: `Wpisz imię komornika. Nie wpisuj nigdzie pełnej nazwy kancelarii komorniczej, a jedynie imię (w tym oknie) oraz nazwisko komornika (w kolejnym oknie).`,
    },
    {
        id: 73,
        text: `Wpisz nazwisko komornika. Nie wpisuj nigdzie pełnej nazwy kancelarii komorniczej.`,
    },
    {
        id: 74,
        function: generateInspectorQuery,
        text: `Wpisz miejscowość siedziby komornika. Aktualny adres jego siedziby znajdziesz <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://google.com/search?q={{ query }}" target="_blank"><b>tutaj</b></a>. Porównaj czy jest taki sam jak w posiadanym od niego dokumencie. Jeżeli posiadany dokument jest "stary" to siedziba kancelarii komorniczej mogła się zmienić. W takim wypadku wpisz adres, który odszukałeś/łaś <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://google.com/search?q={{ query }}" target="_blank"><b>tutaj</b></a>.`,
    },
    {
        id: 75,
        function: generateInspectorQuery,
        text: `Wpisz ulicę siedziby komornika. Jego aktualny adres znajdziesz <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://google.com/search?q={{ query }}" target="_blank"><b>tutaj</b></a>.`,
    },
    {
        id: 76,
        function: generateInspectorQuery,
        text: `Wpisz numer budynku siedziby komornika. Jego aktualny adres znajdziesz <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://google.com/search?q={{ query }}" target="_blank"><b>tutaj</b></a>.`,
    },
    {
        id: 77,
        function: generateInspectorQuery,
        text: `Wpisz numer lokalu siedziby komornika. Jego aktualny adres znajdziesz <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://google.com/search?q={{ query }}" target="_blank"><b>tutaj</b></a>.`,
    },
    {
        id: 78,
        function: generateInspectorQuery,
        text: `Wpisz kod pocztowy siedziby komornika. Jego aktualny adres znajdziesz <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://google.com/search?q={{ query }}" target="_blank"><b>tutaj</b></a>.`,
    },
    {
        id: 79,
        text: `Wydrukuj pobrany dokument. Podpisz go i wyślij listem poleconym do komornika na adres wskazany w dokumencie.`,
        documentType: "inspector",
        btnTitle: "Generuj i pobierz pismo do komornika"
    },
    {
        id: 80,
        function: generateInspectorQuery,
        text: `Sprawdź czy imię i nazwisko komornika jest identyczne jak w posiadanym od niego piśmie. Sprawdź <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://google.com/search?q={{ query }}" target="_blank"><b>tutaj</b></a> czy dodany adres jest aktualny i prawidłowy. Jeśli Twoje dane <b>osobowe</b> są niepoprawne to skontaktuj się z nami pod numerem <b>534 123 123</b>.`
    },
    {
        id: 81,
        text: `endid`,
    },
    {
        id: 82,
        text: `checklist`,
    },
    {
        id: 83,
        text: `Otwórz i przejrzyj raport KRD.`,
    },
    {
        id: 84,
        content: [
            [
                {
                    type: "text",
                    text: `Zaznacz <b>Tak</b> jeżeli raport KRD zawiera strony, na których jesteś wymieniony jako Dłużnik obok podany jest Wierzyciel, a pod spodem jest opisane Zobowiązanie.<br>
                            Przykład takiej strony zobacz tutaj:<br>`
                },
                {
                    type: "image",
                    image: "/Auron/KRD1.png"
                },
                {
                    type: "text",
                    text: `UWAGA: <b>Nie</b> są Twoimi zobowiązaniami informacje oznaczone symbolem fairPay.pl i opisane jako <b>Pozytywna informacja gospodarcza o zobowiązaniu zapłaconym do 7-mego dnia po terminie wymagalności</b> i nie wpisujemy ich do wniosku.<br>
                            Przykład takiej strony zobacz tutaj:<br> `
                },
                {
                    type: "image",
                    image: "/Auron/KRD2.png"
                },
            ],
            [
                {
                    type: "text",
                    text: `Zaznacz <b>Tak</b> jeżeli raport KRD zawiera strony, na których jesteś wymieniony jako Dłużnik obok podany jest Wierzyciel, a pod spodem jest opisane Zobowiązanie, którego jeszcze nie wpisałeś/łaś do wniosku.<br>
                            Przykład takiej strony zobacz tutaj:<br>`
                },
                {
                    type: "image",
                    image: "/Auron/KRD1.png"
                },
                {
                    type: "text",
                    text: `UUWAGA: <b>Nie</b> są Twoimi zobowiązaniami informacje oznaczone symbolem fairPay.pl i opisane jako <b>Pozytywna informacja gospodarcza o zobowiązaniu zapłaconym do 7-mego dnia po terminie wymagalności</b> i nie wpisujemy ich do wniosku.<br>
                            Przykład takiej strony zobacz tutaj:<br> `
                },
                {
                    type: "image",
                    image: "/Auron/KRD2.png"
                },
            ]
        ]
    },
    {
        id: 85,
        content: [
            {
                type: "text",
                text: `Znajdź na stronie opis <b>Informacja gospodarcza o niezapłaconym zobowiązaniu</b>. Pod spodem z prawej strony masz opis <b>Wierzyciel</b>. Przepisz jego pełną nazwę <b>bez NIP</b>.<br>
                    Przykładową stronę z nazwą wierzyciela znajdziesz tutaj (nazwa wierzyciela jest zaznaczona elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/KRD3.png"
            },
        ]
    },
    {
        id: 86,
        content: [
            {
                type: "text",
                text: `Podaj kraj siedziby wierzyciela wynikający z jego adresu.<br>
                    Przykładową stronę z adresem wierzyciela znajdziesz tutaj (adres wierzyciela jest zaznaczony elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/KRD4.png"
            },
        ]
    },
    {
        id: 87,
        content: [
            {
                type: "text",
                text: `Podaj miejscowość siedziby wierzyciela wynikającą z jego adresu.<br>
                    Przykładową stronę z adresem wierzyciela znajdziesz tutaj (adres wierzyciela jest zaznaczony elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/KRD4.png"
            },
        ]
    },
    {
        id: 88,
        content: [
            {
                type: "text",
                text: `Podaj ulicę siedziby wierzyciela wynikającą z jego adresu.<br>
                    Przykładową stronę z adresem wierzyciela znajdziesz tutaj (adres wierzyciela jest zaznaczony elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/KRD4.png"
            },
        ]
    },
    {
        id: 89,
        content: [
            {
                type: "text",
                text: `Podaj numer budynku siedziby wierzyciela wynikający z jego adresu.<br>
                    Przykładową stronę z adresem wierzyciela znajdziesz tutaj (adres wierzyciela jest zaznaczony elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/KRD4.png"
            },
        ]
    },
    {
        id: 90,
        content: [
            {
                type: "text",
                text: `Podaj numer lokalu siedziby wierzyciela wynikający z jego adresu.<br>
                    Przykładową stronę z adresem wierzyciela znajdziesz tutaj (adres wierzyciela jest zaznaczony elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/KRD4.png"
            },
        ]
    },
    {
        id: 91,
        content: [
            {
                type: "text",
                text: `Podaj kod pocztowy siedziby wierzyciela wynikający z jego adresu.<br>
                    Przykładową stronę z adresem wierzyciela znajdziesz tutaj (adres wierzyciela jest zaznaczony elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/KRD4.png"
            },
        ]
    },
    {
        id: 92,
        text: `Jeśli chcesz zmienić walutę wierzytelności to wpisz ją lub wybierz z listy.`,
    },
    {
        id: 93,
        content: [
            {
                type: "text",
                text: `Wpisz kwotę wierzytelności podaną w ramce opisanej <b>Zobowiązanie</b> w linijce <b>SUMA</b> w kolumnie <b>Kwota zadłużenia</b>.<br>
                        Przykładową stronę z kwotą wierzytelności znajdziesz tutaj (kwota wierzytelności jest zaznaczona elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/KRD5.png"
            },
        ]
    },
    {
        id: 94,
        content: [
            {
                type: "text",
                text: `Wpisz termin zapłaty wskazany w ramce zobowiązanie w kolumnie <b>Termin wymagalności</b>. Podaj tylko datę bez wskazywania ilości dni opóźnienia po terminie zapłaty.<br>
                        Przykładową stronę ze wskazaniem terminu zapłaty znajdziesz tutaj (termin zapłaty jest zaznaczony elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/KRD6.png"
            },
        ]
    },
    {
        id: 95,
        text: `endid`,
    },
    {
        id: 96,
        content: [
            {
                type: "text",
                text: `Wpisz datę raportu KRD.<br>
                        Przykładową stronę gdzie znaleźć datę raportu zobacz tutaj (data raportu zaznaczona elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/KRD7.png"
            },
        ]
    },
    {
        id: 97,
        text: `Wykonaj 2 kopie raportu KRD. Będą one stanowiły załączniki do wniosku.`,
    },
    {
        id: 98,
        text: `auto`,
    },
    {
        id: 99,
        text: `Przygotuj dokumenty od komorników, otrzymane w odpowiedzi na Twoje pisma.`,
    },
    {
        id: 100,
        text: `Zaznacz <b>Tak</b> jeżeli w dokumencie od komornika jest wymieniony chociaż jeden wierzyciel.`,
    },
    {
        id: 101,
        text: `Wpisz pełną nazwę wierzyciela.`,
    },
    {
        id: 102,
        function: generateWindycationCompanyQuery,
        text: `Wpisz kraj wierzyciela, wynikający z jego adresu.<br>
        Jeżeli wierzyciel nie jest osobą fizyczną, a pismo komornika nie zawiera adresu wierzyciela to adres możesz znaleźć <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://google.com/search?q={{ query }}" target="_blank"><b>tutaj</b></a>.`,
    },
    {
        id: 103,
        function: generateWindycationCompanyQuery,
        text: `Wpisz miejscowość, wynikającą z jego adresu.<br>
        Jeżeli wierzyciel nie jest osobą fizyczną, a pismo komornika nie zawiera adresu wierzyciela to adres możesz znaleźć <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://google.com/search?q={{ query }}" target="_blank"><b>tutaj</b></a>.`,
    },
    {
        id: 104,
        function: generateWindycationCompanyQuery,
        text: `Wpisz ulicę, wynikającą z jego adresu.<br>
        Jeżeli wierzyciel nie jest osobą fizyczną, a pismo komornika nie zawiera adresu wierzyciela to adres możesz znaleźć <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://google.com/search?q={{ query }}" target="_blank"><b>tutaj</b></a>.`,
    },
    {
        id: 105,
        function: generateWindycationCompanyQuery,
        text: `Wpisz numer budynku, wynikający z jego adresu.<br>
        Jeżeli wierzyciel nie jest osobą fizyczną, a pismo komornika nie zawiera adresu wierzyciela to adres możesz znaleźć <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://google.com/search?q={{ query }}" target="_blank"><b>tutaj</b></a>.`,
    },
    {
        id: 106,
        function: generateWindycationCompanyQuery,
        text: `Wpisz numer lokalu, wynikający z jego adresu.<br>
        Jeżeli wierzyciel nie jest osobą fizyczną, a pismo komornika nie zawiera adresu wierzyciela to adres możesz znaleźć <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://google.com/search?q={{ query }}" target="_blank"><b>tutaj</b></a>.`,
    },
    {
        id: 107,
        function: generateWindycationCompanyQuery,
        text: `Wpisz kod pocztowy, wynikający z jego adresu.<br>
        Jeżeli wierzyciel nie jest osobą fizyczną, a pismo komornika nie zawiera adresu wierzyciela to adres możesz znaleźć <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://google.com/search?q={{ query }}" target="_blank"><b>tutaj</b></a>.`,
    },
    {
        id: 108,
        text: `Jeśli chcesz zmienić walutę wierzytelności to wpisz ją lub wybierz z listy.`,
    },
    {
        id: 109,
        text: `Wpisz kwotę wierzytelności podaną jako pozostała kwota do zapłaty lub przynajmniej sumę należności głównej i wskazanych odsetek od tej należności.`,
    },
    {
        id: 110,
        text: `Wpisz datę wydania nakazu zapłaty, lub datę nadania klauzuli wykonalności. Jeżeli komornik nie podał daty wydania nakazu zapłaty lub klauzuli wykonalności należy ustalić ją na podstawie innych dokumentów tego komornika dotyczących danego wierzyciela lub należy skontaktować się z komornikiem, aby podał tę datę.`,
    },
    {
        id: 111,
        text: `endid`,
    },
    {
        id: 112,
        text: `Podaj datę dokumentu otrzymanego od komornika.`,
    },
    {
        id: 113,
        text: `Wykonaj 2 kopie odpowiedzi otrzymanej od komornika. Będą one stanowiły załączniki do wniosku.`,
    },
    {
        id: 114,
        mode: 'hide'
    },
    {
        id: 115,
        text: `auto`,
    },
    {
        id: 116,
        text: `Przygotuj dokumenty od firm windykacyjnych, otrzymane w odpowiedzi na Twoje pisma.`,
    },
    {
        id: 117,
        text: [
            `Zaznacz <b>Tak</b> jeżeli w piśmie są wymienione jakiekolwiek wierzytelności wobec tej firmy czy też wobec innych podmiotów, na których zlecenie działa ta firma windykacyjna.`,
            `Zaznacz <b>Tak</b> jeżeli firma windykacyjna wskazuje siebie jako wierzyciela.`,
            `Zaznacz <b>Tak</b> jeżeli w piśmie firmy windykacyjnej są wskazani kolejni wierzyciele lub wierzytelności, których jeszcze nie wpisałeś/łaś do wniosku.`,
        ],
    },
    {
        id: 118,
        text: `Wpisz pełną nazwę wierzyciela podaną przez firmę windykacyjną.`,
    },
    {
        id: 119,
        function: generateWindycationCompanyQuery,
        text: `Wpisz kraj wierzyciela, wynikający z jego adresu.<br>
        Jeżeli wierzyciel nie jest osobą fizyczną, a pismo od firmy windykacyjnej nie zawiera adresu wierzyciela to adres możesz znaleźć <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://google.com/search?q={{ query }}" target="_blank"><b>tutaj</b></a>.`,
    },
    {
        id: 120,
        function: generateWindycationCompanyQuery,
        text: `Wpisz miejscowość, wynikającą z jego adresu.<br>
        Jeżeli wierzyciel nie jest osobą fizyczną, a pismo od firmy windykacyjnej nie zawiera adresu wierzyciela to adres możesz znaleźć <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://google.com/search?q={{ query }}" target="_blank"><b>tutaj</b></a>.`,
    },
    {
        id: 121,
        function: generateWindycationCompanyQuery,
        text: `Wpisz ulicę, wynikającą z jego adresu.<br>
        Jeżeli wierzyciel nie jest osobą fizyczną, a pismo od firmy windykacyjnej nie zawiera adresu wierzyciela to adres możesz znaleźć <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://google.com/search?q={{ query }}" target="_blank"><b>tutaj</b></a>.`,
    },
    {
        id: 122,
        function: generateWindycationCompanyQuery,
        text: `Wpisz numer budynku, wynikający z jego adresu.<br>
        Jeżeli wierzyciel nie jest osobą fizyczną, a pismo od firmy windykacyjnej nie zawiera adresu wierzyciela to adres możesz znaleźć <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://google.com/search?q={{ query }}" target="_blank"><b>tutaj</b></a>.`,
    },
    {
        id: 123,
        function: generateWindycationCompanyQuery,
        text: `Wpisz numer lokalu, wynikający z jego adresu.<br>
        Jeżeli wierzyciel nie jest osobą fizyczną, a pismo od firmy windykacyjnej nie zawiera adresu wierzyciela to adres możesz znaleźć <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://google.com/search?q={{ query }}" target="_blank"><b>tutaj</b></a>.`,
    },
    {
        id: 124,
        function: generateWindycationCompanyQuery,
        text: `Wpisz kod pocztowy, wynikający z jego adresu.<br>
        Jeżeli wierzyciel nie jest osobą fizyczną, a pismo od firmy windykacyjnej nie zawiera adresu wierzyciela to adres możesz znaleźć <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://google.com/search?q={{ query }}" target="_blank"><b>tutaj</b></a>.`,
    },
    {
        id: 125,
        text: `Jeśli chcesz zmienić walutę wierzytelności to wpisz ją lub wybierz z listy.`,
    },
    {
        id: 126,
        text: `Wpisz kwotę wierzytelności podaną jako całkowita kwota do zapłaty lub pozostała kwota do zapłaty albo przynajmniej sumę należności głównej i wskazanych odsetek od tej należności.`,
    },
    {
        id: 127,
        text: `Wpisz datę zapłaty wskazaną w piśmie przez firmę windykacyjną lub datę wydania nakazu zapłaty, albo datę nadania klauzuli wykonalności. `,
    },
    {
        id: 128,
        text: `endid`,
    },
    {
        id: 129,
        text: `Podaj datę dokumentu otrzymanego od danej firmy windykacyjnej.`,
    },
    {
        id: 130,
        text: `Wykonaj 2 kopie odpowiedzi otrzymanej od danej firmy windykacyjnej. Będą one stanowiły załączniki do wniosku.`,
    },
    {
        id: 131,
        mode: 'hide'
    },
    {
        id: 132,
        text: `Otwórz i przejrzyj raport ERIF`,
    },
    {
        id: 133,
        content: [
            {
                type: "text",
                text: `Zaznacz <b>Tak</b> jeżeli raport ERIF zawiera jakiekolwiek wierzytelności pod opisem <b>NEGATYWNE INFORMACJE GOSPODARCZE</b>.<br>
                        Przykładową stronę raportu ERIF zawierającą wierzytelności znajdziesz tutaj:<br>`,
            },
            {
                type: "image",
                image: "/Auron/erif1.png"
            },
        ]
    },
    {
        id: 134,
        content: [
            {
                type: "text",
                text: `Znajdź na stronie opis <b>NEGATYWNE INFORMACJE GOSPODARCZE</b>. Pod spodem z lewej strony masz podaną pełną nazwę wierzyciela. Przepisz ją.<br>
                        Przykładową stronę z nazwą wierzyciela znajdziesz tutaj (nazwa wierzyciela jest zaznaczona elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/erif2.png"
            },
        ]
    },
    {
        id: 135,
        content: [
            {
                type: "text",
                text: `Podaj kraj wierzyciela wynikający z jego adresu.<br>
                        Przykładową stronę z adresem wierzyciela znajdziesz tutaj (adres wierzyciela jest zaznaczony elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/erif3.png"
            },
        ]
    },
    {
        id: 136,
        content: [
            {
                type: "text",
                text: `Podaj miejscowość wierzyciela.<br>
                    Przykładową stronę z adresem wierzyciela znajdziesz tutaj (adres wierzyciela jest zaznaczony elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/erif3.png"
            },
        ]
    },
    {
        id: 137,
        content: [
            {
                type: "text",
                text: `Podaj ulicę wierzyciela.<br>
                        Przykładową stronę z adresem wierzyciela znajdziesz tutaj (adres wierzyciela jest zaznaczony elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/erif3.png"
            },
        ]
    },
    {
        id: 138,
        content: [
            {
                type: "text",
                text: `Podaj numer budynku wierzyciela.<br>
                        Przykładową stronę z adresem wierzyciela znajdziesz tutaj (adres wierzyciela jest zaznaczony elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/erif3.png"
            },
        ]
    },
    {
        id: 139,
        content: [
            {
                type: "text",
                text: `Podaj numer lokalu wierzyciela.<br>
                        Przykładową stronę z adresem wierzyciela znajdziesz tutaj (adres wierzyciela jest zaznaczony elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/erif3.png"
            },
        ]
    },
    {
        id: 140,
        content: [
            {
                type: "text",
                text: `Podaj kod pocztowy wierzyciela.<br>
                        Przykładową stronę z adresem wierzyciela znajdziesz tutaj (adres wierzyciela jest zaznaczony elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/erif3.png"
            },
        ]
    },
    {
        id: 141,
        text: `Jeśli chcesz zmienić walutę wierzytelności to wpisz ją lub wybierz z listy.`,
    },
    {
        id: 142,
        content: [
            {
                type: "text",
                text: `Wpisz kwotę wierzytelności opisaną <b>łączna kwota zaległości</b>.<br>
                        Przykładową stronę z kwotą wierzytelności znajdziesz tutaj (kwota wierzytelności jest zaznaczona elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/erif4.png"
            },
        ]
    },
    {
        id: 143,
        content: [
            {
                type: "text",
                text: `Wpisz termin zapłaty wskazany po słowie <b>wymagalne</b>.<br>
                        Przykładową stronę ze wskazaniem terminu zapłaty znajdziesz tutaj (termin zapłaty jest zaznaczony elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/erif5.png"
            },
        ]
    },
    {
        id: 144,
        text: `endid`,
    },
    {
        id: 145,
        content: [
            {
                type: "text",
                text: `Wpisz datę raportu ERIF, którą znajdziesz pod opisem <b>data zapytania</b>.<br>
                        Przykładową stronę gdzie znaleźć datę raportu zobacz tutaj (data raportu zaznaczona elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/erif6.png"
            },
        ]
    },
    {
        id: 146,
        text: `Wykonaj 2 kopie raportu ERIF. Będą one stanowiły załączniki do wniosku.`,
    },
    {
        id: 147,
        text: `auto`,
    },
    {
        id: 148,
        text: `Przygotuj zaświadczenie ZAS-W z Urzędu Skarbowego.`,
    },
    {
        id: 149,
        mode: 'hide'
    },
    {
        id: 150,
        text: `Wpisz pełną nazwę Urzędu Skarbowego z zaświadczenia ZAS-W.`,
    },
    {
        id: 151,
        text: `Wpisz nazwę miejscowości siedziby Urzędu Skarbowego z zaświadczenia ZAS-W.`,
    },
    {
        id: 152,
        text: `Wpisz nazwę ulicy siedziby Urzędu Skarbowego z zaświadczenia ZAS-W.`,
    },
    {
        id: 153,
        text: `Wpisz numer budynku siedziby Urzędu Skarbowego z zaświadczenia ZAS-W.`,
    },
    {
        id: 154,
        text: `Wpisz numer lokalu siedziby Urzędu Skarbowego z zaświadczenia ZAS-W.<br>
        Jeśli adres Urzędu Skarbowego zawiera tylko numer budynku nie wypełniaj tego pola i przejdź <b>Dalej</b>.`,
    },
    {
        id: 155,
        text: `Wpisz kod pocztowy siedziby Urzędu Skarbowego z zaświadczenia ZAS-W.`,
    },
    {
        id: 156,
        text: `Wpisz sumę wszystkich zaległości podanych w zaświadczeniu ZAS-W.`,
    },
    {
        id: 157,
        text: `Wpisz termin ostatniej zaległości podanej w zaświadczeniu ZAS-W.`,
    },
    {
        id: 158,
        text: `endid`,
    },
    {
        id: 159,
        content: [
            {
                type: "text",
                text: `Wpisz datę wskazaną w zaświadczeniu na pierwszej stronie w prawym górnym rogu.<br>
                        Przykładową stronę gdzie znaleźć datę zaświadczenia zobacz tutaj (data zaświadczenia zaznaczona elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/zasw.png"
            },
        ]
    },
    {
        id: 160,
        text: `Wykonaj 2 kopie zaświadczenia z Urzędu Skarbowego. Będą one stanowiły załączniki do wniosku.`,
    },
    {
        id: 161,
        text: `endid`,
    },
    {
        id: 162,
        text: `Przygotuj pismo - informację ZUS.`,
    },
    {
        id: 163,
        mode: 'hide'
    },
    {
        id: 164,
        text: `Wpisz nazwę oddziału ZUS z otrzymanego pisma.`,
    },
    {
        id: 165,
        text: `Wpisz nazwę miejscowości siedziby oddziału ZUS z otrzymanego pisma.`,
    },
    {
        id: 166,
        text: `Wpisz nazwę ulicy siedziby oddziału ZUS z otrzymanego pisma.`,
    },
    {
        id: 167,
        text: `Wpisz numer budynku siedziby oddziału ZUS z otrzymanego pisma.`,
    },
    {
        id: 168,
        text: `Wpisz numer lokalu siedziby oddziału ZUS z otrzymanego pisma.`,
    },
    {
        id: 169,
        text: `Wpisz kod pocztowy siedziby oddziału ZUS z otrzymanego pisma.`,
    },
    {
        id: 170,
        content: [
            {
                type: "text",
                text: `Wpisz sumę wszystkich zaległości oraz naliczonych odsetek. Kwoty te są wskazane na pierwszej stronie dokumentu oraz są podane na końcu tabeli - zestawienia wszystkich zobowiązań.<br>
                        Przykład kwot zaległości i naliczonych odsetek, które należy zsumować i wpisać do wniosku zobaczysz tutaj (kwoty zaznaczone elipsą):`,
            },
            {
                type: "image",
                image: "/Auron/zus1.png"
            },
        ]
    },
    {
        id: 171,
        content: [
            {
                type: "text",
                text: `Wpisz termin ostatniej zaległości podanej w informacji ZUS.<br>
                        Przykładową stronę z terminem zapłaty zobacz tutaj (termin zaznaczony elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/zus2.png"
            },
        ]
    },
    {
        id: 172,
        text: "endid"
    },
    {
        id: 173,
        content: [
            {
                type: "text",
                text: `Wpisz datę sporządzenia dokumentu przez ZUS podaną na pierwszej stronie.<br>
                        Przykładową stronę gdzie znaleźć datę pisma ZUS zobacz tutaj (data pisma zaznaczona elipsą):<br>`,
            },
            {
                type: "image",
                image: "/Auron/zus3.png"
            },
        ]
    },
    {
        id: 174,
        text: `Wykonaj 2 kopie informacji ZUS. Będą one stanowiły załączniki do wniosku.`,
    },
    {
        id: 175,
        text: `Przygotuj pismo - informację BIK.`,
    },
    {
        id: 176,
        mode: 'hide'
    },
    {
        id: 177,
        text: `Mogą to być również np.: monit, wezwanie do zapłaty, wypowiedzenie umowy.`,
    },
    {
        id: 178,
        text: `Wpisz nazwę dokumentu np.: Umowa, Ponaglenie, Monit, Wezwanie do zapłaty, Ostateczne przedsądowe wezwanie do zapłaty, Wypowiedzenie umowy.`,
    },
    {
        id: 179,
        text: "Wpisz datę dokumentu znajdującą się na pierwszej stronie."
    },
    {
        id: 180,
        text: `Wykonaj 2 kopie tego dokumentu. Będą one stanowiły załączniki do wniosku.`,
    },
    {
        id: 181,
        text: `Przez termin zapłaty rozumiemy np.: termin zapłaty ostatniej raty przewidziany w umowie, termin zapłaty wskazany przez wierzyciela w wezwaniu do zapłaty lub w wypowiedzeniu umowy. Przez termin zapłaty rozumiemy też termin wydania nakazu zapłaty przez sąd lub termin nadania klauzuli wykonalności do tego nakazu.`,
    },
    {
        id: 182,
        text: `Jeżeli ta wierzytelność była już na etapie egzekucji komorniczej to upewnij się, że żaden z komorników nie przysłał jej w swoim zestawieniu.`,
    },
    {
        id: 183,
        text: `Jeżeli nie jesteś pewny daty zapłaty to prześlij na adres e-mail: <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://mail.google.com/mail/?view=cm&fs=1&to=biuro.arkancelaria@gmail.com" target="_blank">biuro.arkancelaria@gmail.com</a>, wyraźne zdjęcia informacji BIK, a w treści wiadomości wskaż zobowiązanie, którego daty zapłaty nie jesteś pewien.<br> Po przesłaniu tych zdjęć zadzwoń na numer <b>534 123 123</b> celem uzyskania dalszych informacji.`,
    },
    {
        id: 184,
        text: `Wpisz pełną nazwę wierzyciela.`,
    },
    {
        id: 185,
        text: `Wpisz kraj wierzyciela, wynikający z jego adresu.`
    },
    {
        id: 186,
        text: `Wpisz miejscowość wierzyciela, wynikającą z jego adresu.`
    },
    {
        id: 187,
        text: `Wpisz ulicę wierzyciela, wynikającą z jego adresu.`
    },
    {
        id: 188,
        text: `Wpisz numer budynku wierzyciela, wynikający z jego adresu.`
    },
    {
        id: 189,
        text: `Wpisz numer lokalu wierzyciela, wynikający z jego adresu.`
    },
    {
        id: 190,
        text: `Wpisz kod pocztowy wierzyciela, wynikający z jego adresu.`
    },
    {
        id: 191,
        text: `Jeśli chcesz zmienić walutę wierzytelności to wpisz ją lub wybierz z listy.`,
    },
    {
        id: 192,
        text: `Wpisz kwotę wierzytelności wynikającą z posiadanych dokumentów lub według Twojej pamięci (np.: kwotę, którą uważasz, że pozostała do zapłaty). Może to być także kwota pozostała do zapłaty wskazana w informacji BIK.<br>
        UWAGA: Nie przejmuj się jeżeli podana kwota będzie nieco odbiegać od aktualnego faktycznego zadłużenia, gdyż ostateczną weryfikacją zasadności i wysokości Twoich wierzytelności zajmie się syndyk, po ogłoszeniu Twojej upadłości.`,
    },
    {
        id: 193,
        text: `Wpisz ustalony termin zapłaty wynikający z dokumentów.`,
    },
    {
        id: 194,
        text: `endid`,
    },
    {
        id: 195,
        text: `Wpisz datę z pierwszej strony pisma - informacji BIK.`,
    },
    {
        id: 196,
        text: `Wykonaj 2 kopie pisma informacji BIK. Będą one stanowiły załączniki do wniosku.`,
    },
    {
        id: 197,
        text: `We wniosku o ogłoszenie upadłości muszą być wymienione wszystkie zobowiązania niezależnie od tego czy są spłacane czy nie. Z tego względu jeżeli posiadasz zobowiązania kredytowe/pożyczkowe, które nadal prawidłowo regulujesz to przygotuj umowy na podstawie, których zaciągnąłeś/łaś te zobowiązania i zaznacz <b>Tak</b>. Przez przygotowanie umów rozumiemy ich fizyczne wydrukowanie (jeżeli posiadasz je tylko na mailu).`,
    },
    {
        id: 198,
        text: `Na podstawie posiadanych dokumentów wpisz pełną nazwę wierzyciela.`,
    },
    {
        id: 199,
        text: `Wpisz kraj siedziby wierzyciela, wynikający z jego adresu.`,
    },
    {
        id: 200,
        text: `Wpisz miejscowość siedziby wierzyciela, wynikającą z jego adresu.`,
    },
    {
        id: 201,
        text: `Wpisz ulicę siedziby wierzyciela, wynikającą z jego adresu.`,
    },
    {
        id: 202,
        text: `Wpisz numer budynku siedziby wierzyciela, wynikający z jego adresu.`,
    },
    {
        id: 203,
        text: `Wpisz numer lokalu siedziby wierzyciela, wynikający z jego adresu.`,
    },
    {
        id: 204,
        text: `Wpisz kod pocztowy siedziby wierzyciela, wynikający z jego adresu.`,
    },
    {
        id: 205,
        text: `Jeśli chcesz zmienić walutę wierzytelności to wpisz ją lub wybierz z listy.`,
    },
    {
        id: 206,
        text: `Wpisz kwotę wierzytelności wynikającą z posiadanych dokumentów lub według Twojej pamięci (np.: kwotę, którą uważasz, że pozostała do zapłaty).<br>
        UWAGA: Nie przejmuj się jeżeli podana kwota będzie nieco odbiegać od aktualnego faktycznego zadłużenia, gdyż ostateczną weryfikacją zasadności i wysokości Twoich wierzytelności zajmie się syndyk, po ogłoszeniu Twojej upadłości.`,
    },
    {
        id: 207,
        text: `Wpisz termin ostatniej raty wynikający z umowy.`,
    },
    {
        id: 208,
        text: `endid`,
    },
    {
        id: 209,
        text: `Przez wierzytelności sporne rozumiemy tylko takie wierzytelności, których istnienie podważasz na drodze sądowej lub przedsądowej w całości lub części i w tej sprawie prowadzisz korespondencję z wierzycielem, który tych wierzytelności się domaga.`,
    },
    {
        id: 210,
        text: `UWAGA: Zaznacz <b>Tak</b> jeżeli otrzymałeś/łaś kiedykolwiek jakiekolwiek pismo od komornika dotyczące tej wierzytelności. Nie ma żadnego znaczenia czy komornikowi w drodze egzekucji udało się ściągnąć jakąkolwiek kwotę.`,
    },
    {
        id: 211,
        text: `Wierzytelność, która jest lub była kiedykolwiek egzekwowana na drodze egzekucji komorniczej - nie jest wierzytelnością sporną i musisz podać ją do wniosku jak każdą inną wierzytelność.`,
    },
    {
        id: 212,
        text: `Na podstawie posiadanych dokumentów wpisz pełną nazwę wierzyciela.`,
    },
    {
        id: 213,
        text: `Wpisz kraj siedziby wierzyciela, wynikający z jego adresu.`,
    },
    {
        id: 214,
        text: `Wpisz miejscowość siedziby wierzyciela, wynikającą z jego adresu.`,
    },
    {
        id: 215,
        text: `Wpisz ulicę siedziby wierzyciela, wynikającą z jego adresu.`,
    },
    {
        id: 216,
        text: `Wpisz numer budynku siedziby wierzyciela, wynikający z jego adresu.`,
    },
    {
        id: 217,
        text: `Wpisz numer lokalu siedziby wierzyciela, wynikający z jego adresu.`,
    },
    {
        id: 218,
        text: `Wpisz kod pocztowy siedziby wierzyciela, wynikający z jego adresu.`,
    },
    {
        id: 219,
        text: `Jeśli chcesz zmienić walutę wierzytelności to wpisz ją lub wybierz z listy.`,
    },
    {
        id: 220,
        text: `Wpisz kwotę wierzytelności wynikającą z posiadanych dokumentów.`,
    },
    {
        id: 221,
        text: `Wpisz termin zapłaty wynikający z posiadanych dokumentów.`,
    },
    {
        id: 222,
        text: `Napisz w jakim zakresie (w całości czy w części) kwestionujesz tę wierzytelność. Podaj na jakiej podstawie ją kwestionujesz (np.: przedawnienia), a jeśli w sprawie kwestionowania tej wierzytelności toczy się postępowanie sądowe to podaj nazwę sądu i sygnaturę sprawy.`,
    },
    {
        id: 223,
        text: `Podaj nazwę dokumentu dotyczącego kwestionowanej wierzytelności (np.: umowa, uchylenie się od skutków oświadczenia woli, pozew).`,
    },
    {
        id: 224,
        text: `Wpisz datę znajdującą się na pierwszej stronie dokumentu (np.: datę umowy, datę uchylenia się od skutków oświadczenia woli, datę pozwu).`,
    },
    {
        id: 225,
        text: `Wykonaj 2 kopie tego dokumentu. Będą one stanowiły załączniki do wniosku.`,
    },
    {
        id: 226,
        text: "endid"
    },
    {
        id: 227,
        text: [
            `Zaznacz <b>Tak</b> jeżeli kiedykolwiek w ciągu ostatnich 6-ciu miesięcy otrzymywałeś/łaś wynagrodzenie z tytułu umowy o pracę: <br>
            - tymczasową, <br>
            lub <br>
            - na czas określony.<br>
            lub <br>
            - na czas nieokreślony.<br>`,
            `Zaznacz <b>Tak</b> jeżeli kiedykolwiek w ciągu ostatnich 6-ciu miesięcy otrzymywałeś/łaś wynagrodzenie z tytułu umowy zlecenia.`,
            `Zaznacz <b>Tak</b> jeżeli kiedykolwiek w ciągu ostatnich 6-ciu miesięcy otrzymywałeś/łaś wynagrodzenie z tytułu umowy o dzieło.`,
            `Zaznacz <b>Tak</b> jeżeli kiedykolwiek w ciągu ostatnich 6-ciu miesięcy otrzymywałeś/łaś dochody z tytułu świadczenia zdrowotnego (L4).`,
            `Zaznacz <b>Tak</b> jeżeli kiedykolwiek w ciągu ostatnich 6-ciu miesięcy otrzymywałeś/łaś dochody z tytułu świadczenia rehabilitacyjnego.`,
            `Zaznacz <b>Tak</b> jeżeli kiedykolwiek w ciągu ostatnich 6-ciu miesięcy otrzymywałeś/łaś dochody z tytułu renty.`,
            `Zaznacz <b>Tak</b> jeżeli kiedykolwiek w ciągu ostatnich 6-ciu miesięcy otrzymywałeś/łaś dochody z tytułu emerytury.`,
            `Zaznacz <b>Tak</b> jeżeli kiedykolwiek w ciągu ostatnich 6-ciu miesięcy otrzymywałeś/łaś dochody z tytułu świadczenia pielęgnacyjnego.`,
            `Zaznacz <b>Tak</b> jeżeli kiedykolwiek w ciągu ostatnich 6-ciu miesięcy otrzymywałeś/łaś dochody z tytułu dodatku pielęgnacyjnego.`,
            `Zaznacz <b>Tak</b> jeżeli kiedykolwiek w ciągu ostatnich 6-ciu miesięcy otrzymywałeś/łaś dochody z tytułu świadczenia wychowawczego (800+).`,
            `Zaznacz <b>Tak</b> jeżeli kiedykolwiek w ciągu ostatnich 6-ciu miesięcy otrzymywałeś/łaś dochody z tytułu pomocy społecznej MOPS.`,
            `Zaznacz <b>Tak</b> jeżeli kiedykolwiek w ciągu ostatnich 6-ciu miesięcy otrzymywałeś/łaś dochody z tytułu pracy dorywczej.`,
            `Zaznacz <b>Tak</b> jeżeli kiedykolwiek w ciągu ostatnich 6-ciu miesięcy otrzymywałeś/łaś dochody ze źródeł innych (np.: pomoc rodziny, znajomych, dochód z wynajmu, prostytucja, itp.) niż wskazano poprzednio.`,
        ]
    },
    {
        id: 228,
        text: `Jeśli chcesz zmienić walutę dochodu to wpisz ją lub wybierz z listy.`,
    },
    {
        id: 229,
        text: `Podaj kwotę, którą otrzymałeś/łaś "na rękę".`,
    },
    {
        id: 230,
        text: [
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
        ]
    },
    {
        id: 231,
        text: `Jeśli chcesz zmienić walutę dochodu to wpisz ją lub wybierz z listy.`,
    },
    {
        id: 232,
        text: `Podaj kwotę, którą otrzymałeś/łaś "na rękę".`,
    },
    {
        id: 233,
        text: [
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
        ]
    },
    {
        id: 234,
        text: `Jeśli chcesz zmienić walutę dochodu to wpisz ją lub wybierz z listy.`,
    },
    {
        id: 235,
        text: `Podaj kwotę, którą otrzymałeś/łaś "na rękę".`,
    },
    {
        id: 236,
        text: [
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
        ]
    },
    {
        id: 237,
        text: `Jeśli chcesz zmienić walutę dochodu to wpisz ją lub wybierz z listy.`,
    },
    {
        id: 238,
        text: `Podaj kwotę, którą otrzymałeś/łaś "na rękę".`,
    },
    {
        id: 239,
        text: [
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
        ]
    },
    {
        id: 240,
        text: `Jeśli chcesz zmienić walutę dochodu to wpisz ją lub wybierz z listy.`,
    },
    {
        id: 241,
        text: `Podaj kwotę, którą otrzymałeś/łaś "na rękę".`,
    },
    {
        id: 242,
        text: [
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
        ]
    },
    {
        id: 243,
        text: `Jeśli chcesz zmienić walutę dochodu to wpisz ją lub wybierz z listy.`,
    },
    {
        id: 244,
        text: `Podaj kwotę, którą otrzymałeś/łaś "na rękę".`,
    },
    {
        id: 245,
        text: [
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Ze względu na charakter świadczenia wybierz z listy <b>Otrzymywana kwota bez obciążeń komorniczych</b>.`,
            `Jeśli we wskazanym miesiącu pracodawca dokonał potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
            `Jeśli we wskazanym miesiącu dokonano potrąceń dla komornika to wybierz <b>Otrzymywana kwota po egzekucji komorniczej</b>.`,
        ]
    },
    {
        id: 246,
        text: "endid"
    },
    {
        id: 247,
        text: [
            `Wybierz <b>Tak</b>, jeżeli opłacałeś/łaś koszty czynszu, wynajmu, podatku od nieruchomości. UWAGA: Nawet jeżeli wynajmujesz lokum, w którym mieszkasz bez umowy pisemnej, "na gębę", ale przekazujesz jakieś kwoty na utrzymanie tego lokum to również wybierz <b>Tak</b>.`,
            `Wybierz <b>Tak</b>, jeżeli ponosiłeś/łaś koszty z tytułu opłat za energię elektryczną <b>niezależnie od tego czy faktury za prąd były wystawiane na Ciebie czy też nie</b>. Liczy się sam fakt ponoszenia kosztu, nawet jeśli pieniądze "na prąd" przekazywałeś/łaś innej osobie do zapłaty.`,
            `Wybierz <b>Tak</b>, jeżeli ponosiłeś/łaś koszty z tytułu opłat za gaz <b>niezależnie od tego czy faktury za gaz były wystawiane na Ciebie czy też nie</b>. Liczy się sam fakt ponoszenia kosztu, nawet jeśli pieniądze "na gaz" przekazywałeś/łaś innej osobie do zapłaty.`,
            `Wybierz <b>Tak</b>, jeżeli ponosiłeś/łaś koszty z tytułu opłat za wodę i kanalizację (wywóz nieczystości płynnych) <b>niezależnie od tego czy faktury za wodę i kanalizację (wywóz nieczystości płynnych) były wystawiane na Ciebie czy też nie</b>. Liczy się sam fakt ponoszenia kosztu, nawet jeśli pieniądze "na wodę i ścieki" przekazywałeś/łaś innej osobie do zapłaty.`,
            `Wybierz <b>Tak</b>, jeżeli ponosiłeś/łaś koszty z tytułu opłat za wywóz śmieci i innych odpadów <b>niezależnie od tego czy faktury za wywóz śmieci i innych odpadów były wystawiane na Ciebie czy też nie</b>. Liczy się sam fakt ponoszenia kosztu, nawet jeśli pieniądze "za śmieci" przekazywałeś/łaś innej osobie do zapłaty.`,
            `Wybierz <b>Tak</b>, jeżeli wydałeś/łaś jakąkolwiek kwotę na zakup środków czystości i higieny osobistej (np.: mydło, papier toaletowy, szampon, proszek do prania, płyn do mycia naczyń, etc.).`,
            `Wybierz <b>Tak</b>, jeżeli wydałeś/łaś jakąkolwiek kwotę na zakup żywności i napojów bezalkoholowych.`,
            `Wybierz <b>Tak</b>, jeżeli wydałeś/łaś jakąkolwiek kwotę na zdrowie (wizyty lekarskie, leki, rehabilitację).`,
            `Wybierz <b>Tak</b>, jeżeli ponosiłeś/łaś koszty z tytułu opłat za telewizję <b>niezależnie od tego czy faktury za telewizję były wystawiane na Ciebie czy też nie</b>. Liczy się sam fakt ponoszenia kosztu, nawet jeśli pieniądze "na telewizję" przekazywałeś/łaś innej osobie do zapłaty.`,
            `Wybierz <b>Tak</b>, jeżeli ponosiłeś/łaś koszty z tytułu opłat za internet <b>niezależnie od tego czy faktury za internet były wystawiane na Ciebie czy też nie</b>. Liczy się sam fakt ponoszenia kosztu, nawet jeśli pieniądze "na internet" przekazywałeś/łaś innej osobie do zapłaty.`,
            `Wybierz <b>Tak</b>, jeżeli ponosiłeś/łaś koszty z tytułu zakupu doładowań lub innych opłat za telefon <b>niezależnie od tego czy faktury za telefon były wystawiane na Ciebie czy też nie</b>. Liczy się sam fakt ponoszenia kosztu, nawet jeśli pieniądze "na telefon" przekazywałeś/łaś innej osobie do zapłaty.`,
            `Wybierz <b>Tak</b>, jeżeli wydałeś/łaś jakąkolwiek kwotę na zakup odzieży i obuwia.`,
            `Wybierz <b>Tak</b>, jeżeli kupowałeś/łaś np.: paliwo do pojazdu, który użytkujesz, bilety na jakąkolwiek komunikację publiczną, busy, czy też dałeś/łaś komukolwiek pieniądze "na paliwo", żeby Cię gdzieś podwiózł.`,
        ]
    },
    {
        id: 248,
        text: `Jeśli chcesz zmienić walutę wydatku to wpisz ją lub wybierz z listy.`,
    },
    {
        id: 249,
        text: [
            `Wpisz średniomiesięczne koszty czynszu, czy też wynajmu lub podatku od nieruchomości. UWAGA: Nawet jeżeli wynajmujesz lokum, w którym mieszkasz bez umowy pisemnej, "na gębę", ale przekazujesz jakieś kwoty na utrzymanie tego lokum to wpisz te średniomiesięczne kwot, które przekazujesz. Pamiętaj, aby wpisane dane były rzetelne.`,
            `Wpisz średniomiesięczne koszty ponoszone przez Ciebie z tytułu opłat za energię elektryczną <b>niezależnie od tego czy faktury za prąd były wystawiane na Ciebie czy też nie</b>. Jeżeli wartości miesięcznych opłat nie są jednakowe, to dodaj opłaty za ostatnie 6 miesięcy, podziel przez 6 i wpisz otrzymaną kwotę. Pamiętaj, aby wpisane dane były rzetelne.`,
            `Wpisz średniomiesięczne koszty ponoszone przez Ciebie z tytułu opłat za gaz <b>niezależnie od tego czy faktury za gaz były wystawiane na Ciebie czy też nie</b>. Jeżeli wartości miesięcznych opłat nie są jednakowe, to dodaj opłaty za ostatnie 6 miesięcy, podziel przez 6 i wpisz otrzymaną kwotę. Pamiętaj, aby wpisane dane były rzetelne.`,
            `Wpisz średniomiesięczne koszty ponoszone przez Ciebie z tytułu opłat za wodę i kanalizację (wywóz nieczystości płynnych) <b>niezależnie od tego czy faktury za wodę i kanalizację (wywóz nieczystości płynnych) były wystawiane na Ciebie czy też nie</b>. Jeżeli wartości miesięcznych opłat nie są jednakowe, to dodaj opłaty za ostatnie 6 miesięcy, podziel przez 6 i wpisz otrzymaną kwotę. Pamiętaj, aby wpisane dane były rzetelne.`,
            `Wpisz średniomiesięczne koszty ponoszone przez Ciebie z tytułu opłat za wywóz śmieci i innych odpadów <b>niezależnie od tego czy faktury za wywóz śmieci i innych odpadów były wystawiane na Ciebie czy też nie</b>. Jeżeli wartości miesięcznych opłat nie są jednakowe, to dodaj opłaty za ostatnie 6 miesięcy, podziel przez 6 i wpisz otrzymaną kwotę. Pamiętaj, aby wpisane dane były rzetelne.`,
            `Wpisz średniomiesięczne kwoty z tytułu zakupu środków czystości i higieny osobistej. Przeciętna kwota takich wydatków w zależności od wieku, płci, liczby osób w gospodarstwie domowym, w tym liczby i wieku dzieci na utrzymaniu, itp. waha się od 100 do 400 zł. Pamiętaj, aby wpisane dane były rzetelne.`,
            `Wpisz średniomiesięczne kwoty z tytułu zakupu żywności i napojów bezalkoholowych. Przeciętna kwota takich wydatków na jedną osobę w gospodarstwie domowym wynosi około 800-1200 zł. Wpisz kwotę wydatków dla całego gospodarstwa domowego. Pamiętaj, aby wpisane dane były rzetelne.`,
            `Wpisz średniomiesięczne kwoty z tytułu wydatków na zdrowie (wizyty lekarskie, leki, rehabilitację, itp.). Jeżeli wartości miesięcznych wydatków nie są jednakowe, to dodaj wydatki za ostatnie 6 miesięcy, podziel przez 6 i wpisz otrzymaną kwotę. Pamiętaj, aby wpisane dane były rzetelne.`,
            `Wpisz średniomiesięczne koszty ponoszone przez Ciebie z tytułu opłat za telewizję <b>niezależnie od tego czy faktury za telewizję były wystawiane na Ciebie czy też nie</b>. Jeżeli wartości miesięcznych opłat nie są jednakowe, to dodaj opłaty za ostatnie 6 miesięcy, podziel przez 6 i wpisz otrzymaną kwotę.<br>
            <b>UWAGA: Jeżeli w jednym abonamencie opłacasz telefon, telewizję i internet to ponoszone koszty uwzględnij tylko przy opłacie za telefon</b>, w takim przypadku w kosztach telewizji i internetu wpisz 0. Pamiętaj, aby wpisane dane były rzetelne.`,
            `Wpisz średniomiesięczne koszty ponoszone przez Ciebie z tytułu opłat za internet <b>niezależnie od tego czy faktury za internet były wystawiane na Ciebie czy też nie</b>. Jeżeli wartości miesięcznych opłat nie są jednakowe, to dodaj opłaty za ostatnie 6 miesięcy, podziel przez 6 i wpisz otrzymaną kwotę.<br>
            <b>UWAGA: Jeżeli w jednym abonamencie opłacasz telefon, telewizję i internet to ponoszone koszty uwzględnij tylko przy opłacie za telefon</b>, w takim przypadku w kosztach telewizji i internetu wpisz 0. Pamiętaj, aby wpisane dane były rzetelne.`,
            `Wpisz średniomiesięczne koszty ponoszone przez Ciebie z tytułu zakupu doładowań lub innych opłat za telefon <b>niezależnie od tego czy faktury za telefon były wystawiane na Ciebie czy też nie</b>. Jeżeli wartości miesięcznych opłat nie są jednakowe, to dodaj opłaty za ostatnie 6 miesięcy, podziel przez 6 i wpisz otrzymaną kwotę.<br>
            <b>UWAGA: Jeżeli w jednym abonamencie opłacasz telefon, telewizję i internet to ponoszone koszty uwzględnij tylko przy opłacie za telefon</b>, w takim przypadku w kosztach telewizji i internetu wpisz 0. Pamiętaj, aby wpisane dane były rzetelne.`,
            `Wpisz średniomiesięczne kwoty z tytułu wydatków na odzież i obuwie. Wartości miesięczne takich wydatków nie są jednakowe, dlatego dodaj wydatki za ostatnie 6 miesięcy, podziel przez 6 i wpisz otrzymaną kwotę. Przeciętne średniomiesięczne wydatki na odzież i obuwie na osobę wahają się od 100 do 300 zł. Pamiętaj, aby wpisane dane były rzetelne.`,
            `Wpisz średniomiesięczne koszty ponoszone przez Ciebie na transport i przejazdy. Uwzględnij takie koszty jak np.: paliwo do pojazdu, który użytkujesz, bilety na jakąkolwiek komunikację publiczną, busy, czy też kwoty, które dałeś/łaś komukolwiek "na paliwo", żeby Cię gdzieś podwiózł. Jeżeli wartości miesięcznie ponoszonych kosztów nie są jednakowe, to dodaj koszty za ostatnie 6 miesięcy, podziel przez 6 i wpisz otrzymaną kwotę. Pamiętaj, aby wpisane dane były rzetelne.`,
        ],
    },
    {
        id: 250,
        text: "endid"
    },
    {
        id: 251,
        text: `Wybierz <b>Tak</b>, jeżeli opłacasz np.: żłobek, przedszkole, prywatne ubezpieczenie, płacisz alimenty, kupujesz karmę dla zwierząt lub ponosisz inne regularne wydatki nie wymienione wcześniej.`
    },
    {
        id: 252,
        text: "Wpisz nazwę wydatku np.: żłobek, przedszkole, prywatne ubezpieczenie, alimenty, karma dla zwierząt lub inny regularny wydatek, którego nazwę musisz podać. <b>Podaj tylko jedną nazwę</b> - kolejne wydatki będzie można wypełnić w kolejnych krokach."
    },
    {
        id: 253,
        text: `Jeśli chcesz zmienić walutę wydatku to wpisz ją lub wybierz z listy.`,
    },
    {
        id: 254,
        function: diffrentSpending,
        text: `Wpisz średniomiesięczne koszty ponoszone przez Ciebie na <b>{{ query }}</b>. Jeżeli wartości miesięcznych wydatków nie są jednakowe, to dodaj wydatki za ostatnie 6 miesięcy, podziel przez 6 i wpisz otrzymaną kwotę. Pamiętaj, aby wpisane dane były rzetelne.`
    },
    {
        id: 255,
        text: "endid"
    },
    {
        id: 256,
        text: `Wybierz <b>Tak</b> jeżeli np.: sprzedałeś/łaś, kupiłeś/łaś, darowałeś/łaś, przeniosłeś/łaś własność, zastawiłeś/łaś, użyczyłeś/łaś, dokonałeś/łaś cesji praw dotyczących nieruchomości, akcji lub udziałów w spółkach.`,
    },
    {
        id: 257,
        text: `Wybierz przedmiot czynności prawnej.`,
    },
    {
        id: 258,
        text: `Wybierz z listy lub wpisz rodzaj czynności prawnej, jeśli nie ma jej na liście.`,
    },
    {
        id: 259,
        text: `Możesz podać np.: informację o numerze Księgi Wieczystej nieruchomości, dacie czynności prawnej, liczbie udziałów lub akcji oraz nazwę spółki, której te udziały lub akcje dotyczą.`,
    },
    {
        id: 260,
        text: "endid"
    },
    {
        id: 261,
        text: `Wybierz <b>Tak</b> jeżeli dokonałeś/łaś jakiejkolwiek czynności prawnej, której przedmiotem były: ruchomości, wierzytelności lub inne prawa, których wartość przekracza 10 000 zł.`,
    },
    {
        id: 262,
        text: `Wpisz przedmiot czynności prawnej.`,
    },
    {
        id: 263,
        text: `Wybierz z listy lub wpisz rodzaj czynności prawnej, jeśli nie ma jej na liście.`,
    },
    {
        id: 264,
        text: `Możesz dokładniej opisać przedmiot czynności prawnej, podać datę tej czynności lub inne informacje, które uważasz za istotne.`,
    },
    {
        id: 265,
        text: `endid`,
    },
    {
        id: 266,
        text: `Zaznacz <b>Tak</b> jeżeli chciałeś/łaś podjąć dodatkową pracę lub wyjechać za granicę w celach zarobkowych, aby spłacać długi, nawet jeżeli z jakichkolwiek powodów Ci się to nie udało.`,
    },
    {
        id: 267,
        text: `Zaznacz <b>Tak</b> jeżeli jakakolwiek osoba pomaga lub pomagała Ci się utrzymać w jakikolwiek sposób, np.: poprzez wsparcie drobnymi kwotami, dokonywanie i przekazywanie dowolnego rodzaju zakupów (żywność, środki czystości, ubrania etc.).`,
    },
    {
        id: 268,
        text: `Zaznacz <b>Tak</b> jeżeli w jakichkolwiek zobowiązaniach, które zaciągałeś, podpisałeś umowę jako zaciągający zobowiązanie osobiście lub z inną osobą (jako pożyczkobiorca, kredytobiorca, współpożyczkobiorca, współkredytobiorca etc.).`,
    },
    {
        id: 269,
        text: `Opisz krótko na co, <b>zgodnie ze stanem faktycznym</b>, przeznaczyłeś/aś środki z pierwszych zaciągniętych zobowiązań, np.: "Otrzymane środki z pierwszych zaciągniętych zobowiązań zostały przeznaczone na: (np. bieżące potrzeby, konieczny remont, zakup samochodu, narodziny dziecka, przeprowadzka, leczenie, rehabilitacje, rozwój firmy, wypłaty dla pracowników, opłacenie ZUS, opłacenie podatków, etc. )".`
    },
    {
        id: 270,
        text: `Opisz krótko, <b>zgodnie ze stanem faktycznym</b>, w jaki sposób powstały Twoje zobowiązania, których nie zaciągałeś osobiście, np.: w wyniku dziedziczenia długów (po kim?, kiedy?), poręczania kredytów/pożyczek (komu?, kiedy?, na jaki cel?), wzięcia kredytu/pożyczki dla kogoś z rodziny lub kolegi/koleżanki (kiedy?, na jaki cel?), itp.`,
    },
    {
        id: 271,
        text: `Opisz w 2-3 zdaniach przyczyny (np. utrata pracy lub przychodu (przez kogo), rozwód, choroby i leczenie w tym uzależnienia (np.: alkoholizm, hazard), zaległości w płatnościach od kontrahentów, powstała konkurencja, pandemia, wzrost stóp procentowych, a przez to rat kredytów itp.) i podaj rok, w którym przestałeś/łaś prawidłowo regulować swoje zobowiązania <b>przez okres dłuższy niż 3 miesiące</b>, a stan ten utrzymuje się do dziś (miałeś/łaś opóźnienia w regulowaniu zobowiązań, przestałeś/łaś płacić w ogóle lub kwoty, które płaciłeś/łaś były niższe niż wynikające z umowy).`,
    },
    {
        id: 272,
        text: `Opisz swoją obecną sytuację rodzinną: stan cywilny, liczba dzieci na utrzymaniu, czy płacisz lub otrzymujesz alimenty i w jakiej wysokości, itp.`,
    },
    {
        id: 273,
        text: `Opisz sytuację zdrowotną swoją i najbliższych: podaj wszystkie trapiące Cię schorzenia, czy z ich powodu jesteś pod stałą opieką lekarską, czy Ty lub ktoś z Twoich najbliższych posiada orzeczenie o niepełnosprawności, czy z powodu stanu zdrowia sprawujesz nad kimś opiekę lub ktoś opiekuje się Tobą. Czy przechodziłeś/łaś jakiekolwiek operację lub masz je zaplanowane w przyszłości.`,
    },
    {
        id: 274,
        text: `Wybierz <b>Tak</b> jeżeli posiadasz np.: zaświadczenie o Twoim stanie zdrowia i leczonych chorobach od lekarza rodzinnego lub lekarza prowadzącego, historię choroby, kartę informacyjną leczenia szpitalnego, itp.`,
    },
    {
        id: 275,
        text: `Wpisz nazwę dokumentu, np.: zaświadczenie, historia choroby, karta informacyjna, itp.`,
    },
    {
        id: 276,
        text: `Wpisz datę wystawienia dokumentu (najczęściej możesz ją znaleźć na górze pierwszej strony dokumentu).`,
    },
    {
        id: 277,
        text: `Wykonaj 2 kopie tego dokumentu. Będą one stanowiły załączniki do wniosku.`,
    },
    {
        id: 278,
        text: `endid`,
    },
    {
        id: 279,
        text: `Opisz swoją sytuację zawodową: podaj z czego się utrzymujesz (np.: pracy zarobkowej, emerytury, renty, dzięki pomocy rodziny, itp.), opisz w 2-3 zdaniach czy wystarcza lub nie wystarcza Ci na bieżące wydatki i dlaczego?`
    },
    {
        id: 280,
        text: `Zaznacz <b>Tak</b> jeżeli prowadzisz lub kiedykolwiek prowadziłeś/łaś jakąkolwiek działalność gospodarczą zarejestrowaną na siebie (jednoosobową działalność gospodarczą) lub jesteś/byłeś wspólnikiem spółki cywilnej, jawnej lub partnerskiej.`,
    },
    {
        id: 281,
        text: `Zaznacz <b>Tak</b> jeżeli dokonałeś zamknięcia swojej działalności gospodarczej poprzez zgłoszenie zamknięcia działalności gospodarczej i wykreślenia z rejestru przedsiębiorców. <br>
        UWAGA: Samo zawieszenie działalności gospodarczej <b>nie powoduje</b> zamknięcia działalności i wykreślenia jej z rejestru przedsiębiorców. Kiedy Twoja działalność jest tylko zawieszona <b>nie możesz</b> składać wniosku o ogłoszenie upadłości tzw. konsumenckiej, gdyż Twój wniosek będzie odrzucony lub postępowanie upadłościowe zostanie umorzone (wracasz do stanu sprzed złożenia wniosku).`,
    },
    {
        id: 282,
        text: `Zamknij działalność poprzez portal CEIDG lub w swoim urzędzie gminy/miasta. Pobierz stosowane potwierdzenie zamknięcia działalności.`,
    },
    {
        id: 283,
        text: `Zaznacz <b>Tak</b> jeżeli posiadasz jakikolwiek dokument stwierdzający, że nie jesteś przedsiębiorcą (np.: wydruk z CEIDG, zaświadczenie z urzędu gminy/miasta).`,
    },
    {
        id: 284,
        text: `Pobierz z CEIDG potwierdzenie wykreślenia Twojej działalności z rejestru lub udaj się do swojego urzędu gminy/miasta po takie potwierdzenie/zaświadczenie.`,
    },
    {
        id: 285,
        text: `Wpisz nazwę dokumentu stwierdzającego, że nie jesteś przedsiębiorcą.`,
    },
    {
        id: 286,
        text: `Wpisz datę dokumentu stwierdzającego, że nie jesteś przedsiębiorcą.`,
    },
    {
        id: 287,
        text: `Wykonaj 2 kopie tego dokumentu. Będą one stanowiły załączniki do wniosku.`,
    },
    {
        id: 288,
        text: `Jeżeli uważasz, że wszystkie podane przez Ciebie informacje oraz dane są prawidłowe i zgodne ze znanym Tobie stanem faktycznym to <b>Wyślij wniosek do przygotowania</b>.<br>
        Jeżeli chcesz poprawić jakieś dane to skontaktuj się z Nami mailowo <a style="color: var(--p-primary-500);text-decoration:underline;" href="https://mail.google.com/mail/?view=cm&fs=1&to=biuro.arkancelaria@gmail.com" target="_blank">biuro.arkancelaria@gmail.com</a> lub telefonicznie <b>534 123 123</b>.`,
        btnTitle: "Wyślij wniosek do przygotowania",
        documentType: "end",
        document: ""
        // akceptacja uzasadnienia - samo uzasadnienie - akceptuję / wymaga poprawy "Czy akceptujesz uzasadnienie jako zgodne ze znanym Tobie stanem faktycznym?" "Popraw informacje, które uważasz za niezgodne ze stanem faktycznym." 
    }
]
);
</script>