<template>
    <main class="w-100 mt-0 pl-3 pr-2 pb-3 flex flex-col justify-start items-center gap-3">
        <div class="overflow-auto">
            <Fieldset legend="Quick Launcher" :toggleable="false" class="w-11/12">
                <div class="m-0 flex flex-row">
                    <RouterLink
                        class="w-32 h-32 mx-2 flex flex-col justify-center items-center shadow border rounded hover:cursor-pointer hover:bg-surface-50 dark:hover:bg-surface-700"
                        v-for="(route, index) in childRoutes" :to="route.path">
                        <i class="pi text-6xl" :class="route.icon" />
                        <span class="text-lg mt-2">{{ route.dashboardTitle || route.name }}</span>
                    </RouterLink>
                </div>

            </Fieldset>
            
        </div>
    </main>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import Fieldset from 'primevue/fieldset'
import Divider from 'primevue/divider'

const router = useRouter()

const childRoutes = ref([]);
const mainRoute = router.getRoutes().find(route => route.path === '/');

if (mainRoute && mainRoute.children) {
    childRoutes.value = mainRoute.children.filter(r => r?.meta?.show);
    // console.log("🚀 ~ mainRoute.children:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", mainRoute.children)
}
</script>
