<template>
    <main class="mt-0 pl-3 pr-2 pb-3 flex flex-col justify-start items-center gap-3">
        <div class="h-max lg:w-10/12 xl:w-8/12 w-full min-w-64 flex 2xl:flex-row flex-col gap-3">
            <Card class="w-full flex-[3]">
                <template #content>
                    <div class="flex justify-center items-center">
                        <h1 class="text-2xl font-bold">Sprawdź czy kwalifikujesz się do ogłoszenia upadłości.</h1>
                    </div>
                    <div class="flex w-full justify-center items-center flex-col">
                        <DataView class="w-full" :value="applications">
                            <template #list="slotProps">
                                <div class="grid grid-nogutter">
                                    <div v-for="(item, index) in slotProps.items" :key="index" class="relative">
                                        <div class="flex flex-col lg:flex-row lg:items-center p-4 gap-3"
                                            :class="{ 'border-t border-surface-200 dark:border-surface-700': index !== 0 }">
                                            <div
                                                class="flex flex-col lg:flex-row justify-between lg:items-center flex-1 gap-3">
                                                <div
                                                    class="flex flex-row w-full lg:w-8/12 lg:flex-col justify-between items-start gap-2">
                                                    <div class="text-lg text-surface-700 dark:text-surface-0/80 mt-2">
                                                        {{ item.name }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="flex flex-col items-center lg:items-end gap-5 w-full lg:w-4/12">
                                                    <div class="flex flex-row-reverse lg:flex-row gap-2">
                                                        <FormField v-model="applications[index].val"
                                                            :ref="references[applications[index].uuid]"
                                                            v-bind="applications[index]"
                                                            @update:modelValue="checkChecklist()" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <Transition @before-enter="beforeEnter" @enter="enter"
                                            @enter-active="enterActive" @leave="leave" @leave-active="leaveActive">

                                            <Message :key="item.id + 'hint'" severity="secondary"
                                                icon="pi pi-exclamation-circle" class="z-50 mb-4"
                                                v-show="item.showHint && item.hint">
                                                <div class="leading-5" v-html="item.hint"></div>
                                            </Message>
                                        </Transition>
                                    </div>
                                </div>
                            </template>
                        </DataView>
                    </div>
                    <div class="flex justify-center items-center mt-3 mx-3">
                        <Button label="Prześlij ankietę" raised @click="showResult" />
                    </div>
                </template>
            </Card>
            <Dialog v-model:visible="visible" modal header="Wynik ankiety" :style="{ width: '60rem' }">
                <div v-if="correct" class="flex flex-col items-center">
                    <p class="pb-5 text-justify">
                        Na wszystkie pytania odpowiedziałeś/łaś twierdząco, a zatem dla Ciebie najlepszym sposobem na pozbycie się długów 
                        i związanych z tym problemów jest ogłoszenie upadłości konsumenckiej. Zachęcamy do skorzystania z naszej platformy 
                        do profesjonalnego przygotowania wniosku o ogłoszenie upadłości. Uzyskaj roczny płatny dostęp do platformy,
                        która poprowadzi Cię <b>krok po kroku</b> przez cały proces przygotowania wniosku.
                        Platforma pomoże Ci zebrać niezbędne informacje i przygotować wszystkie wymagane załączniki, a na koniec
                        wygeneruje gotowy wniosek do sądu o ogłoszenie upadłości. <br><br>
                        Dzięki temu przygotowanie kompletnego wniosku
                        zajmie Ci najkrótszy możliwy czas, który zależnie od, m.in. liczby wierzycieli i dokumentów, które już posiadasz wynosi od 2 godzin do 3 miesięcy. 
                        Wystarczy, że będziesz postępował/ła zgodnie ze wszystkimi wskazówkami przekazywanymi na platformie. W trakcie korzystania z platformy 
                        masz zapewnione pełne wsparcie zarówno techniczne (dotyczące działania platformy) jak i merytoryczne (dotyczące zawartości i treści wniosku).
                        Korzystając zatem z naszej platformy przygotujesz wniosek o ogłoszenie updadłości w sposób dyskretny i profesjonalny, nawet bez konieczności odwiedzania naszej kancelarii.
                    </p>
                    <Button class="w-4/12" raised @click="sendResult">Chcę przetestować platformę</Button>
                </div>
                <div v-else class="flex flex-col items-center">
                    <p class="pb-5 text-justify">
                        Twoje odpowiedzi sugerują, że Twoja sytuacja jest bardziej skomplikowana niż
                        zwykle. Żeby dokładniej ocenić Twoją sytuację i 
                        możliwości oddłużenia zapraszamy do kontaktu na bezpłatną konsultację, lub zadzwoń
                         <b>534 123 123</b>.
                    </p>
                    <Button class="w-4/12" raised @click="showConsultation()">Umów się na bezpłatną konsultację</Button>
                </div>
            </Dialog>
            <Dialog v-model:visible="visibleConsultationDialog" modal header="Umów się na bezpłatną konsultację"
                class="w-[45rem]">
                <Consultation :name="userdata.name" :ankieta="getAnkietaData()" @close-window="visibleConsultationDialog = false"></Consultation>
            </Dialog>
        </div>
    </main>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { userdataStore } from '@/stores/userData';


import DataView from 'primevue/dataview';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button'
import Card from 'primevue/card';
import FormField from '@/components/forms/FormField.vue';
import Message from 'primevue/message';
import Consultation from '@/components/DialogsContent/consultation.vue';

let transformEnterTime = 1;
let transformLeaveTime = 0.3;

const visible = ref(false);
const correct = ref(true);
const visibleConsultationDialog = ref(false);
const userdata = userdataStore();

function beforeEnter(el) {
    el.style.opacity = 0;
    el.style.transform = 'scale(0.8)';
}
function enter(el, done) {
    console.log('Entering:', el);

    setTimeout(() => {
        requestAnimationFrame(() => {
            el.style.transition = `opacity ${transformEnterTime}s, transform ${transformEnterTime}s`;
            el.style.opacity = 1;
            el.style.transform = 'scale(1)';
        });
    }, 50)



    setTimeout(done, transformEnterTime * 2000);
}
function enterActive(el) {
    console.log('Enter active:', el);
}
function leave(el, done) {
    console.log('Leaving:', el);
    el.style.transition = `opacity ${transformLeaveTime}s, transform ${transformLeaveTime}s`;
    el.style.opacity = 0;
    el.style.transform = 'scale(0.8)';
    setTimeout(done, transformLeaveTime * 1000);
}
function leaveActive(el) {
    console.log('Leave active:', el);
}

const router = useRouter();
const references = Array.from({ length: 100 }, (_, i) => ({}[i + ""] = ref()));

const applications = ref([
    {
        name: 'Czy mieszkasz w Polsce lub masz zameldowanie w Polsce (jeśli pracujesz za granicą)?',
        val: null,
        id: 1,
        uuid: "1",
        component: "SelectButton",
        options: ['Tak', 'Nie'],
        normal: false,
        errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
        conditions: [{
            type: "required"
        }]
    },
    {
        name: ' Czy suma wszystkich Twoich, pozostałych do spłaty zobowiązań przekracza 30 000 zł?',
        val: null,
        id: 2,
        uuid: "2",
        component: "SelectButton",
        hint: 'Rozumiane jako suma NIE najbliższych rat, ale CAŁYCH, pozostałych do spłaty długów, w tym np.: kredytów, pożyczek, chwilówek, zaległości za telefon, media, mieszkaniowych, w US i ZUS, etc. spłacanych i nie spłacanych.',
        options: ['Tak', 'Nie'],
        normal: false,
        errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
        conditions: [{
            type: "required"
        },
        {
            type: "value",
            option: 0
        }]
    },
    {
        name: 'Czy czas, w którym nie spłacasz prawidłowo swoich zobowiązań przekracza 3 miesiące lub są prowadzone wobec Ciebie egzekucje komornicze?',
        val: null,
        id: 3,
        uuid: "3",
        component: "SelectButton",
        options: ['Tak', 'Nie'],
        normal: false,
        errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
        conditions: [{
            type: "required"
        },
        {
            type: "value",
            option: 0
        }]
    },
    
    {
        name: 'Czy jesteś osobą nieprowadzącą działalności gospodarczej lub nie jesteś/nie byłeś wspólnikiem spółki cywilnej, jawnej lub partnerskiej, albo zakończyłeś działalność i jesteś wykreślony z rejestru przedsiębiorców, albo prowadzisz działalność gospodarczą i jesteś gotów ją zamknąć przed wysłaniem wniosku o ogłoszenie upadłości?',
        val: null,
        id: 4,
        uuid: "4",
        component: "SelectButton",
        options: ['Tak', 'Nie'],
        normal: false,
        errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
        conditions: [{
            type: "required"
        },
        {
            type: "value",
            option: 0
        }]
    },
    {
        name: 'Czy wartość Twojego majątku jest niższa niż wartość Twoich zobowiązań?',
        val: null,
        id: 5,
        uuid: "5",
        hint: `Twoim majątkiem, który jest istotny w kontekście postępowania upadłościowego, są: <br>
            <ul class="list-disc list-outside pl-4">
            <li>Nieruchomości (działka, dom, mieszkanie, a także cząstkowe udziały we własności nieruchomości),</li>
            <li>Samochód i każdy inny pojazd mechaniczny (także jeśli jesteś współwłaścicielem pojazdu mechanicznego),</li>
            <li>Prawa spadkowe (także niezrealizowane, tzn. że spadkodawca zmarł, a spadkobiercy nie przeprowadzili postępowania spadkowego oraz prawo do zachowku gdy zostałeś pominięty w testamencie),</li>
            <li>Dzieła sztuki,</li>
            <li>Udziały w spółkach,</li>
            <li>Akcje,</li>
            <li>Jednostki funduszy inwestycyjnych,</li>
            <li>Gotówka.</li>
            </ul>`,
        component: "SelectButton",
        options: ['Tak', 'Nie'],
        normal: false,
        errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        name: 'Jeśli posiadasz majątek to czy jesteś gotów na to, aby go poświęcić żeby pozbyć się długów?',
        val: null,
        id: 6,
        uuid: "6",
        hint: `Dzięki postępowaniu upadłościowemu masz możliwości na uzyskanie korzyści, które są niedostępne w przypadku utraty majątku w wyniku egzekucji komorniczej. Jeżeli chcesz dowiedzieć się więcej, to zadzwoń pod numer <br><b>534 123 123</b> w celu bezpłatnej konsultacji.`,
        component: "SelectButton",
        options: ['Tak', 'Nie'],
        normal: false,
        errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        name: 'Czy Twoje zobowiązania to głównie długi inne niż: alimenty, zadośćuczynienia, odszkodowania, grzywny, nawiązki?',
        val: null,
        id: 7,
        uuid: "7",
        hint: `Wyżej wymienione zobowiązania (alimenty, zadośćuczynienia, odszkodowania, grzywny, nawiązki) nie mogą być umorzone. Jednak w przypadku gdy masz także inne długi to jest prawdopodobne, że te inne długi zostaną umorzone nawet bez ustalania planu spłaty.`,
        component: "SelectButton",
        options: ['Tak', 'Nie'],
        normal: false,
        errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }, {
        name: 'Czy chcesz skutecznie pozbyć się swoich długów, w terminie nie dłuższym praktycznie, niż kilka lat?',
        val: null,
        id: 8,
        uuid: "8",
        hint: `Zależnie od Twoich możliwości zarobkowych oraz faktycznie ponoszonych kosztów jest możliwe całkowite, a na pewno częściowe umorzenie Twoich długów w perspektywie od roku do maksymalnie kilku lat, od złożenia wniosku o ogłoszenie upadłości. Co więcej, już po ogłoszeniu upadłości Twoje długi przestają rosnąć, pozbywasz się komorników i windykatorów, gdyż żadne postępowania egzekucyjne czy windykacyjne nie mogą być prowadzone.`,
        component: "SelectButton",
        options: ['Tak', 'Nie'],
        normal: false,
        errorLabels: ['Odpowiedź na to pytanie jest wymagana'],
        conditions: [{
            type: "required"
        }]
    }
]);

const formValues = ref([1].map(() => null));

const getAnkietaData = () => {
    return {
        question1:applications.value[0].name + ' <b>' + applications.value[0].val+'</b>',
        question2:applications.value[1].name + ' <b>' + applications.value[1].val+'</b>',
        question3:applications.value[2].name + ' <b>' + applications.value[2].val+'</b>',
        question4:applications.value[3].name + ' <b>' + applications.value[3].val+'</b>',
        question5:applications.value[4].name + ' <b>' + applications.value[4].val+'</b>',
        question6:applications.value[5].name + ' <b>' + applications.value[5].val+'</b>',
        question7:applications.value[6].name + ' <b>' + applications.value[6].val+'</b>',
    }
       
}

const showResult = () => {
    // console.log("Saving form.", formValues.value);
    checkChecklist(true);

    correct.value = true;

    for (let i = 0; i < applications.value.length; i++) {
        if (applications.value[i].val == null) {
            return;
        }
        if (applications.value[i].val == 'Nie') {
            correct.value = false;
        }
    }

    visible.value = true;
};

const showConsultation = () => {
    visibleConsultationDialog.value = true;
    visible.value = false;
}

const sendResult = () => {
    console.log("🚀 ~ sendResult ~ router:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", router.getPreviousPath())

    router.push("/dashboard");
    console.log("🚀 ~ sendResult2 ~ router:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", router.getPreviousPath())
}

const checkChecklist = (notchecked = false) => {
    const lastObjValIndex = applications.value.findLastIndex(obj => obj.val !== null);

    applications.value[lastObjValIndex + 1 == applications.value.length ? lastObjValIndex : lastObjValIndex + 1].showHint = true;

    for (let i = 0; i < (notchecked ? applications.value.length : lastObjValIndex + 1); i++) {
        let status = true;
        if (applications.value[i].val == null || applications.value[i].val == 'Nie') {
            status = references[applications.value[i].uuid].value[0].validate();
        }

        // if (!status) 
        applications.value[i].showHint = true;
        // else applications.value[i].showHint = false;

        // if (applications.value[i].val == null) applications.value[i].showHint = true;
    }
}

</script>
