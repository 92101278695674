<template>
    <section class="bg-gray-50 dark:bg-gray-900 min-h-screen flex items-center justify-center h-full w-full">
        <div class="min-w-[30rem] flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

            <RouterLink class="flex flex-col items-center justify-center px-6 py-2 mx-auto lg:py-4" to="/">
                <div class="flex items-center mb-1 text-3xl font-bold text-gray-900 dark:text-white">
                    <div
                        class="bg-white w-[4.5rem] h-[4.5rem] mr-5 rounded-lg shadow-lg dark:border dark:border-gray-700 flex justify-center items-center">
                        <img class="w-12 h-12" src="/logo.png" alt="logo" />
                    </div>

                    <div class="flex flex-col">
                        <span class="block" :class="{ 'lg:hidden': isLandingPageShown() }">Upadłość Konsumencka </span>
                        <span class="block" :class="{ 'lg:hidden': isLandingPageShown() }">Kancelaria AR</span>
                    </div>
                </div>
            </RouterLink>
            <div v-if="!hasSent"
                class="w-full bg-white rounded-lg shadow-lg dark:border dark:border-gray-700 dark:bg-gray-800">
                <div class="p-8 sm:p-10 ">
                    <h1
                        class="text-2xl font-extrabold leading-tight tracking-tight text-gray-900  dark:text-white text-center">
                        {{ langObject.register_header }}
                        <br>

                    </h1>
                    <div class="w-full text-center text-sm">(rejestracja jest bezpłatna)</div>
                    <form class="space-y-4" @submit.prevent="registerSubmit">
                        <div>
                            <FormField v-for="(pole, poleIndex) in data" :key="poleIndex" :ref="references[pole.uuid]"
                                v-bind="pole" v-model=formValues[poleIndex] @update:modelValue="custom(pole.uuid)" />
                        </div>
                        <div>
                            <Checkbox v-model="checked" :invalid="invalid && !checked" binary inputId="policy" />
                            <label for="policy" class="cursor-pointer"> Akceptuję</label>
                            <a href="https://upadlosc-ar-expert.pl/data-policy-and-user-agreement"
                                class="text-primary-500"> politykę prywatności</a>.
                            <div v-if="invalid && !checked" class="pl-2 pt-1 text-sm text-red-600">Zgoda na politykę
                                prywatności jest wymagana.</div>
                        </div>

                        <RouterLink class="flex justify-between items-center"
                            :to="childRoutes.find(route => route.name === 'Login').path">
                            <span class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">{{
                                langObject.have_account }}</span>
                        </RouterLink>
                        <button type="submit"
                            class="w-full text-white font-bold bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg text-lg px-5 py-3 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 pt-2">
                            {{ langObject.register_button }}
                        </button>
                        <div class="text-center space-y-4 max-w-sm text-xs text-gray-500 dark:text-gray-400"
                            v-html="langObject.recaptchaDisclaimer">
                        </div>
                        <div class=" w-full mt-4" v-if="isLoading">
                            <ProgressBar mode="indeterminate" style="height: 6px"></ProgressBar>
                        </div>
                    </form>
                </div>
            </div>
            <div v-if="hasSent"
                class="w-full bg-white rounded-lg shadow-lg dark:border dark:border-gray-700 sm:max-w-xl dark:bg-gray-800">
                <div class="p-8 space-y-6 sm:p-10 ">
                    <h1
                        class="text-2xl font-extrabold leading-tight tracking-tight text-gray-900  dark:text-white text-center max-w-sm">
                        {{ langObject.email_sent }}
                    </h1>
                    <div class="text-center space-y-4 max-w-sm">
                        <p class="text-sm text-gray-600 dark:text-gray-300">
                            {{ langObject.email_sent_register }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>



<script setup>
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import { langObject } from '@/languages/pl.js';
import ProgressBar from 'primevue/progressbar';
import Checkbox from 'primevue/checkbox';
import FormField from '@/components/forms/FormField.vue';
import { useReCaptcha } from 'vue-recaptcha-v3'

const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

const showToast = inject('showToast');
const isLandingPageShown = inject('isLandingPageShown');

const isLoading = ref(false);
const hasSent = ref(false);
const repeatPasswordValue = ref([]);
const checked = ref(false);
const invalid = ref(false);

const router = useRouter();
const childRoutes = ref([]);
const authRoute = router.getRoutes().find(route => route.path === '/authview');

if (authRoute && authRoute.children) {
    childRoutes.value = authRoute.children;
}

const references = Array.from({ length: 5 }, (_, i) => ({}[i + ""] = ref()));
const formValues = ref(Array(5).fill(null));

const data = ref([
    {
        uuid: '0',
        component: "InputText",
        label: langObject.name,
        errorLabels: ['Imię jest wymagane'],
        conditions: [{
            type: "required",
        },],
        placeholder: "Jan",
    },
    {
        uuid: '1',
        component: "InputText",
        label: langObject.surname,
        errorLabels: ['Nazwisko jest wymagane'],
        conditions: [{
            type: "required",
        },],
        placeholder: "Kowalski",
    },
    {
        uuid: '2',
        component: "InputText",
        label: langObject.email,
        errorLabels: ['Email jest wymagany', 'Email jest nieprawidłowy'],
        conditions: [{
            type: "required",
        },
        {
            type: "regex",
            value: "^\\w+([\\-\\.]\\w+)*@([\\w\\-]+\\.)+[\\w\\-]{2,4}$"
        },],
        placeholder: "przykład@invictus.pl",
    },
    {
        uuid: '3',
        component: "Password",
        label: langObject.password,
        conditions: [{
            type: "required",
        },],
        innerInputProps: {
            feedback: true,
        },
        placeholder: "••••••••",
    },
    {
        uuid: '4',
        component: "Password",
        label: langObject.repeat_password,
        options: repeatPasswordValue,
        errorLabels: ['Hasło jest wymagane', 'Hasła się różnią'],
        conditions: [{
            type: "required",
        }, {
            type: "value",
            option: 0,
        }],
        innerInputProps: {
            feedback: false,
        },
        placeholder: "••••••••",
    }
])

function custom(uuid) {
    if (uuid == "3") repeatPasswordValue.value = [formValues.value[3]];
}

const registerSubmit = async () => {
    let status = true;

    for (const reference of references.values()) {
        if (!reference.value[0].validate()) {
            status = false;
        }
    }

    if (!checked.value) {
        status = false;
        invalid.value = true;
    }

    if (status) {
        let loadingTimeout;

        try {
            loadingTimeout = setTimeout(() => {
                isLoading.value = true;
            }, 400);
            await recaptchaLoaded()
            const token = await executeRecaptcha('login')
            const response = await fetch('/api/register', {
                body: JSON.stringify({
                    name: formValues.value[0],
                    surname: formValues.value[1],
                    email: formValues.value[2],
                    password: formValues.value[3],
                    token: token
                }),
            });

            if (response.ok) {
                const data = await response.json();
                console.log("🚀 ~ registerSubmit ~ data:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", data)

                if (data.success == 1) {
                    isLoading.value = false;
                    hasSent.value = true;
                }
                else if (data.success == 2) {
                    showToast('error', 'Błąd', langObject.error_error_userAlreadyExists);
                }
                else {
                    throw "error"
                }
            }
        } catch (error) {
            showToast('error', 'Błąd', langObject.error_unknown);
        } finally {
            clearTimeout(loadingTimeout);
            isLoading.value = false;
        }
    }
}
</script>