<template>
    <main class="mt-0 pl-3 pr-2 pb-3 flex flex-col justify-start items-center gap-3">
        <Card class="w-full xl:w-11/12" :pt="{ body: { class: 'p-3' }, content: { class: 'p-0' } }">
            <template #content>
                <DataTable v-model:filters="filters" v-model:selection="selectedObject" :value="users" showGridlines
                    size="small" class="bg-white" filterDisplay="menu" :loading="loading" scrollable scrollHeight="550px" :virtualScrollerOptions="{ itemSize: 7 }"
                    :globalFilterFields="['name', 'surname', 'email', 'created', 'lastLoggin', 'lastPayment']">
                    <template #header>
                        <div class="flex justify-between">

                            <Button type="button" icon="pi pi-filter-slash" label="Wyczyść" outlined
                                @click="clearFilter()" />
                            <div class="flex justify-end w-full">
                                <IconField>
                                    <InputIcon>
                                        <i class="pi pi-search" />
                                    </InputIcon>
                                    <InputText v-model="filters['global'].value" placeholder="Wyszukaj" />
                                </IconField>
                            </div>
                        </div>

                    </template>
                    <template #empty> Brak użytkowników. </template>
                    <template #loading> Ładowanie danych. Proszę czekać. </template>
                    <Column v-for="col of textcolumns" :key="col.field" :field="col.field" :header="col.header">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="w-full"
                                placeholder="Wyszukaj" />
                        </template>
                    </Column>
                    <Column v-for="col of datecolumns" :key="col.field" :field="col.field" :header="col.header">
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="w-full"
                                placeholder="Wyszukaj" />
                        </template>
                        <template #body="slotProps">
                            {{ slotProps.data[col.field]?new Date(slotProps.data[col.field]).toLocaleString():'Nigdy' }}
                        </template>
                    </Column>
                </DataTable>
            </template>
        </Card>

    </main>
</template>

<script setup>
import { ref, onMounted, onUnmounted, inject } from 'vue'
import { FilterMatchMode, FilterOperator } from '@primevue/core/api';
import { useRouter } from 'vue-router'

import Card from 'primevue/card';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import InputText from 'primevue/inputtext';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';

import Button from 'primevue/button';

const router = useRouter();
const showToast = inject('showToast');

const users = ref();
const loading = ref(true);
const selectedObject = ref();
const filters = ref();

const textcolumns = [
    { field: 'name', header: 'Imie' },
    { field: 'surname', header: 'Nazwisko' },
    { field: 'email', header: 'Email' }
];

const datecolumns = [
    { field: 'Created', header: 'Utworzono' },
    { field: 'LastLogged', header: 'Ostatnie logowanie' },
    { field: 'LastPayment', header: 'Ostatnia płatność' }
];


const initFilters = () => {
    filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        surname: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        created: {value: null, matchMode: FilterMatchMode.EQUALS},
        lastLoggin: { value: null, matchMode: FilterMatchMode.EQUALS},
        lastPayment: { value: null, matchMode: FilterMatchMode.EQUALS},
    }
}
initFilters();

function clearFilter() {
    initFilters();
}

async function getObjects() {
    const response = await fetch('/api/userList');

    if (response.ok) {
        const data = await response.json();

        if (data.success == 1) {
            users.value = data.result;
            loading.value = false;
        }
        else if (data.success == 2) {
            showToast('error', 'Błąd', data.result);
        }
    } else {
        showToast('error', 'Błąd', 'Problem z połączeniem z serwerem');
    }
}

onMounted(() => {
    getObjects();
})

let myInterval = setInterval(getObjects, 20000);

onUnmounted(() => {
    clearInterval(myInterval);
})



</script>
