import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'

import Lara from '@/presets/lara'
// import Aura from '@/presets/aura'
// import BadgeDirective from 'primevue/badgedirective'
import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice'

import { VueReCaptcha } from 'vue-recaptcha-v3'

const language = 'pl';

const app = createApp(App)
app.use(createPinia());
app.use(router);
app.use(ToastService);
// app.directive('badge', BadgeDirective);
app.directive('tooltip', Tooltip);

app.use(VueReCaptcha, {
    siteKey: import.meta.env.VITE_RECAPTCHA_KEY, loaderOptions: {
        autoHideBadge: true,
        renderParameters: { hl: 'pl' }
    }
});

async function loadLanguage(language) {
    if (language !== 'none') {
        try {
            const langModule = await import(`./languages/${language}.js`);
            return langModule.langObject;
        } catch (error) {
            console.error('Error loading language module:', error);
            return null;
        }
    }
    return null;
}

loadLanguage(language).then((langObject) => {
    if (langObject) {
        app.use(PrimeVue, {
            unstyled: true,
            pt: Lara,
            locale: langObject
        });
    } else {
        app.use(PrimeVue, {
            unstyled: true,
            pt: Lara
        });
    }

    app.mount('#app');
});



