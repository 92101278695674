export const langObject = {
    startsWith: 'Zaczyna się od',
    contains: 'Zawiera',
    notContains: 'Nie zawiera',
    endsWith: 'Kończy się na',
    equals: 'Równe',
    notEquals: 'Nie równe',
    noFilter: 'Brak filtra',
    filter: 'Filtr',
    lt: 'Mniejsze niż',
    lte: 'Mniejsze lub równe',
    gt: 'Większe niż',
    gte: 'Większe lub równe',
    dateIs: 'Data jest',
    dateIsNot: 'Data nie jest',
    dateBefore: 'Data przed',
    dateAfter: 'Data po',
    custom: 'Personalizuj',
    clear: 'Wyczyść',
    apply: 'Zastosuj',
    matchAll: 'Dopasuj wszystko',
    matchAny: 'Dopasuj cokolwiek',
    addRule: 'Dodaj regułę',
    removeRule: 'Usuń regułę',
    accept: 'Tak',
    reject: 'Nie',
    choose: 'Wybierz',
    upload: 'Prześlij',
    cancel: 'Anuluj',
    completed: 'Zakończony',
    pending: 'W toku',
    dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
    dayNamesShort: ['Nie', 'Pon', 'Wto', 'Śro', 'Czw', 'Pią', 'Sob'],
    dayNamesMin: ['N', 'P', 'W', 'Ś', 'C', 'P', 'S'],
    monthNames: [
        'Styczeń',
        'Luty',
        'Marzec',
        'Kwiecień',
        'Maj',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień'
    ],
    monthNamesShort: [
        'Sty',
        'Lut',
        'Mar',
        'Kwi',
        'Maj',
        'Cze',
        'Lip',
        'Sie',
        'Wrz',
        'Paź',
        'Lis',
        'Gru'
    ],
    chooseYear: 'Wybierz rok',
    chooseMonth: 'Wybierz miesiąc',
    chooseDate: 'Wybierz datę',
    prevDecade: 'Poprzednia dekada',
    nextDecade: 'Następna dekada',
    prevYear: 'Poprzedni rok',
    nextYear: 'Następny rok',
    prevMonth: 'Poprzedni miesiąc',
    nextMonth: 'Następny miesiąc',
    prevHour: 'Poprzednia godzina',
    nextHour: 'Następna godzina',
    prevMinute: 'Poprzednia minuta',
    nextMinute: 'Następna minuta',
    prevSecond: 'Poprzednia sekunda',
    nextSecond: 'Następna sekunda',
    am: 'am',
    pm: 'pm',
    today: 'Dzisiaj',
    weekHeader: 'Tydz',
    firstDayOfWeek: 1,
    showMonthAfterYear: false,
    dateFormat: 'dd.mm.yy',
    weak: 'Słaby',
    medium: 'Średni',
    strong: 'Silny',
    passwordPrompt: 'Wpisz hasło',
    emptyFilterMessage: 'Brak wyników',
    searchMessage: 'Szukaj {0}',
    selectionMessage: 'Wybierz {0} elementów',
    emptySelectionMessage: 'Wybierz {0}',
    emptySearchMessage: 'Brak podpowiedzi',
    emptyMessage: 'Brak danych',
    username: 'Adres email',
    password: 'Hasło',
    sign_in: 'Zaloguj',
    login_header: 'Zaloguj się do swojego konta',
    remember_me: 'Zapamiętaj mnie',
    forgot_password: 'Nie pamiętasz hasła?',
    other_methods: 'Inne metody logowania',
    qr_code: 'Kod QR',
    register_now: "Zarejestruj się",
    no_account: "Nie masz konta?",
    repeat_password: 'Powtórz hasło',
    name: "Imię",
    surname: "Nazwisko",
    register_header: "Zarejestruj się",
    email: "Adres email",
    register_button: "Zarejestruj",
    error_wrongPasswordOrEmail: 'Nieprawidłowa nazwa użytkownika lub hasło. Spróbuj ponownie',
    have_account: "Masz już konto?",
    provide_email: "Wpisz swój adres email",
    reset_password: "Zresetuj hasło",
    reset_password_text: "Na Twój adres e-mail zostanie wysłany link umożliwiający zresetowanie hasła.",
    send: "Wyślij",
    email_sent: "Email wysłany",
    email_sent_text: "Link do zresetowania hasła został wysłany na Twój adres email. Jeżeli nie otrzymałes emaila, sprawdź folder SPAM.",
    email_sent_register: "Link do potwierdzenia rejestracji został wysłany na Twój adres email. Jeżeli nie otrzymałes emaila, sprawdź folder SPAM.",
    error_userAlreadyExists: 'Użytkownik o podanym adresie email już istnieje. Spróbuj ponownie',
    error_unknown: 'Wystąpił błąd. Spróbuj ponownie.',
    reset_password_header: "Proszę wprowadzić nowe hasło i potwierdzić je w drugim polu, a następnie kliknąć przycisk",
    reset_password_button: "Zapisz nowe hasło",
    reset_password_success: "Hasło zostało zresetowane. Za chwilę nastąpi przekierowanie.",
    confirm_success: "Email został potwierdzony. Kliknij poniższy przycisk aby się zalogować.",
    error_tooManyRequests: "Zbyt wiele błędnych logowań. Spróbuj zalogować się ponownie za 5 minut",
    go_back_to_login: "Wróć do strony logowania",
    recaptchaDisclaimer: `Ta strona jest chroniona przez reCAPTCHA i obowiązują 
    <a 
      href="https://policies.google.com/privacy"
      target="_blank"
      class="text-primary-600 underline hover:underline"
    >
      Polityka prywatności
    </a>
    oraz
    <a
      href="https://policies.google.com/terms"
      target="_blank"
      class="text-primary-600 underline hover:underline"
    >
      Warunki korzystania z usługi
    </a>
    Google.`,
}
