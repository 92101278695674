<template>
    <Toast />
    <!-- style="max-width: calc(100% - 1.5rem); min-width: calc(932px - 1.5rem)" -->
    <Menubar class="w-full shadow m-0 mb-0 p-0 rounded-none bg-white z-10 sticky top-0"
        :pt="{ start: { style: 'overflow: hidden;width: 100%;' }, end: { class: 'pl-3 lg:pl-0 max-w-[40%] min-w-[30%] w-fit' } }">
        <template #start>
            <div class="flex justify-start items-center overflow-hidden h-max">
                <Button icon="pi pi-bars" severity="secondary" text aria-label="Bookmark" @click="visible = true"
                    class="text-xl" />
                <span class="font-bold text-xl ml-2 mr-0 pr-0 text-surface-600 dark:text-white/70 min-w-fit">
                    <RouterLink to="/">Kancelaria - AR</RouterLink>
                </span>
                <Breadcrumb :model="tabs" style="overflow: hidden;white-space: nowrap;"
                    class="ml-0 pl-0 flex text-base pointer-events-none border-none w-fit h-fit" />
                <!-- <Button label="Show" @click="showToast('success', 'test', 'test')" /> -->
            </div>
        </template>

        <template #end>
            <div class="flex items-center justify-end text-center gap-3 max-h-10 w-full">
                <OverlayBadge :value="messages.length" v-if="!auron">
                    <i class="pi pi-bell text-surface-600 dark:text-white/70 cursor-pointer text-2xl"
                        @click="toggleNotification" />
                </OverlayBadge>

                <OverlayPanel v-if="!auron" class="mt-7 max-h-72 overflow-auto max-w-sm shadow-gray-400" ref="op">
                    <div v-if="messages.length > 0" class="flex flex-col-reverse h-max min-w-56 gap-3">
                        <Message v-for="(msg, msgIndex) of messages" :key="msgIndex" :severity="msg.severity"
                            class="flex-wrap p-0" style="justify-content: start;">
                            <p class="py-2 font-bold">{{ msg.summary }}</p>{{ msg.detail }}
                        </Message>
                    </div>
                    <div v-else class="flex flex-col-reverse h-max min-w-56 justify-center">Brak historii operacji.
                    </div>
                </OverlayPanel>
                <i v-if="auron" v-tooltip.bottom="'Pomniejsz czcionkę'"
                    class="pi pi-search-minus cursor-pointer text-surface-600 hidden lg:inline-block pl-3 text-2xl"
                    severity="secondary" @click="changeScaleDown"></i>
                <i v-if="auron" v-tooltip.bottom="'Powiększ czcionkę'"
                    class="pi pi-search-plus cursor-pointer text-surface-600 hidden lg:inline-block text-2xl"
                    severity="secondary" @click="changeScaleUp"></i>

                <Button type="button" @click="toggle" rounded :label="username" outlined aria-haspopup="true"
                    aria-controls="overlay_menu"
                    class="max-h-10 flex !p-2 !px-4 gap-3 text-lg w-full lg:w-fit !leading-[20px] lg:!leading-[30px]"
                    style="white-space: wrap;">
                    <!-- <Avatar label="NS" class="bg-green-100 shadow text-sm" size="normal" shape="circle" /> -->
                    <!-- {{ userdata.username ? userdata.username : '' }} -->
                </Button>
                <Menu ref="menu" id="overlay_menu" :model="items" :popup="true">
                    <template #item="{ item, props }">
                        <!-- <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                            <a :href="href" v-bind="props.action" @click="navigate">
                                <span :class="item.icon" />
                                <span class="ml-2">{{ item.label }}</span>
                            </a>
                        </router-link> -->
                        <!-- :href="item.url" :target="item.target" -->
                        <a v-bind="props.action" @click="logout()">
                            <span :class="item.icon" />
                            <span class="ml-2">{{ item.label }}</span>
                        </a>
                    </template>
                </Menu>
            </div>
        </template>
    </Menubar>

    <div class="flex flex-col overflow-auto relative pt-3">
        <router-view />
        <!-- <div v-if="false" class="flex justify-center items-end w-full h-screen p-3 text-[0.75rem] text-surface-300">
            <span>&copy;{{ new Date().getFullYear() }} &bull; Invictus. All rights reserved.</span>
        </div> -->
        <div class="flex w-full p-3 text-[0.75rem] text-surface-300"
            :class="{ 'flex-col gap-16 justify-start items-center': isMobile(), 'justify-center items-end h-screen': !isMobile()}">
            <!-- , 'h-[20vh]': isIPhone(), 'h-[15vh]':!isIPhone()&&isMobile() -->
            <span>&copy;{{ new Date().getFullYear() }} &bull; Invictus. All rights reserved.</span>
            <!-- <span v-if="isMobile()" style="color: rgb(249 250 251);">lorem</span> -->
        </div>
        <!-- <div v-else class="flex flex-col justify-start items-center w-full h-[15vh] gap-16 p-3 text-[0.75rem] text-surface-300">
            <span>&copy;{{ new Date().getFullYear() }} &bull; Invictus. All rights reserved.</span>
            <span style="color: rgb(249 250 251);">lorem</span>
        </div> -->
    </div>

    <Sidebar v-model:visible="visible" header="Menu:" class="outline-none">
        <div class="flex flex-col h-full justify-between">
            <div>
                <Divider class="mt-0 pt-0" />
                <ul>
                    <RouterLink v-for="route in childRoutes" :to="route.path" @click="visible = false">
                        <li
                            class="w-100 mx-2 p-2 border-b border-surface-200 dark:border-surface-600 'bg-surface-0 dark:bg-surface-800', hover:bg-surface-100 dark:hover:bg-surface-700 rounded-t cursor-pointer flex flex-row items-center">
                            <i class="pi text-3xl" :class="route.icon" />
                            <span class="text-lg ml-4">{{ route.menuTitle || route.name }}</span>
                        </li>
                    </RouterLink>
                </ul>
            </div>
        </div>
    </Sidebar>
</template>

<script setup>
import { ref, provide, onMounted, onUnmounted, inject } from 'vue'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { userdataStore } from '@/stores/userData'
import { useToast } from 'primevue/usetoast';

const toast = useToast();

import Menubar from 'primevue/menubar'
import Avatar from 'primevue/avatar'
import Button from 'primevue/button'
import Sidebar from 'primevue/drawer'
import Divider from 'primevue/divider'
import Breadcrumb from 'primevue/breadcrumb'
import Menu from 'primevue/menu'
import OverlayPanel from 'primevue/popover'
import Message from 'primevue/message';
import Toast from 'primevue/toast';
import OverlayBadge from 'primevue/overlaybadge';
import Badge from 'primevue/badge';

let scale = false;
const auron = ref(true);
const isMobile = inject('isMobile');
const isIPhone = inject('isIPhone');

const userdata = userdataStore();
const route = useRoute()
const router = useRouter()

const childRoutes = ref([]);
const mainRoute = router.getRoutes().find(route => route.path === '/');

const visible = ref(false)
const tabs = ref([{ label: '' }, { label: computed(() => mainRoute.children.find(r => r.name === route.name)?.menuTitle || route.name) }])


const menu = ref()
const items = ref([
    {
        label: 'Opcje:',
        items: [
            {
                label: 'Wyloguj',
                icon: 'pi pi-reply',
                // route: '/login'
            }
        ]
    }
])

let messages = ref([]);

const getUsername = () => {
    if (userdata.username) {
        if (userdata.username.length > 20) {
            if (userdata.name.length > 12) return userdata.name.substring(0, 12) + '...';
            else return userdata.name + " " + userdata.surname.substring(0, 20 - userdata.name.length) + "...";
        } else {
            return userdata.username
        }
    } else {
        return 'Not logged';
    }
}

const username = ref(getUsername());
const toggle = (event) => {
    menu.value.toggle(event)
}

const op = ref();
const toggleNotification = (event) => {
    op.value.toggle(event)
}


const showToast = (severity, summary, detail,life = 3000) => {
    let msg = { severity: severity, summary: summary, detail: detail, life: life };
    toast.add(msg);
    messages.value.push(msg);
};

var factor = 1.0;
var factorDiff = 0;
const changeScaleUp = () => {
    if (factorDiff < 5) {
        factorDiff += 1;
        factor = 100 / 95;
        changeScale();
    }
}

const changeScaleDown = () => {
    if (factorDiff > -5) {
        factorDiff -= 1;
        factor = 0.95;
        changeScale();
    }
}
const changeScale = () => {
    var all = document.getElementsByTagName("html");
    // all.push(document.querySelector("main"));
    for (var i = 0, max = all.length; i < max; i++) {
        var style = window.getComputedStyle(all[i]);
        var fontSize = style.getPropertyValue('font-size');

        all[i].offsetHeight;

        // if (fontSize) {
        all[i].style.fontSize = (parseFloat(fontSize) * factor) + "px";
        // }
        // if (all[i].nodeName === "IMG") {
        //     // var width = style.getPropertyValue('width');
        //     // var height = style.getPropertyValue('height');
        //     // all[i].style.height = (parseFloat(height) * factor) + "px";
        //     // all[i].style.width = (parseFloat(width) * factor) + "px";
        // }
    }
}

onMounted(() => {
    if (auron.value&&scale) {
        for (let i = 0; i < 5; i++) {
            changeScaleUp();
            factorDiff = 0;
        }
    }

    fetch('/api/refreshToken',{
        body: JSON.stringify({
            requestName: true
        })
    }).then(res => {
        res.json().then(data => {
            if (data.success) {
                userdata.username = data.result.name + " " + data.result.surname;
                userdata.name = data.result.name;
                userdata.surname = data.result.surname;
                userdata.email = data.result.email;
                userdata.defaultOrg = data.result.defaultOrg;

                username.value = getUsername();
            }
        })
    });

    fetch('/api/getListObjects', {
        body: JSON.stringify({
            "listUUID": "0f38658b-dce6-4534-979e-31b7b3a82e68",
            "propertyMap": {
                "name": ".*"
            }
        })
    }).then(objectsResponse => {
        return objectsResponse.json();
    }).then(objects => {
        objects = objects.result.objects.map(o => o.name);
        // console.log("🚀 ~ onMounted ~ objects:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", objects)

        if (mainRoute && mainRoute.children) {
            childRoutes.value = mainRoute.children.filter(r => {
                return r?.meta?.show && objects.includes(r.name)
            });
            // console.log("🚀 ~ onMounted ~ childRoutes:\x1B[34mutil.inspect(, false, 10, true)\x1B[0m", mainRoute.children)
            
        }
    })
});

onUnmounted(() => {
    if (auron.value&&scale) {
        let tempFactorDiff = factorDiff;

        for (let i = 0; i < 5 + tempFactorDiff; i++) {
            changeScaleDown();
            factorDiff = 0;
        }
    }
});

const logout = () => {
    document.cookie = 'appToken=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    userdata.clear();
    router.push('/login');
}

provide('showToast', showToast);

</script>
